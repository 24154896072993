import gql from "graphql-tag";

export default gql`
  fragment supportAccountInfo on Account {
    id
    phone
    extension
    firstName
    lastName
    email
    isAdmin
    isReadOnly
    isDeactivated
    isLockedOut
    allowedInstitutionIds
    InstitutionId
    portalCredentials
    isSystemUser
    identityProvider
  }
`;
