import { useMutation } from "@apollo/client";
import { Path } from "history";
import moment from "moment";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import FormSubmitButtons from "@@components/AuthorizationSharedComponents/FormSubmitButtons";
import { ConsentRadioButton } from "@@components/AuthorizationSharedSteps/EnrollmentSection/ConsentRadioButton";
import { EnrollmentBenefits } from "@@components/AuthorizationSharedSteps/EnrollmentSection/EnrollmentBenefits";
import { StatusField } from "@@components/AuthorizationSharedSteps/EnrollmentSection/StatusField";
import LoadingSpinner from "@@components/LoadingSpinner";
import ThreeLineSpinner from "@@components/LoadingSpinner/LoadingSpinner";
import Modal from "@@components/Modal";
import {
  Account,
  Authorization,
  CapturesPatientConsent,
  EnrollmentStatus,
} from "@samacare/graphql";
import { BaseButton } from "@@ui-kit/BaseButton";
import { LoadingButton } from "@samacare/design/core";
import colors from "Resources/colors";
import { updateInstitutionMutation } from "../../../graphql/Institution";
import { useConfig } from "../../../hooks";
import { useConfirmDialog } from "../../../hooks/confirmDialogHook";
import ROUTE_PATHS from "../../../routes/ROUTE_PATHS";
import { authorizationStatusDisplayName } from "../../../util/authUtils";
import { parseAsUnknownRecordWithDefault } from "../../../util/parsers";
import { getScreenHeight, getScreenWidth } from "../../../util/windowUtils";
import {
  useCanSkipEnrollment,
  useEnrollmentDetails,
  useEnrollmentProgramName,
  useUpdateEnrollmentById,
} from "./enrollmentHooks";

const STATUSES = window.CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;

const BoxTitle = styled.div`
  width: 100%;
  font-size: 18px;
  line-height: 1.5em;
  margin: 3px 0 12px;
  color: ${colors.purple};
`;

const BoxLabel = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 1.5em;
  margin: 3px 0 20px;
`;

const DialogHeader = styled.div`
  display: flex;
  height: 36px;
`;

const DialogInnerHeader = styled.div`
  display: flex;
  align-items: baseline;
  overflow: hidden;
`;

const DialogTitle = styled.div`
  font-size: 18px;
  color: ${colors.purple};
  white-space: nowrap;
`;

const DialogInstructions = styled.div`
  font-size: 14px;
  color: ${colors.darkGray};
  margin-left: 8px;
  margin-right: 32px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LoadingWrapper = styled.div`
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DATE_FORMAT = "MMM D, yyyy - h:MM a";

interface IMessageData {
  drugName: string;
  eventName: string;
  patientExternalID: string;
  praticeExternalID: string;
  service: string;
  status: string;
  transactionId: string;
  transactionType: string;
}

const BackToListButton = ({ onClick }: { onClick: () => void }) => (
  <BaseButton onClick={onClick}>Back to Authorization List</BaseButton>
);

interface IEnrollmentSectionProps {
  account: Account;
  authorization: Authorization;
  back: () => void;
  submitAuth: (isSubmitAuthInBackground: boolean) => Promise<void>;
  disabled: boolean;
  onShowModule: () => void;
  onHideModule: () => void;
  goToLocation: (path: Path) => void;
}
const EnrollmentSection: React.VFC<IEnrollmentSectionProps> = (props) => {
  const config = useConfig();

  const { account, authorization, submitAuth, onShowModule, onHideModule } =
    props;
  const [isModuleDialogShown, setIsModuleDialogShown] = useState(false);
  const [cachedHtmlModule, setCachedHtmlModule] = useState<string | null>(null);
  const [isSubmitAuthInvoked, setIsSubmitAuthInvoked] = useState(false);
  const [isAuthHandledAfterFailure, setIsAuthHandledAfterFailure] =
    useState(false);
  const [isModuleSubmitting, setIsModuleSubmitting] = useState(false);
  const [capturesPatientConsent, setCapturesPatientConsent] = useState(
    account.institution?.capturesPatientConsent
  );
  const [
    isEnrollmentSubmittedDuringModuleSession,
    setIsEnrollmentSubmittedDuringModuleSession,
  ] = useState(false);

  const isShowConsentBlock =
    account.institution?.capturesPatientConsent ===
    CapturesPatientConsent.Unknown;
  const [isProceedEnabled, setIsProceedEnabled] = useState(!isShowConsentBlock);

  const handleConsentOptionSelected = (cpc: CapturesPatientConsent) => {
    setIsProceedEnabled(true);
    setCapturesPatientConsent(cpc);
  };

  const canSkipEnrollment = useCanSkipEnrollment(authorization);
  const updateEnrollmentById = useUpdateEnrollmentById();
  const {
    isEnrollmentFailed,
    isEnrollmentDetailsLoading,
    fetchedModuleHtml,
    enrollment,
  } = useEnrollmentDetails(authorization.id);

  const [updateInstitution] = useMutation(updateInstitutionMutation, {});

  const handleSubmitAuthorization = useCallback(
    async (isSubmitAuthInBackground: boolean) => {
      if (!isSubmitAuthInvoked) {
        setIsSubmitAuthInvoked(true);
        await submitAuth(isSubmitAuthInBackground);
        return true;
      }
      return false;
    },
    [submitAuth, isSubmitAuthInvoked, setIsSubmitAuthInvoked]
  );

  const handleProceed = useCallback(async () => {
    if (
      isShowConsentBlock &&
      account.institution != null &&
      capturesPatientConsent !== CapturesPatientConsent.Unknown
    ) {
      await updateInstitution({
        variables: {
          id: account.institution.id,
          patch: {
            capturesPatientConsent,
          },
        },
      });
    }
    if (
      !isShowConsentBlock ||
      capturesPatientConsent !== CapturesPatientConsent.No
    ) {
      setIsModuleDialogShown(true);
      onShowModule();
    } else {
      await handleSubmitAuthorization(false);
    }
  }, [
    setIsModuleDialogShown,
    onShowModule,
    isShowConsentBlock,
    handleSubmitAuthorization,
    updateInstitution,
    account,
    capturesPatientConsent,
  ]);

  const closeDialog = useCallback(() => {
    setIsModuleDialogShown(false);
    onHideModule();
  }, [setIsModuleDialogShown, onHideModule]);

  const [ConfirmDialog, confirmDialogProps, setConfirmDialogOpen] =
    useConfirmDialog({
      message: "Are you sure you want to cancel?",
      confirm: closeDialog,
      zIndexOffset: 1,
    });

  const handleCloseDialogRequest = useCallback(() => {
    if (isEnrollmentSubmittedDuringModuleSession) {
      closeDialog();
    } else {
      setConfirmDialogOpen(true);
    }
  }, [
    setConfirmDialogOpen,
    closeDialog,
    isEnrollmentSubmittedDuringModuleSession,
  ]);

  const handleSubmitResult = useCallback(
    async (enrollmentStatus: EnrollmentStatus) => {
      setIsModuleSubmitting(false);
      setIsEnrollmentSubmittedDuringModuleSession(true);

      if (enrollment != null) {
        try {
          await updateEnrollmentById({
            variables: {
              id: Number(enrollment.id),
              patch: { status: enrollmentStatus },
            },
          });
          await handleSubmitAuthorization(false);
        } finally {
          closeDialog();
        }
      }
    },
    [enrollment, handleSubmitAuthorization, updateEnrollmentById]
  );

  useEffect(() => {
    if (isEnrollmentFailed) {
      void (async () => {
        if (authorization.status === STATUSES.PRESUBMISSION) {
          if (await handleSubmitAuthorization(true)) {
            setIsAuthHandledAfterFailure(true);
          }
        }
      })();
    }
  }, [isEnrollmentFailed, handleSubmitAuthorization, authorization.status]);

  // Trap message from the enrollment module and process them
  useEffect(() => {
    const messageCallback = (e: MessageEvent<IMessageData>) => {
      let { data } = e;
      // sometimes the data is stringified instead of an object
      if (typeof data === "string") {
        data = JSON.parse(data) as IMessageData;
      }

      if (data.transactionType?.toLowerCase() === "enrollment") {
        if (data.eventName?.toLowerCase() === "cancel") {
          handleCloseDialogRequest();
          // As of 2/16 the finish event caremetx will deprecate the finish event
        } else if (data.eventName?.toLowerCase() === "finish") {
          closeDialog();
        } else if (data.eventName?.toLowerCase() === "submit-initiated") {
          setIsModuleSubmitting(true);
        } else if (data.eventName?.toLowerCase() === "submit-failed") {
          void handleSubmitResult(EnrollmentStatus.SubmitError);
        } else if (data.eventName?.toLowerCase() === "submit-successful") {
          void handleSubmitResult(EnrollmentStatus.Submitted);
        }
      }
    };
    window.addEventListener("message", messageCallback);

    return () => {
      window.removeEventListener("message", messageCallback);
    };
  }, [closeDialog, handleCloseDialogRequest, handleSubmitResult]);

  // We need to cache the moduleHtml to prevent losing it after the enrollment status is updated.
  // When the enrollment status is updated, useEnrollmentDetails is refetched.
  // The module is not returned since status is submitted.
  useEffect(() => {
    if (fetchedModuleHtml != null) {
      setCachedHtmlModule(fetchedModuleHtml);
    }
  }, [fetchedModuleHtml, setCachedHtmlModule]);

  const handleBackToAuthList = () => {
    props.goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
  };

  const isShowSpinner =
    isEnrollmentDetailsLoading ||
    (isEnrollmentFailed &&
      !isAuthHandledAfterFailure &&
      authorization.status === STATUSES.PRESUBMISSION);

  const isShowStatusPane =
    !isShowSpinner &&
    enrollment != null &&
    (isEnrollmentFailed ||
      (!isEnrollmentFailed && enrollment.status !== EnrollmentStatus.Draft));
  const isShowProceedPane =
    !isShowSpinner &&
    !isEnrollmentFailed &&
    enrollment != null &&
    enrollment.status === EnrollmentStatus.Draft;

  const isShowSubmitButton =
    authorization.status === STATUSES.PRESUBMISSION ||
    authorization.status === STATUSES.EDIT_AND_RESUBMIT;

  const authConfig = parseAsUnknownRecordWithDefault(authorization.config, {});
  const drugName = authConfig[
    config.DEFAULT_FIELDS.PRIMARY_DRUG_NAME.key
  ] as string;
  const programName = useEnrollmentProgramName(drugName);

  const moduleWidth = getScreenWidth() - 72;

  const isError: boolean =
    enrollment != null &&
    (enrollment.status === EnrollmentStatus.FetchModuleError ||
      enrollment.status === EnrollmentStatus.SubmitError);

  return (
    <div>
      {isShowSpinner && (
        <LoadingWrapper>
          <LoadingSpinner
            color={colors.purple}
            colorTop={colors.darkPurple}
            open
          />
        </LoadingWrapper>
      )}
      {isShowStatusPane && (
        <>
          <StatusField
            label="SamaAssist"
            value={
              enrollment.status === EnrollmentStatus.Skipped ||
              enrollment.status === EnrollmentStatus.Submitted
                ? "Complete"
                : "Incomplete"
            }
            secondaryValue={
              enrollment.status === EnrollmentStatus.Skipped
                ? "Patient already enrolled"
                : enrollment.status === EnrollmentStatus.Submitted
                ? moment(enrollment.updatedAt).format(DATE_FORMAT)
                : isError
                ? "Unable to complete due to technical issues"
                : undefined
            }
            isError={isError}
          />
          <StatusField
            label="Authorization Submission"
            value={authorizationStatusDisplayName(authorization.status)}
            secondaryValue={moment(authorization.dateOfCurrentStatus).format(
              DATE_FORMAT
            )}
            style={{ marginBottom: "32px" }}
          />
          <EnrollmentBenefits />
          {!isShowSubmitButton && (
            <BackToListButton onClick={handleBackToAuthList} />
          )}
          {isShowSubmitButton && (
            <FormSubmitButtons
              disabled={props.disabled}
              back={props.back}
              submit={() => {
                void handleSubmitAuthorization(false);
              }}
              submitText="Submit"
              style={{ marginTop: 0 }}
            />
          )}
        </>
      )}
      {isShowProceedPane && (
        <div data-cy="componentSamaAssistRegistration">
          <BoxTitle>SamaAssist Registration</BoxTitle>
          <BoxLabel>
            {`SamaCare offers additional support for ${drugName}.  Complete the
              required form for a faster authorization approval.`}
          </BoxLabel>
          <BoxLabel>
            Your authorization will be submitted automatically upon completion
            of the SamaAssist form.
          </BoxLabel>
          <StatusField label="SamaAssist" value="Pending" />
          <StatusField
            label="Authorization Submission"
            value="Pending SamaAssist completion"
            style={{ marginBottom: "32px" }}
          />

          <EnrollmentBenefits />

          {isShowConsentBlock && (
            <>
              <BoxTitle>Patient Consent Confirmation</BoxTitle>
              <BoxLabel>
                Are you currently capturing patient consent/signature for
                enrolling patients in {programName}?
              </BoxLabel>

              <ConsentRadioButton
                onChange={() =>
                  handleConsentOptionSelected(CapturesPatientConsent.Yes)
                }
              >
                Yes
              </ConsentRadioButton>

              <ConsentRadioButton
                onChange={() =>
                  handleConsentOptionSelected(CapturesPatientConsent.No)
                }
              >
                No
              </ConsentRadioButton>

              <ConsentRadioButton
                onChange={() =>
                  handleConsentOptionSelected(CapturesPatientConsent.Unknown)
                }
                style={{ marginBottom: "32px" }}
              >
                I am not sure
              </ConsentRadioButton>
            </>
          )}

          <FormSubmitButtons
            disabled={props.disabled}
            submitDisabled={!isProceedEnabled}
            back={props.back}
            submit={handleProceed}
            submitText="Proceed"
            style={{ marginTop: 0 }}
          >
            {canSkipEnrollment && (
              <LoadingButton
                disabled={!isProceedEnabled}
                color="primary"
                variant="outlined"
                onClick={async () => handleSubmitAuthorization(false)}
                sx={{ margin: 0, paddingX: "16px", paddingY: "6px" }}
              >
                Proceed without SamaAssist
              </LoadingButton>
            )}
          </FormSubmitButtons>
        </div>
      )}
      {isModuleDialogShown && cachedHtmlModule != null && (
        <Modal
          open
          onClick={handleCloseDialogRequest}
          isPreventCloseOnBackdropClick
        >
          <div data-cy="componentSamaAssistRegistrationEnrollmentModule">
            <DialogHeader style={{ width: `${moduleWidth.toString()}px` }}>
              <DialogInnerHeader>
                <DialogTitle>SamaAssist Registration</DialogTitle>
                <DialogInstructions>
                  Complete the required form for a faster authorization
                  approval. Your authorization will be submitted automatically
                  upon completion of the SamaAssist form.
                </DialogInstructions>
              </DialogInnerHeader>
            </DialogHeader>
            <ThreeLineSpinner open={isModuleSubmitting} />
            <iframe
              title="CareMetx Module"
              width={moduleWidth}
              height={getScreenHeight() - 144}
              srcDoc={cachedHtmlModule}
            />
          </div>
        </Modal>
      )}
      <ConfirmDialog {...confirmDialogProps} />
    </div>
  );
};

export { EnrollmentSection };
