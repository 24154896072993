import React, { useState } from "react";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Button,
} from "@samacare/design";
import { LeftRightCenterAll, SamaDialog } from "@samacare/component";
import CloseIcon from "@samacare/design/core/icons/Close";
import ROUTE_PATHS, { OPS_HUB_BASE } from "../routes/ROUTE_PATHS";
import { useLocation } from "react-router-dom";

export const CptAmaDisclaimer: React.FC = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  if (
    [
      ROUTE_PATHS.ADMIN_ROUTE.path,
      ROUTE_PATHS.USER_SETTINGS_ROUTE.path,
      OPS_HUB_BASE,
    ].some((route) => location.pathname.startsWith(route))
  ) {
    return null;
  }

  const marginTop = [
    ROUTE_PATHS.BENEFITS_VERIFICATIONS.path,
    ROUTE_PATHS.PATIENTS_TREATMENT.path,
  ].some((route) => location.pathname.startsWith(route))
    ? 0
    : 4;

  return (
    <>
      <LeftRightCenterAll sx={{ marginX: 4, marginTop }}>
        <Typography variant="caption" sx={{ paddingBottom: 2 }}>
          Procedure code searches use content licensed by the American Medical
          Association (AMA).{" "}
          <Button
            variant="text"
            onClick={handleOpen}
            sx={{
              p: 0,
              minWidth: 0,
              textTransform: "none",
              verticalAlign: "baseline",
              fontSize: "0.75rem",
              fontWeight: "normal",
            }}
          >
            Learn more
          </Button>
        </Typography>
      </LeftRightCenterAll>
      <SamaDialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            CPT AMA Disclaimer
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          CPT® copyright 2023 American Medical Association. All rights reserved.
          Fee schedules, relative value units, conversion factors and/or related
          components are not assigned by the AMA, are not part of CPT®, and the
          AMA is not recommending their use. The AMA does not directly or
          indirectly practice medicine or dispense medical services. The AMA
          assumes no liability for data contained or not contained herein.
        </DialogContent>
      </SamaDialog>
    </>
  );
};
