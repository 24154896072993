import { useEffect, useState, useMemo } from "react";
import { isWithinInterval, subHours } from "date-fns";

import {
  Box,
  Typography,
  Stack,
  Alert,
  CircularProgress,
  LoadingButton,
  type SxProps,
} from "@samacare/design/core";
import { useTheme, alpha } from "@samacare/design/core/styles";
import { useConfig } from "../../hooks/config";
import { Patient, InsuranceCoverageCheckType } from "@samacare/graphql";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { useCurrentAccount } from "../../graphql/Account";
import { StatusDetailsForTreatmentView } from "./StatusDetailsForTreatmentView";
import {
  EnhancedServicesCard,
  EnhancedServicesCardHeader,
  EnhancedServicesCardTitle,
} from "@samacare/component";
import { cleanDrugName } from "../../../../server/src/shared_code";

interface InsuranceCoverageCheckProps {
  patient: Patient | null;
  isLoading: boolean;
  onRunClick: () => void;
  disabled?: boolean;
  eligibilityCheckError: boolean;
  autoRun?: boolean;
  coverageCheckAlertStyleOverride?: SxProps;
  // Pass the J-code to determine drug-specific eligibility criteria
  jCode?: string;
  // Enable automated eligibility checks for partnered drugs based on any status
  autoRunOnAnyStatus?: boolean;
  drugName?: string;
}

const CoverageCheckAlert = ({
  children,
  sxOverride,
}: {
  children: React.ReactNode;
  sxOverride?: SxProps;
}) => {
  const theme = useTheme();

  return (
    <Alert
      severity="info"
      icon={false}
      sx={{
        justifyContent: "center",
        backgroundColor: alpha(theme.palette.primary.main, 0.04),
        "& .MuiAlert-message": { width: "100%" },
        width: "100%",
        marginTop: "4px",
        ...sxOverride,
      }}
    >
      {children}
    </Alert>
  );
};

const useCoverageCheckState = (patient: Patient | null, runCount: number) => {
  return useMemo(() => {
    const latestCoverageCheck = patient?.primaryInsurance?.latestCoverageCheck;
    const lastCheckedAt = latestCoverageCheck?.checkedAt
      ? new Date(latestCoverageCheck.checkedAt)
      : null;

    const checkedWithinLast24Hours = lastCheckedAt
      ? isWithinInterval(lastCheckedAt, {
          start: subHours(new Date(), 24),
          end: new Date(),
        })
      : false;

    return {
      latestCoverageCheck,
      lastCheckedAt,
      checkedWithinLast24Hours,
      supportsCoverageCheck: patient?.primaryInsurance?.supportsCoverageCheck,
    };
  }, [patient, runCount]);
};

const LoadingState = ({ sxOverride }: { sxOverride?: SxProps }) => (
  <CoverageCheckAlert sxOverride={sxOverride}>
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={16} />
      <Typography variant="body2" color="textPrimary">
        Checking patient coverage
      </Typography>
    </Stack>
  </CoverageCheckAlert>
);

const DenialTipsCard = ({
  patient,
  currentAccount,
  latestCoverageCheck,
  eligibilityCheckError,
  onRun,
  isLoading,
  disabled,
  sxOverride,
  drugName,
}: {
  patient: Patient;
  currentAccount: ReturnType<typeof useCurrentAccount>[0];
  latestCoverageCheck?: InsuranceCoverageCheckType | null;
  eligibilityCheckError: boolean;
  onRun: () => void;
  isLoading: boolean;
  disabled: boolean;
  sxOverride?: SxProps;
  drugName: string;
}) => (
  <EnhancedServicesCard sx={{ maxWidth: 600, my: "4px", border: null }}>
    <EnhancedServicesCardHeader
      title={
        <EnhancedServicesCardTitle
          title={`${cleanDrugName(drugName)?.toUpperCase()} denial tips`}
        />
      }
      subheader="Confirm insurance information is correct to avoid potential denial."
      subheaderTypographyProps={{ variant: "body2" }}
    />
    <CoverageCheckAlert
      sxOverride={{ justifyContent: "flex-start", marginTop: 0, ...sxOverride }}
    >
      <Stack spacing={1} direction="column">
        <StatusDetailsForTreatmentView
          patient={patient}
          currentAccount={currentAccount}
          latestCoverageCheck={latestCoverageCheck}
          eligibilityCheckError={eligibilityCheckError}
          onRunClick={onRun}
          isLoading={isLoading}
          disabled={disabled}
        />
      </Stack>
    </CoverageCheckAlert>
  </EnhancedServicesCard>
);

export const InsuranceCoverageCheckWithAlert = ({
  patient,
  isLoading,
  onRunClick,
  disabled = false,
  eligibilityCheckError,
  autoRun = false,
  coverageCheckAlertStyleOverride,
  jCode = "",
  autoRunOnAnyStatus = false,
  drugName,
}: InsuranceCoverageCheckProps) => {
  const config = useConfig();
  const [currentAccount] = useCurrentAccount();
  const [runCount, setRunCount] = useState(0);

  const {
    latestCoverageCheck,
    checkedWithinLast24Hours,
    supportsCoverageCheck,
  } = useCoverageCheckState(patient, runCount);

  const isEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableEligibilityCheck
  );

  const availableDrugMessages = useFeatureFlag<string[]>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.AvailableDrugMessages
  );

  const drugMessageEnabled = useMemo(
    () =>
      Array.isArray(availableDrugMessages) &&
      availableDrugMessages.includes(jCode),
    [availableDrugMessages, jCode]
  );

  const shouldCheckCoverage = useMemo(
    () =>
      (!latestCoverageCheck ||
        (runCount === 0 && !latestCoverageCheck?.isValid)) &&
      !eligibilityCheckError,
    [latestCoverageCheck, eligibilityCheckError, runCount]
  );

  const onRun = () => {
    setRunCount((prev) => prev + 1);
    onRunClick();
  };

  useEffect(() => {
    if (
      supportsCoverageCheck &&
      ((shouldCheckCoverage && autoRun) ||
        (autoRunOnAnyStatus && drugMessageEnabled && !checkedWithinLast24Hours))
    ) {
      onRun();
    }
  }, [drugMessageEnabled, checkedWithinLast24Hours]);

  if (!patient || !isEnabled || !supportsCoverageCheck) {
    return <Box data-cy="coverage-check-disabled" />;
  }

  if (isLoading) {
    return <LoadingState sxOverride={coverageCheckAlertStyleOverride} />;
  }

  if (shouldCheckCoverage && !autoRun) {
    return (
      <CoverageCheckAlert sxOverride={coverageCheckAlertStyleOverride}>
        <Stack spacing={2} direction="row" alignItems="center">
          <LoadingButton
            data-cy="actionRunEligibilityCheck"
            onClick={onRun}
            loading={isLoading}
            disabled={disabled || eligibilityCheckError}
            variant="contained"
            sx={{ width: "fit-content" }}
            size="small"
          >
            Check Coverage
          </LoadingButton>
          <Typography variant="body2" color="textPrimary">
            Instantly check this patient’s insurance status
          </Typography>
        </Stack>
      </CoverageCheckAlert>
    );
  }

  if (drugMessageEnabled && !latestCoverageCheck?.isActive && drugName) {
    return (
      <DenialTipsCard
        patient={patient}
        currentAccount={currentAccount}
        latestCoverageCheck={latestCoverageCheck}
        eligibilityCheckError={eligibilityCheckError}
        onRun={onRun}
        isLoading={isLoading}
        disabled={disabled}
        sxOverride={coverageCheckAlertStyleOverride}
        drugName={drugName}
      />
    );
  }

  return (
    <CoverageCheckAlert
      sxOverride={{
        justifyContent: "flex-start",
        ...coverageCheckAlertStyleOverride,
      }}
    >
      <Stack spacing={1} direction="column">
        <StatusDetailsForTreatmentView
          patient={patient}
          currentAccount={currentAccount}
          latestCoverageCheck={latestCoverageCheck}
          eligibilityCheckError={eligibilityCheckError}
          onRunClick={onRun}
          isLoading={isLoading}
          disabled={disabled}
        />
      </Stack>
    </CoverageCheckAlert>
  );
};
