type MsgType =
  | {
      type: "Ping";
    }
  | {
      type: "SetActiveAuth";
    }
  | {
      type: "LaunchPortal";
      data: { authId: string; url: string };
    }
  | {
      type: "SetSupportDetails";
    }
  | {
      type: "SupportLaunchPortal";
      data: { followUpId: number };
    };

export class WebExtensionPubSub {
  private static instance: WebExtensionPubSub;
  private listeners: ((event: MessageEvent) => void)[];

  private constructor() {
    this.listeners = [];
  }

  public static getInstance(): WebExtensionPubSub {
    if (!WebExtensionPubSub.instance) {
      WebExtensionPubSub.instance = new WebExtensionPubSub();
    }
    return WebExtensionPubSub.instance;
  }

  public send(msg: MsgType): void {
    window.postMessage({ ...msg, origin: "SCWebsite" }, window.location.origin);
  }

  public addListener(
    fn: (event: MessageEvent<{ type: string }>) => void
  ): void {
    window.addEventListener("message", fn);
    this.listeners.push(fn);
  }

  public removeListeners(): void {
    this.listeners.forEach((fn) => window.removeEventListener("message", fn));
    this.listeners = [];
  }
}

/* 
  Usage example (in the component):
  1. Get instance using WebExtensionPubSub.getInstance()
  2. Add listeners
  3. Use send method to send message to the extension
  4. Don't forget to remove all listeners on component unmount
*/
