import gql from "graphql-tag";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import RequestResendButton from "@@components/RequestResendButton";
import BaseButton from "@@components/BaseButton";
import { ActionRequiredRowContainer } from "./ActionRequiredRowContainer";
import { useConfig } from "../../../hooks";

const FollowUpButtons = styled(BaseButton)`
  margin-left: 10px;
  width: 200px;
`;

export const ActionRequiredBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  white-space: normal;
  padding: 15px;
  margin-left: 5px;

  b {
    margin: 0 5px;
  }
`;

const updateAuthorizationStatusMutation = gql`
  mutation updateAuthorizationStatusForMarkPending(
    $id: Int!
    $status: AuthorizationStatusEnum!
  ) {
    updateAuthorizationStatus(id: $id, status: $status) {
      id
    }
  }
`;

export type SendingFailedRowProps = React.ComponentProps<
  typeof SendingFailedRow
>;

export const SendingFailedRow: React.VoidFunctionComponent<{
  authorizationId: string;
  authorizationFormId?: number | null;
  insuranceCompanyId?: string | undefined;
  patient: { firstName: string; lastName: string };
  resendText: string;
}> = ({
  authorizationId,
  insuranceCompanyId,
  authorizationFormId,
  patient,
  resendText,
}) => {
  const alert = useAlert();
  const [updateAuthorizationStatus] = useMutation(
    updateAuthorizationStatusMutation,
    { refetchQueries: ["allAuthorizations"] }
  );
  const config = useConfig();
  const onClick = async () => {
    try {
      await updateAuthorizationStatus({
        variables: {
          id: authorizationId,
          status: config.CONSTANTS.AUTHORIZATION_STATUSES.PENDING,
        },
      });

      alert.info("Authorization status updated to pending");
    } catch (e) {
      alert.error("Authorization update failed");
    }
  };

  const patientName = `${_.upperFirst(patient.firstName)} ${_.upperFirst(
    patient.lastName
  )}`;
  return (
    <ActionRequiredRowContainer>
      <ActionRequiredBanner>
        {`This authorization for ${patientName} failed to be sent. This can happen if the fax is too long or if the fax number is invalid. ` +
          "Please verify the fax number and resend to resolve this issue."}
        <RequestResendButton
          authorizationId={authorizationId}
          insuranceCompanyId={insuranceCompanyId}
          authorizationFormId={authorizationFormId}
        >
          <FollowUpButtons>{resendText}</FollowUpButtons>
        </RequestResendButton>
        <FollowUpButtons onClick={onClick}>Mark Pending</FollowUpButtons>
      </ActionRequiredBanner>
    </ActionRequiredRowContainer>
  );
};
