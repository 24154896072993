import { parseISO, isMatch, parse, addYears, isBefore } from "date-fns";
import { useEffect, useState } from "react";
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps
} from "@samacare/design/core";
import { Controller, UseControllerProps, useFormContext } from "react-hook-form";
import _ from "lodash";

export function getValidDate(value: string | Date | null | undefined): Date | null {
  if (value instanceof Date) return value;
  if (!value) return null;

  const formats = ["yyyy/MM/dd", "yyyy-MM-dd", "MM/dd/yyyy", "MM / dd / yyyy"];

  // eslint-disable-next-line no-restricted-syntax
  for (const format of formats) {
    if (isMatch(value, format)) return parse(value, format, new Date());
  }

  const parsedISO = parseISO(value);
  return Number.isNaN(parsedISO.getTime()) ? null : parsedISO;
}

export type DatePickerProps = MuiDatePickerProps<Date>
  & UseControllerProps
  & { required?: boolean; warnIfPast?: boolean };

const DatePicker: React.FC<DatePickerProps> = ({
  name,
  rules = {},
  required = false,
  disabled = false,
  shouldUnregister = false,
  label = "",
  defaultValue,
  warnIfPast = false,
}) => {
  const { control, trigger, setValue, watch } = useFormContext();
  const [warn, setWarn] = useState<string>();

  const dateValue = getValidDate(watch(name));

  const handleBlur = async () => {
    if (dateValue && dateValue.getFullYear() < 100) {
      setValue(name, addYears(dateValue, 2000), { shouldDirty: true });
    }

    await trigger(name);
  };

  useEffect(() => {
    if (!warnIfPast || !dateValue) {
      setWarn("");
      return;
    }

    const todayMidnight = new Date();
    todayMidnight.setHours(0, 0, 0, 0);

    if (isBefore(dateValue, todayMidnight)) {
      setWarn("Past date selected");
    } else {
      setWarn("");
    }
  }, [dateValue, setWarn, warnIfPast]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules, required }}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { ref }, fieldState: { error } }) => (
        <MuiDatePicker
          label={label}
          disabled={disabled}
          slotProps={{
            textField: {
              helperText:
                error?.type === "required"
                  ? `${label} required`
                  : error?.message ?? warn ?? "",
              id: `DatePicker-${name}`,
              error: Boolean(error || !!warn),
              InputLabelProps: { shrink: true },
              required,
              onBlur: handleBlur,
              defaultValue,
              inputProps: { "data-cy": _.camelCase(`field.${name}`) }
            },
          }}
          inputRef={ref}
          format="MM/dd/yyyy"
          value={dateValue}
          onChange={async (option) => setValue(name, option, { shouldDirty: true })}
          onClose={async () => {
            await trigger(name);
          }}
          sx={{ flex: 1 }}
        />
      )}
    />
  );
};

export default DatePicker;
