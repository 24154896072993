import {
  useForm,
  SubmitHandler,
  FormProvider,
  TextField,
} from "@samacare/form";

import {
  Box,
  Alert,
  Stack,
  Typography,
  LoadingButton,
} from "@samacare/design/core";

import { useAuth } from "../../providers/AuthProvider";
import { AuthTitle } from "./AuthTitle";
import { isInvalidCodeError } from "../../providers/AuthProvider/AuthProvider";

type AuthCodeVerificationForm = {
  code: string;
};

export type OnSubmitParams = {
  code: string;
};

export const AuthCodeVerification = ({
  onSubmit,
}: {
  onSubmit: ({ code }: OnSubmitParams) => Promise<void>;
}) => {
  const methods = useForm<AuthCodeVerificationForm>();
  const { error, loading, onSignOut } = useAuth();

  const handleSubmit: SubmitHandler<AuthCodeVerificationForm> = async ({
    code,
  }: OnSubmitParams) => {
    await onSubmit({ code });
  };

  return (
    <FormProvider {...methods}>
      <AuthTitle>Enter code</AuthTitle>
      <Typography variant="body2" sx={{ marginTop: "-25px" }}>
        Enter the code on your authenticator app
      </Typography>
      <form
        name="codeVerification"
        onSubmit={methods.handleSubmit(handleSubmit)}
        noValidate
      >
        <Stack spacing={2}>
          <Box pt={1}>
            <TextField
              type="text"
              inputMode="numeric"
              fullWidth
              size="medium"
              name="code"
              label="Code"
              InputLabelProps={{
                shrink: false,
              }}
            />
          </Box>
          {error && (
            <Alert color="error">
              {error}
              {isInvalidCodeError(error) && ". Please try again in 5 seconds"}
            </Alert>
          )}
          <LoadingButton
            loading={loading}
            type="submit"
            disabled={
              !methods.formState.isDirty ||
              (!!error && isInvalidCodeError(error))
            }
            size="large"
            variant="contained"
          >
            Verify Code
          </LoadingButton>
          <LoadingButton
            size="large"
            loading={loading}
            onClick={async () => {
              await onSignOut();
            }}
          >
            Back to Login
          </LoadingButton>
        </Stack>
      </form>
    </FormProvider>
  );
};
