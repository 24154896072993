import _ from "lodash";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import styled from "styled-components";
import strings from "Resources/strings";
import {
  Authorization,
  AuthorizationStatusEnum,
  IdentifierCaptureType,
  TerminalStatusUpdateType,
} from "@samacare/graphql";

import {
  useUpdateAuthorization,
  useUpdateAuthorizationStatus,
} from "../graphql/Authorization";
import { useCurrentAccount } from "../graphql/Account";
import BaseButton from "./BaseButton";
import {
  formatNonApprovalTypeLabel,
  getNonApprovalTypesOptions,
} from "../util/nonApprovalTypes";
import Tooltip from "@samacare/design/core/Tooltip";
import { Autocomplete, Button, DatePicker, TextField } from "@samacare/design";
import { DialogModal } from "@@components/DialogModal";
import { useConfig } from "@@hooks/config";
import { useTheme } from "@samacare/design/core/styles";
import { PlacesType } from "react-tooltip";
import { useFeatureFlag } from "@@hooks/useFeatureFlag";
import {
  authorizationStatusDisplayName,
  normalizeNonApprovalResponseDueBy,
} from "../util/authUtils";
import {
  getFollowUpTypesOptions,
  formatFollowUpTypeLabel,
} from "../util/followUpTypeUtils";

export interface UpdateAuthorizationStatusButtonProps {
  children: React.ReactNode;
  authorization: Authorization;
  defaultTo?: string;
  onUpdateSuccess?: () => void;
  styleOverrides?: object;
  tooltipPlace?: PlacesType | "top-start";
  disableTooltip?: boolean;
}

const FieldContainer = styled.div`
  margin-top: 16px;
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.red};
  text-align: center;
  margin-bottom: 20px;
  white-space: normal;
`;

type Option = {
  value: string;
  label: string;
};

export const UpdateAuthorizationStatusButton = ({
  children,
  authorization,
  defaultTo,
  onUpdateSuccess,
  styleOverrides,
  tooltipPlace,
  disableTooltip,
}: UpdateAuthorizationStatusButtonProps) => {
  const [selectedOption, setSelectedOption] = useState<Option>({
    value: "",
    label: "",
  });
  const [selectedFollowUpType, setSelectedFollowUpType] =
    useState<Option | null>(null);
  const [selectedNonApprovalType, setSelectedNonApprovalType] =
    useState<Option | null>(null);
  const [open, setOpen] = useState(false);
  const [portalAuthorizationId, setPortalAuthorizationId] =
    useState<string>("");
  const [responseDueBy, setResponseDueBy] = useState<Date | null>(null);
  const [nonApprovalInfo, setNonApprovalInfo] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [invalidDateError, setInvalidDateError] = useState<string | null>(null);
  const [account] = useCurrentAccount();

  const config = useConfig();
  const theme = useTheme();
  const alert = useAlert();
  const STATUSES = config.CONSTANTS.AUTHORIZATION_STATUSES;

  const originalStatus = authorization.status;
  const originalPortalAuthorizationId =
    authorization.portalAuthorizationId ?? "";

  const shouldDisplayNonApprovalReasons = (status: string) => {
    // Always display non-approval reasons for statuses listed in STATUSES_REQUIRING_NONAPPROVAL_REASON (e.g. Denied)
    // Only display non-approval reasons for Action Required if it's a Sama User.
    // Otherwise, user is correcting their own mistake and is not considered a non-approval
    return !!(
      _.includes(
        config.CONSTANTS.STATUSES_REQUIRING_NONAPPROVAL_REASON,
        status
      ) ||
      (_.includes([STATUSES.ACTION_REQUIRED], status) && account?.isSamaUser)
    );
  };

  let originalNonApprovalType: string;
  let originalFollowUpType: string;
  let originalResponseDueBy: Date | null = null;
  let originalNonApprovalInfo = "";
  let latestNonApprovalId: number | null = null;

  if (shouldDisplayNonApprovalReasons(originalStatus)) {
    const hasNonApproval =
      (authorization.authorizationNonApprovals ?? []).length > 0;
    const latestNonApproval = hasNonApproval
      ? _.last(_.sortBy(authorization.authorizationNonApprovals, "updatedAt"))
      : undefined;

    latestNonApprovalId = latestNonApproval?.id ?? null;

    if (latestNonApproval?.type) {
      originalNonApprovalType =
        config.CONSTANTS.NONAPPROVAL_TYPES[latestNonApproval.type];
    }

    if (authorization.followUp?.type) {
      originalFollowUpType = authorization.followUp.type;
    }

    if (latestNonApproval?.responseDueBy) {
      // responseDueBy stored in the database is in YYYY-MM-DD format
      originalResponseDueBy = normalizeNonApprovalResponseDueBy(
        latestNonApproval.responseDueBy
      );
    }

    if (latestNonApproval?.details) {
      originalNonApprovalInfo = latestNonApproval.details;
    }
  }

  const enhancedDrugs = useFeatureFlag<
    { drugName: string; drugOptionId: number }[]
  >(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.PostSubmissionEnhancedMessaging
  );

  const enhancedMessagingEnabled = _.some(
    enhancedDrugs ?? [],
    (drug: { drugName: string; drugOptionId: number }) =>
      drug.drugOptionId === authorization.DrugOptionId
  );

  const [updateAuthorizationStatus] = useUpdateAuthorizationStatus();
  const [updateAuthorization] = useUpdateAuthorization();

  const resetFields = () => {
    setResponseDueBy(null);
    setNonApprovalInfo("");
    setErrorMessage(null);
    setSelectedFollowUpType(null);
    setSelectedNonApprovalType(null);
    setInvalidDateError(null);
  };

  const shouldDisplayAdditionalComments = (
    nonApprovalType: string | null | undefined
  ) => {
    return (
      (enhancedMessagingEnabled && account?.isSamaUser) ||
      (nonApprovalType &&
        _.includes(
          config.CONSTANTS.NONAPPROVALS_REQUIRING_ADDITIONAL_INFO,
          nonApprovalType
        ))
    );
  };

  const isAdditionalCommentsRequired =
    selectedNonApprovalType != null &&
    _.includes(
      config.CONSTANTS.NONAPPROVALS_REQUIRING_ADDITIONAL_INFO,
      selectedNonApprovalType.value
    );

  const setOriginalReasonAndFollowUpFields = () => {
    if (originalNonApprovalType) {
      setSelectedNonApprovalType({
        value: originalNonApprovalType,
        label: formatNonApprovalTypeLabel(originalNonApprovalType),
      });

      if (originalNonApprovalInfo) {
        setNonApprovalInfo(originalNonApprovalInfo);
      }
    }

    if (originalFollowUpType) {
      setSelectedFollowUpType({
        value: originalFollowUpType,
        label: formatFollowUpTypeLabel(originalFollowUpType),
      });
    }

    if (originalResponseDueBy) {
      setResponseDueBy(originalResponseDueBy);
    }
  };

  const toggleModal = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }

    if (!open) {
      // Set default values when opening
      const defaultSelected = defaultTo || authorization.status;
      setSelectedOption({
        value: defaultSelected,
        label: authorizationStatusDisplayName(defaultSelected),
      });
      setPortalAuthorizationId(authorization.portalAuthorizationId ?? "");

      if (!defaultTo) {
        // If not using a suggested status then populate fields with original values
        if (shouldDisplayNonApprovalReasons(defaultSelected)) {
          setOriginalReasonAndFollowUpFields();
        }
      }

      setErrorMessage(null);
      setInvalidDateError(null);
    }

    setOpen(!open);
  };

  const getErrorMessage = () => {
    if (selectedOption.value === "") {
      return "Please select a status";
    }

    if (
      shouldDisplayNonApprovalReasons(selectedOption.value) &&
      _.isNil(selectedNonApprovalType)
    ) {
      return "Please select a reason for status change";
    }

    if (isAdditionalCommentsRequired && !nonApprovalInfo) {
      return "Please add additional info";
    }

    if (invalidDateError) {
      return "Please select a valid date";
    }

    return null;
  };

  const statusChangeHandler = (selected: any) => {
    if (!selected) {
      return;
    }

    const message =
      selected.value ===
      config.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT
        ? strings.UPDATE_AUTH_STATUS.EDIT_AND_RESEND_NOTE
        : null;
    setSelectedOption(selected);
    setErrorMessage(message);
    resetFields();
  };

  const nonApprovalReasonChangeHandler = (selected: any) => {
    setSelectedNonApprovalType(selected);
    if (!shouldDisplayAdditionalComments(selected?.value)) {
      setNonApprovalInfo("");
    }
  };

  const responseDueByChangeHandler = (selectedDate: Date | null) => {
    if (!selectedDate) {
      setResponseDueBy(null);
      return;
    }

    // Mui-datepicker defaults to selectedDate + 00:00:00 using the system's local timezone
    setResponseDueBy(selectedDate);
  };

  const getAdditionalComments = () => {
    if (!shouldDisplayAdditionalComments(selectedNonApprovalType?.value)) {
      return null;
    }

    return (
      <FieldContainer>
        <TextField
          label="Additional comments"
          multiline
          fullWidth
          value={nonApprovalInfo}
          required={isAdditionalCommentsRequired}
          InputLabelProps={{
            shrink: true,
            sx: {
              "& .MuiFormLabel-asterisk": {
                verticalAlign: "middle",
                color: theme.palette.error.main,
              },
            },
          }}
          inputProps={{
            maxLength: 500, // Maximum characters allowed
          }}
          onChange={(event) => {
            setNonApprovalInfo(event.target.value);
          }}
          helperText={`${nonApprovalInfo?.length ?? 0}/500 characters`}
        />
      </FieldContainer>
    );
  };

  const getReasonInputs = () => {
    if (shouldDisplayNonApprovalReasons(selectedOption.value)) {
      const nonApprovalReasonSelect = (
        <Autocomplete
          data-cy="controlNonApprovalReason"
          autoHighlight={true}
          options={getNonApprovalTypesOptions(
            selectedOption.value as AuthorizationStatusEnum
          )}
          getOptionLabel={(option) => option.label}
          value={selectedNonApprovalType}
          renderInput={(params) => (
            <TextField
              {...params}
              required={shouldDisplayNonApprovalReasons(selectedOption.value)}
              InputLabelProps={{
                shrink: true,
                sx: {
                  "& .MuiFormLabel-asterisk": {
                    verticalAlign: "middle",
                    color: theme.palette.error.main,
                  },
                },
              }}
              label="Reason"
            />
          )}
          onChange={(event, newValue) => {
            nonApprovalReasonChangeHandler(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          filterSelectedOptions
          filterOptions={(options, { inputValue }) => {
            return options.filter((option) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
          }}
        />
      );

      const nextStepsSelect = (
        <Autocomplete
          autoHighlight={true}
          options={getFollowUpTypesOptions(
            selectedOption.value as AuthorizationStatusEnum
          )}
          getOptionLabel={(option) => option.label}
          value={selectedFollowUpType}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              label="SamaCare expected next step"
            />
          )}
          onChange={(event, selected) => {
            setSelectedFollowUpType(selected);
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          filterSelectedOptions
          filterOptions={(options, { inputValue }) => {
            return options.filter((option) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
          }}
        />
      );

      const responseDueByPicker = (
        <DatePicker
          label="Response due by"
          name="updateAuthorizationStatus-responseDueBy"
          value={responseDueBy}
          slotProps={{
            textField: {
              fullWidth: true,
              InputLabelProps: {
                shrink: true,
              },
            },
          }}
          onError={(error) => {
            setInvalidDateError(error);
          }}
          onChange={(newValue) => {
            responseDueByChangeHandler(newValue);
          }}
        />
      );

      return (
        <>
          <FieldContainer>{nonApprovalReasonSelect}</FieldContainer>
          {enhancedMessagingEnabled && account?.isSamaUser && (
            <>
              {_.isNil(authorization?.PortalId) && (
                <FieldContainer>{nextStepsSelect}</FieldContainer>
              )}
              <FieldContainer>{responseDueByPicker}</FieldContainer>
            </>
          )}
          {getAdditionalComments()}
        </>
      );
    }
    return null;
  };

  let allowedStatuses = _.without(
    config.CONSTANTS.AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES,
    config.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT
  );

  if (authorization.PortalId) {
    allowedStatuses =
      config.CONSTANTS.PORTAL_AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES;
  }

  const updateStatus = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (getErrorMessage()) {
      setErrorMessage(getErrorMessage());
    } else {
      try {
        if (
          portalAuthorizationId !== originalPortalAuthorizationId &&
          originalStatus ===
            (selectedOption.value as AuthorizationStatusEnum) &&
          !allowedStatuses.includes(
            selectedOption.value as AuthorizationStatusEnum
          )
        ) {
          // Only updating portalAuthorizationId (status is unchanged), while using a
          // status that is not selectable (e.g. Draft)
          await updateAuthorization({
            variables: {
              id: parseInt(authorization.id),
              patch: {
                portalAuthorizationId,
                identifierCaptureType: IdentifierCaptureType.UserDirect,
              },
            },
          });
        } else {
          if (
            !shouldDisplayAdditionalComments(selectedNonApprovalType?.value)
          ) {
            setNonApprovalInfo("");
          }
          await updateAuthorizationStatus({
            variables: {
              id: parseInt(authorization.id),
              status: selectedOption.value as AuthorizationStatusEnum,
              followUpType: selectedFollowUpType?.value,
              nonApprovalId: latestNonApprovalId,
              nonApprovalInfo,
              nonApprovalType: selectedNonApprovalType?.value,
              responseDueBy,
              terminalStatusUpdateType: TerminalStatusUpdateType.User,
              ...(portalAuthorizationId === originalPortalAuthorizationId
                ? {}
                : {
                    portalAuthorizationId,
                    identifierCaptureType: IdentifierCaptureType.UserDirect,
                  }),
            },
          });
        }

        setOpen(false);
        if (onUpdateSuccess) {
          onUpdateSuccess();
        }
      } catch (err) {
        alert.error("There was an error updating the Authorization");
      }
    }
  };

  const isUpdateDisabled = () => {
    if (
      (selectedOption.value as AuthorizationStatusEnum) === originalStatus &&
      selectedNonApprovalType?.value === originalNonApprovalType &&
      selectedFollowUpType?.value === originalFollowUpType &&
      _.isEqual(responseDueBy, originalResponseDueBy) &&
      portalAuthorizationId === originalPortalAuthorizationId &&
      nonApprovalInfo === originalNonApprovalInfo
    ) {
      // if nothing was changed, then disable update
      return true;
    }

    // logic to determine if update is disabled is the same as getErrorMessage
    return getErrorMessage() !== null;
  };

  const statusUpdateOptions = _.map(allowedStatuses, (option) => ({
    value: option,
    label: authorizationStatusDisplayName(option),
  }));

  const getContent = () => {
    return (
      <>
        <Autocomplete
          data-cy="controlUpdateAuthToStatus"
          autoHighlight={true}
          options={statusUpdateOptions}
          getOptionLabel={(option) => option.label}
          value={selectedOption}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              label="Update the status of this authorization"
            />
          )}
          onChange={(event, newValue) => {
            statusChangeHandler(newValue);
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          filterSelectedOptions
          filterOptions={(options, { inputValue }) => {
            return options.filter((option) =>
              option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
          }}
        />
        {!_.isNil(authorization.PortalId) && (
          <FieldContainer>
            <TextField
              label="Portal authorization ID"
              fullWidth
              value={portalAuthorizationId}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                setPortalAuthorizationId(event.target.value);
              }}
            />
          </FieldContainer>
        )}
        {getReasonInputs()}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </>
    );
  };

  const getActions = () => {
    const bgColor = isUpdateDisabled()
      ? theme.palette.divider
      : theme.palette.primary.main;
    return (
      <>
        <Button
          sx={{
            textDecoration: "underline",
            "&:hover": {
              background: "transparent",
            },
          }}
          onClick={toggleModal}
        >
          Cancel
        </Button>
        <Button
          sx={{
            borderRadius: "20px",
            background: bgColor,
            color: "white",
            "&:hover": {
              background: bgColor,
              cursor: isUpdateDisabled() ? "not-allowed" : "pointer",
            },
          }}
          onClick={updateStatus}
          disabled={isUpdateDisabled()}
          data-cy="actionUpdateAuthUpdateDetails"
        >
          Save
        </Button>
      </>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <DialogModal
        open={open}
        onClose={toggleModal}
        title="Update authorization status"
        content={getContent()}
        actions={getActions()}
        divider
        preventDefault
      />

      <Tooltip
        title="Manually update this authorization status"
        placement={tooltipPlace ?? "top"}
      >
        <BaseButton
          style={{
            padding: "3px",
            fontSize: "12px",
            display: "inline-block",
            ...(styleOverrides || {}),
          }}
          onClick={toggleModal}
          data-cy="actionUpdateAuthStatus"
          data-tooltip-id="updateAuthorizationStatusButton"
          disabled={disableTooltip}
        >
          {children}
        </BaseButton>
      </Tooltip>
    </div>
  );
};
