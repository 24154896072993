import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Stack, Typography } from "@samacare/design";
import { useEffect, useState } from "react";
import Add from "@samacare/design/core/icons/Add";
import { useFormContext } from "@samacare/form2";
import { useFieldArray } from "react-hook-form";
import { ServiceCodeInput, } from "./ServiceCodeInput";
// eslint-disable-next-line no-shadow
export var ServiceCodeSelectorDataSources;
(function (ServiceCodeSelectorDataSources) {
    ServiceCodeSelectorDataSources["AllServiceCodes"] = "allServiceCodes";
    ServiceCodeSelectorDataSources["DrugOptionsOnly"] = "drugOptionsOnly";
    ServiceCodeSelectorDataSources["HcpcsCodesOnly"] = "hcpcsCodesOnly";
})(ServiceCodeSelectorDataSources || (ServiceCodeSelectorDataSources = {}));
const DEFAULT_STATE = {
    code: '',
    drugName: '',
    quantity: '',
    quantityType: '',
    startDate: '',
    endDate: ''
};
export const ServiceCodeSelector = (props) => {
    const { required = false, disabled = false, limit = 5, allServiceCodes, drugOptions, hcpcsCodes, loading, drugOptionRequired = true, dataSource = ServiceCodeSelectorDataSources.AllServiceCodes, name, optionalFields, showFirstServiceCode = true, disableSelectedService, highlightedEnhancedServices } = props;
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({ control, name });
    const [firstServiceCodeSelected, setFirstServiceCodeSelected] = useState(false);
    useEffect(() => {
        if (showFirstServiceCode && fields.length > 0 && disableSelectedService) {
            setFirstServiceCodeSelected(true);
        }
        if (showFirstServiceCode && fields.length === 0) {
            append({});
        }
    }, [append, fields.length, showFirstServiceCode, disableSelectedService]);
    const serviceCodes = () => {
        switch (dataSource) {
            case ServiceCodeSelectorDataSources.DrugOptionsOnly: {
                return drugOptions;
            }
            case ServiceCodeSelectorDataSources.HcpcsCodesOnly: {
                return hcpcsCodes;
            }
            default: {
                return allServiceCodes;
            }
        }
    };
    return (_jsxs(Stack, { spacing: 2, maxWidth: 600, children: [fields.map(({ id }, idx) => (_jsx(ServiceCodeInput, { required: required, disabled: disabled || (idx === 0 && firstServiceCodeSelected), drugOptionRequired: drugOptionRequired, optionalFields: optionalFields, serviceCodes: drugOptionRequired &&
                    dataSource === ServiceCodeSelectorDataSources.AllServiceCodes &&
                    idx === 0
                    ? drugOptions
                    : serviceCodes(), handleDelete: disabled ? undefined : () => remove(idx), isFirst: idx === 0, loading: loading, name: `${name}.${idx}`, highlightedEnhancedServices: highlightedEnhancedServices, firstServiceCodeSelected: firstServiceCodeSelected }, id))), limit > 1 && !disabled && (_jsx(Box, { sx: { display: "flex", justifyContent: "flex-start" }, children: fields.length === limit ? (_jsx(Typography, { variant: "caption", color: "GrayText", children: `Maximum of ${limit} Service Codes reached` })) : (_jsx(Button, { onClick: () => append(DEFAULT_STATE), startIcon: _jsx(Add, {}), "data-cy": "actionAddServiceCode", children: "Add Service Code" })) }))] }));
};
