import _ from "lodash";
import { PureComponent } from "react";
import { withAlert } from "react-alert";
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import { Flex } from "@@ui-kit";
import FormSubmitButtons from "AuthorizationSharedComponents/FormSubmitButtons";

import { TaggerErrorModal } from "./TaggingError";
import { withFormById } from "../../graphql/AuthorizationForm";
import {
  setFormFields as setFormFieldsAction,
  setRequiredFields as setRequiredFieldsAction,
  unsetRequiredFields as unsetRequiredFieldsAction,
} from "../../reducers/formReducer";
import { Legend } from "./Legend";
import { Editor } from "./Editor";
import { getDisplayInput } from "./Editor/utils";
import {
  getInputValue,
  transformSpecToInputs,
} from "../../util/inputConfigurationManager";
import AttachmentUpload from "../AuthorizationSharedComponents/AttachmentUpload";
import Info from "@samacare/design/core/icons/InfoOutlined";
import SubmitButtons from "@@components/SubmitButtons";

const Container = styled(Flex)`
  height: calc(100% - 50px);
  flex-direction: column;
`;

const UnsupportedAuthorizationView = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.red};
  border: 2px solid #5d5d5d;
  margin-bottom: 40px;
  padding: 50px;
`;

const TestViewHeader = styled.div`
  color: ${(props) => props.theme.darkGray};
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const WarningPill = styled.div`
  border-radius: 20px;
  background: white;
  color: ${(props) => props.color || props.theme.red};
  border: 1px solid ${(props) => props.color || props.theme.red};
  padding: 4px;
  font-size: 11px;
  margin: 0 8px 8px 0;
`;

const IncorrectAssociationHelper = styled.div`
  border: 1px solid ${(props) => props.theme.purple};
  padding: 20px;
`;

const TriggerIncorrectAssociationText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.purple};
  font-weight: bold;
`;

const Note = styled.div`
  color: rgba(0, 0, 0, 0.6);
  margin: 14px 0;
`;

const HeaderContainer = styled(Flex)`
  justify-content: flex-start;
  width: 1011.5px;
  margin-left: ${(props) => (props.withLegendMargin ? "22px" : 0)};
`;

const ReportContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  gap: 5px;
`;

const ReportPart = styled.div`
  color: #5151ee;
  text-decoration: underline;
  cursor: pointer;
`;

const importances = CONFIG.CONSTANTS.INPUT_IMPORTANCE;
export class PDFEditor extends PureComponent {
  state = {
    isSubmitting: false,
    showReportErrorModal: false,
  };

  overlayScrollRef = null;

  submit = () => {
    const { onSubmit } = this.props;
    onSubmit(
      () => {
        this.setState({ isSubmitting: true });
      },
      () => {
        this.setState({ isSubmitting: false });
      },
      () => {
        return true;
      },
      this.onInvalidFormState
    );
  };

  onInvalidFormState = () => {
    const { disabled, form, results, segmentLoading, setFormFields } =
      this.props;

    window.scrollTo({ top: 200, behavior: "smooth" });
    const inputs = transformSpecToInputs(form.specification);
    const scrollTo = inputs.find(
      (input) =>
        !getInputValue(input, results) &&
        input.y &&
        input.importance === importances.REQUIRED.key
    );

    if (scrollTo) {
      const { top } = getDisplayInput({
        // XXX(ndhoule): This is an lame hack to work around the fact that when
        // we designed this, this and the Editor component were colocated, so we
        // could calculate exactly where each input is relative to the image it
        // appears above. After we split the editor and this apart for reuse,
        // that assumption no longer held and scrolling broke. In practice,
        // though, the dimensions of form pages are almost always the same, so
        // here, we just hack a fixed value into place, and almost all of the
        // time we'll scroll the target into view.
        //
        // If you end up experiencing pain as a result of this kludge: we should
        // turn the form elements in Editor into a proper `form` with localized
        // validation rules. That would let us implement "scroll into view"
        // logic like this in a localized manner.
        dimensionRatios: form.imgURLs.reduce(
          (acc, _imgURL, i) => ({
            ...acc,
            [i]: { heightRatio: 0.988, widthRatio: 0.988 },
          }),
          {}
        ),
        disabled,
        form,
        input: scrollTo,
        inputs,
        results,
        segmentLoading,
        setFormFields,
        inputTypes: CONFIG.CONSTANTS.TAGGER_INPUT_TYPES,
      });

      this.overlayScrollRef.scrollTo({
        top: Math.max(top - 50, 0),
        behavior: "smooth",
      });
    }
  };

  render() {
    const {
      authorization,
      disabled,
      form,
      isTagger,
      loading,
      onBack,
      reportErrorUserDetails,
      requiredSamaTypes,
      results,
      segmentLoading,
      setFormFields,
      setRequiredFields,
      unsetRequiredFields,
      hideAttachment,
      showEMRDocumentOption,
      account,
      withLegendMargin,
      withNewFooter,
      displayInfo,
    } = this.props;
    const { isSubmitting, showReportErrorModal } = this.state;

    if (loading) {
      return null;
    }

    const formSubmitButtons = (
      <FormSubmitButtons
        back={onBack}
        submit={this.submit}
        disabled={isSubmitting || segmentLoading}
        loading={isSubmitting || segmentLoading}
      />
    );

    if (
      _.includes(
        CONFIG.CONSTANTS.AUTHORIZATION_VERSION.LEGACY,
        authorization.version
      )
    ) {
      return (
        <Container>
          <UnsupportedAuthorizationView>
            This is an authorization created on a previous version of our
            software. While this view is no longer supported, you can still see
            a full reference of your authorization on the next step.
          </UnsupportedAuthorizationView>
          {formSubmitButtons}
        </Container>
      );
    }

    if (!form) {
      return (
        <Container>
          <UnsupportedAuthorizationView>
            This is an authorization was created using a form that has been
            removed from our system. While this view is no longer supported, you
            can still see a full reference of your authorization on the next
            step.
          </UnsupportedAuthorizationView>
          {formSubmitButtons}
        </Container>
      );
    }

    const samaTypeArray = _.flatten(form.specification || []).map(
      ({ samaTypes }) => samaTypes
    );
    const samaTypeWarnings = _.reject(requiredSamaTypes, (type) => {
      if (Array.isArray(type)) {
        return _.some(samaTypeArray, (t) => samaTypeArray.includes(t));
      }
      return samaTypeArray.includes(type);
    });

    return (
      <Container>
        <HeaderContainer withLegendMargin={withLegendMargin}>
          <Legend
            items={[
              importances.REQUIRED,
              importances.IMPORTANT,
              importances.DEFAULT,
              {
                key: "item_legend_not_important",
                color: "#777777",
                title: "Filled / Low priority",
              },
            ]}
          />
        </HeaderContainer>

        <Flex>
          <Editor
            disabled={disabled}
            form={form}
            results={results}
            scrollRef={(ref) => {
              this.overlayScrollRef = ref;
            }}
            segmentLoading={segmentLoading}
            setFormFields={setFormFields}
            setRequiredFields={setRequiredFields}
            unsetRequiredFields={unsetRequiredFields}
          />
          {isTagger && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "8px",
                width: "35%",
              }}
            >
              <TestViewHeader>SamaTypes Not Tagged on Form:</TestViewHeader>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {samaTypeWarnings.map((type, i) => {
                  if (Array.isArray(type)) {
                    return (
                      <WarningPill
                        key={`PDF_WARNING_PILL${i}`}
                      >{`${type[0]} OR ${type[1]}`}</WarningPill>
                    );
                  }
                  return <WarningPill key={`${i}-${type}`}>{type}</WarningPill>;
                })}
              </div>
              <TestViewHeader>Prepopulated Config Data:</TestViewHeader>
              {
                // This map returns only true for text keys / sama types and ignores custom fields
                Object.keys(results)
                  .filter((key) => Number.isNaN(Number.parseInt(key, 10)))
                  .map((key, i) => (
                    <div key={`showKeys-${i}-${key}`}>
                      <b>{key}</b>
                      {`: ${results[key]}`}
                    </div>
                  ))
              }
            </div>
          )}
        </Flex>
        {!isTagger && !withNewFooter && (
          <>
            <IncorrectAssociationHelper>
              {"See an error in the form above? "}
              <TriggerIncorrectAssociationText
                data-cy="actionReviewReportError"
                onClick={() => {
                  this.setState({ showReportErrorModal: true });
                }}
              >
                Click here
              </TriggerIncorrectAssociationText>
              {" to have SamaCare help resolve the issue."}
            </IncorrectAssociationHelper>
            {hideAttachment ? null : (
              <AttachmentUpload
                showEMRDocumentOption={showEMRDocumentOption}
                authorization={authorization}
                account={account}
              />
            )}
            {formSubmitButtons}
          </>
        )}
        {withNewFooter && (
          <>
            <Note>
              <Info style={{ margin: "0 6px" }} />
              <span style={{ fontSize: "12px" }}>{displayInfo}</span>
            </Note>
            <SubmitButtons
              back={onBack}
              submit={this.submit}
              submitText="Continue to Cover Letter"
              disabled={isSubmitting || segmentLoading}
              loading={isSubmitting || segmentLoading}
            />
            <ReportContainer>
              <ReportPart
                data-cy="actionReviewReportError"
                onClick={() => {
                  this.setState({ showReportErrorModal: true });
                }}
              >
                Report
              </ReportPart>
              form errors
            </ReportContainer>
          </>
        )}
        {showReportErrorModal === true && (
          <TaggerErrorModal
            close={() => {
              this.setState({ showReportErrorModal: false });
            }}
            form={form}
            reportErrorUserDetails={reportErrorUserDetails}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    disabled: state.form.disabled,
    segmentLoading: state.form.segmentLoading,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setFormFields(fields) {
    dispatch(setFormFieldsAction(fields));
  },
  setRequiredFields(toSet) {
    dispatch(setRequiredFieldsAction(toSet));
  },
  unsetRequiredFields(toUnset) {
    dispatch(unsetRequiredFieldsAction(toUnset));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withFormById)(withAlert()(PDFEditor)));
