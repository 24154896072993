import { useEffect, useMemo, useState } from "react";
import { Account, Authorization } from "@samacare/graphql";
import { LeftRightCenterV } from "../LeftRight";
import SegmentPhone from "../Segment/SegmentPhone";
import SegmentText from "../Segment/SegmentText";
import { useSelector } from "../../configureStore";
import { useFieldConfigs } from "../../hooks/useFieldConfigs";
import { useHighlightRequiredFields } from "../../hooks/useHighlightRequiredFields";
import { useSet } from "../../hooks/useSet";
import { requireAuthEnrollmentRequiredFields } from "../../util/enrollUtils";
import { FormField } from "../../routes/PortalAuthorization/PortalAuthorizationLegacy/FormField";

const df = window.CONFIG.DEFAULT_FIELDS;

interface RequesterFieldsProps {
  isDisabled?: boolean;
  auth: Authorization;
  account: Account;
  saveChanges: () => Promise<void>;
}
export const RequesterFields: React.FC<RequesterFieldsProps> = (props) => {
  const set = useSet();
  const highlightRequiredFields = useHighlightRequiredFields();

  const results = useSelector((state) => state.form.results);

  const [phone, setPhone] = useState<string | undefined>(
    results[df.REQUESTED_BY_PHONE.key]
  );
  const [extension, setExtension] = useState<string | undefined>(
    results[df.REQUESTED_BY_EXTENSION.key]
  );

  const fields = useMemo(
    () => [
      df.REQUESTED_BY,
      {
        ...df.REQUESTED_BY_PHONE,
        required: requireAuthEnrollmentRequiredFields(props.auth),
      },
      df.REQUESTED_BY_EXTENSION,
    ],
    [props.auth]
  );
  const fieldConfigs = useFieldConfigs(fields, props.auth.formFieldConfig);

  useEffect(() => {
    if (phone == null) {
      setPhone(results[df.REQUESTED_BY_PHONE.key]);
    }

    if (extension == null) {
      setExtension(results[df.REQUESTED_BY_EXTENSION.key]);
    }
  }, [extension, phone, setPhone, setExtension, results]);

  return (
    <LeftRightCenterV style={{ columnGap: "8px" }}>
      <FormField
        title="Name"
        fieldConfig={fieldConfigs[df.REQUESTED_BY.key]}
        style={{ width: "100%" }}
      >
        <SegmentText
          disabled={props.isDisabled}
          handleChange={set}
          item={{
            key: df.REQUESTED_BY.key,
          }}
          highlightIfMissing={
            highlightRequiredFields &&
            fieldConfigs[df.REQUESTED_BY.key].isRequired
          }
        />
      </FormField>

      <FormField
        title="Phone"
        fieldConfig={fieldConfigs[df.REQUESTED_BY_PHONE.key]}
        style={{ width: "100%" }}
      >
        <SegmentPhone
          disabled={props.isDisabled}
          handleChange={set}
          item={{
            key: df.REQUESTED_BY_PHONE.key,
          }}
          highlightIfMissing={
            highlightRequiredFields &&
            fieldConfigs[df.REQUESTED_BY_PHONE.key].isRequired
          }
        />
      </FormField>

      <FormField
        title="Extension"
        fieldConfig={fieldConfigs[df.REQUESTED_BY_EXTENSION.key]}
        style={{ width: "100%" }}
      >
        <LeftRightCenterV style={{ position: "relative" }}>
          <SegmentText
            disabled={props.isDisabled}
            handleChange={set}
            item={{
              key: df.REQUESTED_BY_EXTENSION.key,
            }}
            highlightIfMissing={
              highlightRequiredFields &&
              fieldConfigs[df.REQUESTED_BY_EXTENSION.key].isRequired
            }
          />
        </LeftRightCenterV>
      </FormField>
    </LeftRightCenterV>
  );
};
