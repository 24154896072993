import { Section } from "@@ui-kit";
import { Box } from "@samacare/design/core";
import { Redirect, Route, Switch } from "react-router-dom";
import HelpCenter from "@samacare/design/core/icons/HelpCenter";

import Admin from "../../routes/Admin";
import Dashboard from "../../routes/Dashboard";
import PDFTagger from "../../routes/PDFTagger";
import { Support } from "../../routes/Support";
import ROUTE_PATHS from "../../routes/ROUTE_PATHS";
import Enrollments from "../../routes/Enrollments";
import NewReferral from "../../routes/NewReferral";
import SamaStoryPlayer from "../../routes/SamaStoryPlayer";
import NewAuthorization from "../../routes/NewAuthorization";
import SecureFileViewer from "../../routes/SecureFileViewer";
import { AuthorizationList } from "../../routes/PCAuthorizations";
import PortalAuthorization from "../../routes/PortalAuthorization";
import AppNavigationMenu from "../../components/AppNavigationMenu";
import { WebExtensionStateProvider } from "../../contexts/webExtension";
import { BenefitsVerifications } from "../../routes/BenefitsVerifications";
import MultiPartyAuthorization from "../../routes/MultiPartyAuthorization";
import { UserSettingsPage } from "../../routes/UserSettings/UserSettingsPage";
import { PatientFiltersStateProvider } from "../../routes/PCAuthorizations/reducer";
import { PatientTreatments } from "../../routes/PatientTreatmentView";
import { EdiAuthCreate } from "../../routes/EdiAuth/EdiAuthCreate";
import { PortalAuthorizationCreate } from "../../routes/PortalAuthorization/Create";
import { useConfig, useFeatureFlag } from "../../hooks";
import { PortalStateProvider } from "app/contexts/portalContext";
import { CptAmaDisclaimer } from "../../components/CptAmaDisclaimer";

export const Application: React.FC = () => {
  const config = useConfig();
  const isEdiAuthEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.AvailityEdiSubmission
  );
  const enableBlendedPortalWorkflow = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableBlendedPortalWorkflow
  );

  return (
    <Box
      sx={{
        margin: "0 auto",
        display: "flex",
        height: "100%",
        padding: 0,
        flexDirection: "column",
      }}
    >
      <Section>
        <Box
          sx={{
            margin: "0 auto",
            display: "flex",
            height: "100%",
            width: "100%",
          }}
        >
          <AppNavigationMenu key="navigationMenu" />
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              width: "100%",
              height: "100%",
              minWidth: "0",
              position: "relative",
              justifyContent: "space-between",
            }}
          >
            <WebExtensionStateProvider>
              <PatientFiltersStateProvider>
                <PortalStateProvider>
                  <Switch>
                    <Route path={ROUTE_PATHS.DASHBOARD.path}>
                      <Dashboard />
                    </Route>
                    <Route path={ROUTE_PATHS.PATIENTS_TREATMENT.path}>
                      <PatientTreatments />
                    </Route>
                    <Route path={ROUTE_PATHS.ENROLLMENTS.path}>
                      <Enrollments />
                    </Route>
                    {isEdiAuthEnabled && (
                      <Route
                        component={EdiAuthCreate}
                        path={ROUTE_PATHS.EDI_AUTH_CREATE.path}
                      />
                    )}
                    {enableBlendedPortalWorkflow && (
                      <Route
                        component={PortalAuthorizationCreate}
                        path={ROUTE_PATHS.PORTAL_AUTH_NEW.path}
                      />
                    )}
                    <Route
                      component={BenefitsVerifications}
                      path={ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}
                    />
                    <Route
                      component={NewAuthorization}
                      path={ROUTE_PATHS.FORM_AUTHORIZATION.path}
                    />
                    <Route
                      component={Admin}
                      path={ROUTE_PATHS.ADMIN_ROUTE.path}
                    />
                    <Route
                      component={UserSettingsPage}
                      path={ROUTE_PATHS.USER_SETTINGS_ROUTE.path}
                    />
                    <Route
                      component={Support}
                      path={ROUTE_PATHS.OPS_HUB_ROUTE.path}
                    />
                    <Route
                      component={PortalAuthorization}
                      path={ROUTE_PATHS.PORTAL_AUTHORIZATION.path}
                    />
                    <Route
                      component={NewReferral}
                      path={ROUTE_PATHS.REFERRAL.path}
                    />
                    <Route
                      component={SecureFileViewer}
                      path={ROUTE_PATHS.SECURE_FILE.path}
                    />
                    <Route
                      component={SamaStoryPlayer}
                      path={ROUTE_PATHS.STORY_PLAYER.path}
                    />
                    <Route
                      component={MultiPartyAuthorization}
                      path={ROUTE_PATHS.MUTLI_PARTY_AUTH.path}
                    />
                    <Route
                      component={PDFTagger}
                      path={ROUTE_PATHS.TAGGER.path}
                    />
                    <Route
                      component={AuthorizationList}
                      path={ROUTE_PATHS.AUTHORIZATION_LIST.path}
                    />
                    <Route
                      component={AuthorizationList}
                      path={ROUTE_PATHS.BENEFITS_VERIFICATION_SUMMARY.path}
                    />
                    <Route
                      component={HelpCenter}
                      path={ROUTE_PATHS.HELP_CENTER_ROUTE.path}
                    />

                    {/* Must be last - anything after this route won't show up */}
                    <Route>
                      <Redirect to={ROUTE_PATHS.AUTHORIZATION_LIST.path} />
                    </Route>
                  </Switch>
                </PortalStateProvider>
              </PatientFiltersStateProvider>
            </WebExtensionStateProvider>
            <CptAmaDisclaimer />
          </Box>
        </Box>
      </Section>
    </Box>
  );
};
