import gql from "graphql-tag";
import _ from "lodash";
import { graphql } from "@apollo/client/react/hoc";

import noteInfo from "./fragments/noteInfo";
import { withDefaultProps } from "./utils";

export const AUTHORIZATION_NOTES_QUERY_NAME = "getAuthorizationNotes";
export const withAuthorizationNotesQuery = gql`
  query getAuthorizationNotes($authorizationId: Int!) {
    getAuthorizationNotes(authorizationId: $authorizationId) {
      ...noteInfo
    }
  }
  ${noteInfo}
`;

export const withAuthorizationNotes = graphql(withAuthorizationNotesQuery, {
  options: (props: { authorization: { id: string } }) => ({
    variables: { authorizationId: props.authorization.id },
  }),
  props: withDefaultProps(({ data }) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    authorizationNotes: _.get(data, "getAuthorizationNotes"),
  })),
});

export const withCreateAuthorizationNote = graphql(
  gql`
    mutation createAuthorizationNote($note: String!, $authorizationId: Int!) {
      createAuthorizationNote(note: $note, authorizationId: $authorizationId) {
        ...noteInfo
      }
    }
    ${noteInfo}
  `,
  {
    name: "createAuthorizationNote",
    options: () => ({ refetchQueries: () => [AUTHORIZATION_NOTES_QUERY_NAME] }),
  }
);

export const createReviewNoteMutation = gql`
  mutation createReviewNote($authorizationId: Int!) {
    createReviewNote(authorizationId: $authorizationId) {
      ...noteInfo
    }
  }
  ${noteInfo}
`;

export const withCreateReviewNote = graphql(createReviewNoteMutation, {
  name: "createReviewNote",
});

export const withToggleNoteHidden = graphql(
  gql`
    mutation toggleNoteHidden($id: Int!) {
      toggleNoteHidden(id: $id) {
        ...noteInfo
      }
    }
    ${noteInfo}
  `,
  { name: "toggleNoteHidden" }
);
