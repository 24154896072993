import { Box, MenuItem, Stack } from "@samacare/design";
import SearchIcon from "@samacare/design/core/icons/Search";
import { AutocompleteField, useFormContext } from "@samacare/form2";
import { Ndc } from "@samacare/graphql";

export type BaseNdcSelectorProps = {
  loading: boolean;
  findNdc: (s: string) => void;
  ndcs: Ndc[];
  required?: boolean;
  disabled?: boolean;
  name: string;
};

const renderOption = (
  props: object,
  option: Ndc,
  { selected }: { selected: boolean }
) => {
  const { code, brandName } = option;

  return (
    <MenuItem {...props} key={code} selected={selected}>
      {brandName} - {code}
    </MenuItem>
  );
};

export const BaseNdcSelector: React.FC<BaseNdcSelectorProps> = ({
  required = false,
  disabled = false,
  loading,
  name,
  findNdc,
  ndcs,
}) => {
  const { watch } = useFormContext();
  const selected = watch(name);

  return (
    <Stack spacing={2} width="100%">
      <Box>Primary service</Box>
      <Stack direction="row" spacing={1}>
        <AutocompleteField<Ndc, false, true, false>
          data-cy="controlNdcCode"
          disablePortal
          loading={loading}
          required={required}
          disabled={disabled}
          label="NDC code"
          options={ndcs}
          name={name}
          value={selected?.code ? selected : null}
          getOptionLabel={(option: Ndc) =>
            `${option.brandName} - ${option.code}`
          }
          renderOption={renderOption}
          isOptionEqualToValue={(option: Ndc, value: Ndc) =>
            option.code === value.code
          }
          disableClearable
          TextFieldInputProps={{
            startAdornment: <SearchIcon />,
          }}
          onInputChange={(_e, v) => {
            findNdc(v);
          }}
        />
      </Stack>
    </Stack>
  );
};
