import React, { useState } from "react";
import SimplifiedModal from "@@components/SimplifiedModal";
import { TextField, Typography } from "@samacare/design/core";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import UploadAttachments from "../Attachments/UploadAttachments";
import strings from "Resources/strings";
import { reportMissingInformationMutation } from "../../../graphql/AuthorizationForm";
import { useSelector } from "../../../configureStore";

const { MISSING_INFORMATION_TYPES } = CONFIG.CONSTANTS;

const ReportMissingFormModal = ({
  onClose,
  onFinish,
  open,
  authorization,
  insuranceCompanyId,
  state,
}) => {
  const attachments = useSelector((s) => s.form.attachments);
  const fileKey = attachments?.[0]?.awsKey;
  const [missingInformationDetails, setMissingInformationDetails] =
    useState("");
  const alert = useAlert();

  const [reportMissingInformation] = useMutation(
    reportMissingInformationMutation
  );

  const handleSubmit = async () => {
    try {
      await reportMissingInformation({
        variables: {
          type: MISSING_INFORMATION_TYPES.FORM,
          authorizationId: authorization.id,
          insuranceCompanyId,
          details: missingInformationDetails,
          state,
          fileKey,
        },
      });
      alert.info("Request submitted");
      if (onFinish) onFinish();
    } catch (e) {
      alert.error(strings.MISSING_INFORMATION.ERROR);
    }
  };

  return (
    <SimplifiedModal
      header="Report Missing Form"
      onClick={onClose}
      submit={async () => {
        await handleSubmit();
      }}
      open={open}
      submitText="Report Missing Form"
      submitDisabled={!missingInformationDetails}
      back={onClose}
    >
      <Typography variant="body1">Details of Missing Form</Typography>
      <TextField
        multiline
        name="note"
        label="Form details"
        placeholder="Include details for missing form such as Payer name."
        value={missingInformationDetails}
        onChange={(e) => setMissingInformationDetails(e.target.value)}
        rows={3}
        required
      />
      <UploadAttachments
        attachments={attachments}
        authorization={authorization}
        showEMRDocumentOption={false}
        multiple={false}
        header="Upload Missing Form"
        details="Help SamaCare resolve your inquiry faster by uploading the missing form. The form should not contain any added information like patient or physician name."
        hideUploader={attachments?.length >= 1}
      />
    </SimplifiedModal>
  );
};

export default ReportMissingFormModal;
