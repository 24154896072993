import _ from "lodash";
import {
  DataGridPro,
  GridColDef,
  Stack,
  Link,
  GridFilterListIcon,
  Typography,
} from "@samacare/design/core";
import Chip from "@samacare/design/core/Chip";
import GridWrapper from "../../components/DataGrid/GridWrapper";
import { PatientTreatmentDetailPage } from "./PatientTreatmentDetailPage";
import GridQuickFilter from "../../components/DataGrid/GridQuickFilter";
import { EnhancedServicesIcon } from "@samacare/component";

import {
  PatientTreatmentsGridRow,
  usePatientTreatmentsContext,
} from "./PatientTreatmentsProvider";
import { PatientPresetFilter } from "@samacare/graphql";
import { format } from "date-fns";
import { cleanDrugName } from "../../../../server/src/shared_code";
import { useConfig, useFeatureFlag } from "../../hooks";

const PatientTreatmentToolBar = ({
  quickFilterText,
}: {
  quickFilterText?: string;
}) => {
  const { setPresetFilter, presetFilter } = usePatientTreatmentsContext();

  return (
    <Stack direction="row" alignItems="center" spacing={1} p={2}>
      <GridQuickFilter
        inputHeightOverride={40}
        inputWidthOverride={300}
        searchWord={quickFilterText}
        data-cy="patient-treatment-quick-filter"
      />
      <Chip
        label="Expiring PAs"
        icon={<GridFilterListIcon />}
        variant={presetFilter ? "filled" : "outlined"}
        color="primary"
        onClick={() => {
          const newFilterValue = presetFilter
            ? undefined
            : PatientPresetFilter.ExpiringAuths;
          setPresetFilter(newFilterValue);
        }}
      />
    </Stack>
  );
};

const RowAction: React.FC<{ label: string }> = ({ label }) => (
  <Link href=" " component="button">
    {label}
  </Link>
);

export const PatientTreatmentsGrid: React.FC = () => {
  const {
    toggleDrawer,
    setActiveRow,
    setPaginationModel,
    paginationModel,
    patientTreatmentCount,
    rows,
    filterModel,
    setFilterModel,
    loading,
  } = usePatientTreatmentsContext();

  const config = useConfig();
  const patientTreatmentGridQueryEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnablePatientTreatmentGridQuery
  );

  const columns: GridColDef[] = [
    {
      field: "mrn",
      headerName: "MRN",
      editable: false,
      minWidth: 100,
      renderCell: (params) => params.row.mrn || params.row.institutionPatientId, //prefer mrn if it exists, otherwise fall back to institutionPatientId
    },
    {
      field: "firstName",
      headerName: "First name",
      editable: false,
      minWidth: 180,
    },
    {
      field: "lastName",
      headerName: "Last name",
      editable: false,
      minWidth: 180,
    },
    {
      field: "dob",
      headerName: "DOB",
      editable: false,
      minWidth: 180,
      valueFormatter: ({ value }) => {
        // The DOB is stored as date only, which will be defaulted to UTC in JS.
        // To display the DOB in the table, we need to prevent the date from being converted to the local timezone.
        // Ex: 1950-01-01 -> 01/01/1950
        const date = new Date(value);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          timeZone: "UTC",
        }).format(date);

        return formattedDate;
      },
    },
    {
      field: "payerName",
      headerName: "Payer",
      editable: false,
      minWidth: 180,
    },
    {
      field: "drugNames",
      headerName: "Treatments",
      editable: false,
      minWidth: 300,
      renderCell: (params: { row: PatientTreatmentsGridRow }) => {
        if (patientTreatmentGridQueryEnabled) {
          return (params.row.drugs ?? []).map((drug, i) => {
            const { drugName, enhancedServices } = drug;

            return (
              <Chip
                label={
                  <Stack direction="row" spacing={0.75} alignItems="center">
                    <Typography>
                      {cleanDrugName(drugName) ?? drugName}
                    </Typography>
                    {(enhancedServices ?? []).length > 0 && (
                      <EnhancedServicesIcon sx={{ fontSize: 18 }} />
                    )}
                  </Stack>
                }
                size="small"
                sx={{
                  marginLeft: ({ spacing }) => spacing(1),
                }}
                key={i}
              />
            );
          });
        }

        return (params.row.treatments ?? []).map((treatment, i) => {
          const { drugName, availableEnhancedServices } = treatment;

          return (
            <Chip
              label={
                <Stack direction="row" spacing={0.75} alignItems="center">
                  <Typography>{drugName}</Typography>
                  {availableEnhancedServices &&
                    availableEnhancedServices.length > 0 && (
                      <EnhancedServicesIcon sx={{ fontSize: 18 }} />
                    )}
                </Stack>
              }
              size="small"
              sx={{
                marginLeft: ({ spacing }) => spacing(1),
              }}
              key={i}
            />
          );
        });
      },
    },
    {
      field: "actionTypes",
      headerName: "Actions",
      editable: false,
      minWidth: 180,
      renderCell: (params: { row: PatientTreatmentsGridRow }) => {
        if (patientTreatmentGridQueryEnabled) {
          return (
            <Stack direction="row" spacing={2}>
              {params.row.actionTypes?.map((actionType, i) => {
                if (actionType == null) return null;

                switch (actionType) {
                  case "COMPLETE_BV":
                    return <RowAction label="Complete BV" key={i} />;
                  case "START_BV":
                    return <RowAction label="Start BV" key={i} />;
                  case "START_PA":
                    return <RowAction label="Start PA" key={i} />;
                  case "RENEW_PA":
                    return <RowAction label="Renew PA" key={i} />;
                  default:
                    return null;
                }
              })}
            </Stack>
          );
        }

        return (
          <Stack direction="row" spacing={2}>
            {params.row.showBvSupportNote && <RowAction label="Start BV" />}
            {params.row.hasBvInDraft && <RowAction label="Complete BV" />}

            {_.find(params.row.treatments, (tx) => tx.expiringAuth) && (
              <RowAction label="Renew PA" />
            )}
            {_.find(
              params.row.treatments,
              (tx) =>
                (tx.bvs ?? []).length > 0 &&
                (tx.authorizations ?? []).length === 0
            ) && <RowAction label="Start PA" />}
          </Stack>
        );
      },
    },
    {
      field: "lastUpdated",
      headerName: "Last updated",
      editable: false,
      minWidth: 180,
      valueFormatter: ({ value }) => format(new Date(value), "MM/dd/yyyy"),
    },
  ];

  const searchWords = filterModel?.quickFilterValues ?? [];
  return (
    <GridWrapper headerText="Patients">
      <DataGridPro
        loading={loading}
        disableColumnFilter
        disableColumnMenu
        slots={{
          toolbar: PatientTreatmentToolBar,
        }}
        rows={rows ?? []}
        columns={columns}
        onRowClick={(params) => {
          toggleDrawer(true);
          setActiveRow(_.find(rows, { patientId: params.row.patientId }));
        }}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={patientTreatmentCount ?? 0}
        filterMode="server"
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => {
          setFilterModel(newFilterModel);
        }}
        localeText={{
          toolbarQuickFilterPlaceholder: "Search by Patient or Drug name",
        }}
        pageSizeOptions={[25, 50]}
        slotProps={{
          row: {
            "data-cy": "patientTreatmentRow",
          },
          pagination: {
            SelectProps: {
              inputProps: { "data-cy": "patientTreatmentPaginationSelect" },
            },
          },
          toolbar: {
            quickFilterText: searchWords?.[0] ?? null,
          },
        }}
      />
      <PatientTreatmentDetailPage />
    </GridWrapper>
  );
};
