import parsePhoneNumber, {
  parsePhoneNumberFromString,
} from "libphonenumber-js";

export const formatPhoneForDisplay = (phone?: string): string => {
  if (!phone) {
    return "";
  }

  const parsedPhone = parsePhoneNumber(phone);
  if (!parsedPhone) {
    return phone;
  }

  const intlPhone = parsedPhone.formatInternational();
  if (intlPhone.startsWith("+1")) {
    return parsedPhone.formatNational();
  }

  return intlPhone;
};

export const isE164 = (phoneNumber: string) => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);

  if (!parsedNumber) {
    return false;
  }
  if (!parsedNumber.isValid()) {
    return false;
  }

  return phoneNumber === parsedNumber.format("E.164");
};

export const formatPhoneNumber = (
  str?: string,
  sliceFirst10?: boolean,
): string => {
  if (str == null) return "";

  // Remove all non-digit characters
  // eslint-disable-next-line unicorn/prefer-string-replace-all
  const cleanedStr = str.replace(/\D/g, "");

  // Take the last 10 digits
  const last10Digits = sliceFirst10
    ? cleanedStr.slice(0, 10)
    : cleanedStr.slice(-10);

  if (last10Digits.length < 10) return ""; // Return as is if less than 10 digits

  // Format the 10 digits
  return `${last10Digits.slice(0, 3)}-${last10Digits.slice(3, 6)}-${last10Digits.slice(6)}`;
};

export const formatPhoneNumberPerMask = (phoneNumber?: string): string => {
  // mask - (999) 999-9999
  if (phoneNumber == null) return "";

  // eslint-disable-next-line unicorn/prefer-string-replace-all
  const cleaned = phoneNumber.replace(/\D/g, "").slice(-10);

  if (cleaned.length !== 10) {
    return phoneNumber;
  }

  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
};
