import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import { MdChevronRight as ChevronArrowRight } from "@react-icons/all-files/md/MdChevronRight";

export const Container = styled.div`
  width: 640px;
  border: 1px solid ${(props) => props.theme.lightGray};
  padding: 20px;
  border-radius: 3px;
  display: ${(props) => props.display || "block"};
`;

const MatchingTitle = styled.div`
  font-weight: 700;
  margin-bottom: 10px;
`;

const SearchResult = styled.div`
  display: flex;
  border-radius: 3px;
  padding: 5px;
  &:hover {
    background-color: ${(props) => props.theme.green};
    cursor: pointer;
    color: white;
  }
  span {
    width: 30%;
  }
  .gray {
    color: ${(props) => props.theme.lightGray};
  }
`;

const formatMRN = (input) => {
  if (input.length > 13) {
    return `${input.slice(0, 5)}...${input.slice(-5)}`;
  }
  return input;
};

const PatientSearchList = ({ setPatient, patients }) => (
  <Container>
    <MatchingTitle>Matching patients (click to select)</MatchingTitle>
    {_.map(patients, (patient) => (
      <div
        key={`patientSearch${patient.id}`}
        onClick={() => {
          setPatient(patient);
        }}
      >
        <SearchResult>
          <ChevronArrowRight style={{ marginRight: "5px" }} />
          <span>
            {patient.firstName} {patient.lastName}
          </span>{" "}
          <span className="gray">
            {moment.parseZone(patient.dob).format("LL")}
          </span>
          {(patient.institutionPatientId || patient.mrn) && (
            <span className="gray">
              {`MRN: ${formatMRN(patient.mrn || patient.institutionPatientId)}`}
            </span>
          )}
        </SearchResult>
      </div>
    ))}
  </Container>
);

export default PatientSearchList;
