import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { IconButton, MenuItem, Stack } from "@samacare/design";
import { EnhancedServicesIcon } from "@samacare/component";
import DeleteIcon from "@samacare/design/core/icons/Delete";
import { AutocompleteField } from "@samacare/form2";
import { createFilterOptions } from "@samacare/design/core/base";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import { Rule } from "./components/Rule";
import { QuantityAndType, DatesOfService } from "./optionalFields";
// eslint-disable-next-line no-shadow
export var ServiceCodeOptionalFields;
(function (ServiceCodeOptionalFields) {
    ServiceCodeOptionalFields["Quantity"] = "quantity";
    ServiceCodeOptionalFields["DateOfService"] = "dateOfService";
})(ServiceCodeOptionalFields || (ServiceCodeOptionalFields = {}));
const renderOption = (highlightedEnhancedServices) => (props, option, { selected }) => {
    const { drugName, code, enhancedServices, drugOptionId } = option;
    const shouldHighlight = !_.isEmpty(highlightedEnhancedServices) &&
        !_.isEmpty(enhancedServices) &&
        !_.isEmpty(_.intersection(highlightedEnhancedServices, enhancedServices));
    return (_createElement(MenuItem, Object.assign({}, props, { key: drugOptionId !== null && drugOptionId !== void 0 ? drugOptionId : (code + drugName), selected: selected }),
        code,
        ": ",
        drugName,
        " ",
        shouldHighlight && _jsx(EnhancedServicesIcon, { sx: { fontSize: 20 }, hover: false }, `icon-${code}`)));
};
export const ServiceCodeInput = ({ required = true, disabled = false, handleDelete, drugOptionRequired, optionalFields, name, serviceCodes, isFirst, loading, highlightedEnhancedServices, firstServiceCodeSelected = false }) => {
    const { watch } = useFormContext();
    const selected = watch(name);
    // If the first service code is pre-populated, we disable it. But the optional fields should still be enabled.
    const disableOptionalFields = (isFirst && firstServiceCodeSelected) ? false : disabled;
    return (_jsxs(Stack, { spacing: 2, width: "100%", children: [!isFirst && _jsx(Rule, {}), _jsxs(Stack, { direction: "row", spacing: 1, children: [_jsx(AutocompleteField, { "data-cy": "controlServiceCode", disablePortal: true, loading: loading, required: required, disabled: disabled, placeholder: "Type to search services...", label: isFirst ? "Primary Service Code" : "Additional Service Code", options: serviceCodes, name: name, value: (selected === null || selected === void 0 ? void 0 : selected.code) ? selected : null, filterSelectedOptions: true, filterOptions: createFilterOptions({ limit: 50 }), getOptionLabel: (option) => `${option.code}: ${option.drugName}`, renderOption: renderOption(highlightedEnhancedServices), sx: { flex: 4 }, isOptionEqualToValue: (option, value) => option.code === value.code, disableClearable: true }), !(isFirst && drugOptionRequired) && handleDelete && (_jsx(IconButton, { onClick: handleDelete, children: _jsx(DeleteIcon, {}) }))] }), (optionalFields === null || optionalFields === void 0 ? void 0 : optionalFields.includes(ServiceCodeOptionalFields.Quantity)) && (_jsx(QuantityAndType, { required: required, disabled: disableOptionalFields, name: name })), (optionalFields === null || optionalFields === void 0 ? void 0 : optionalFields.includes(ServiceCodeOptionalFields.DateOfService)) && (_jsx(DatesOfService, { required: required, disabled: disableOptionalFields, name: name }))] }));
};
