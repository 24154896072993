import {
  EnhancedServicesCard,
  EnhancedServicesCardHeader,
  EnhancedServicesAvatar,
  EnhancedServicesCardTitle,
} from "@samacare/component";
import { Link, Stack, Typography } from "@samacare/design";
import TipsAndUpdates from "@samacare/design/core/icons/TipsAndUpdates";
import { useTheme } from "@samacare/design/core/styles";
import { Authorization } from "@samacare/graphql";
import { cleanDrugName } from "../../../../../server/src/shared_code";

export const AppealTips = ({
  authorization,
}: {
  authorization: Authorization;
}) => {
  const theme = useTheme();
  const drugName =
    cleanDrugName(authorization.HCPCSCodes?.[0]?.drugName) ??
    authorization.HCPCSCodes?.[0]?.drugName ??
    "";

  return (
    <EnhancedServicesCard>
      <EnhancedServicesCardHeader
        title={
          <EnhancedServicesCardTitle title={`Appeal Tips for ${drugName}`} />
        }
        avatar={
          <EnhancedServicesAvatar>
            <TipsAndUpdates />
          </EnhancedServicesAvatar>
        }
        subheader="This denial may be eligible for appeal"
        subheaderTypographyProps={{ variant: "body2" }}
      />

      <Stack direction="column" spacing={0.5} sx={{ padding: "16px" }}>
        <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
          View payer decision below to see if the denial is eligible for appeal.
        </Typography>
        {authorization.drugConfiguration?.payerPolicyLink && (
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.primary }}
          >
            Review{" "}
            <Link
              href={authorization.drugConfiguration?.payerPolicyLink}
              target="_blank"
            >
              {`${authorization?.config?.INSURANCE_COMPANY} payer policy`}
            </Link>{" "}
            to help you decide on next steps.
          </Typography>
        )}
      </Stack>
    </EnhancedServicesCard>
  );
};
