
import ContentCopyIcon from "@samacare/design/core/icons/ContentCopy";
import CheckCircleIcon from "@samacare/design/core/icons/CheckCircle";
import { useCallback, useState } from "react";
import { Stack, Tooltip } from "@samacare/design";

interface ICopyableTextProps {
  label: string;
  textToCopy: string;
  tooltipText: string;
}

export const CopyableText: React.FC<
  ICopyableTextProps
> = ({ label, textToCopy, tooltipText: initialTooltipText }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const [tooltipText, setTooltipText] = useState(initialTooltipText);

  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess(true);
      setTooltipText("Copied!");
      setTimeout(() => {
        setCopySuccess(false);
        setTooltipText(initialTooltipText);
      }, 3000);
    } catch {
      setCopySuccess(false);
      setTooltipText("Copy failed");
      setTimeout(() => {
        setTooltipText(initialTooltipText);
      }, 3000);
    }
  };

  const toggleCopyIcon = useCallback(() => {
    setShowCopyIcon(prev => !prev);
  }, []);

  return (
    <Stack direction="row" justifyContent="flex-end" width="100%">
      <Tooltip placement="top" title={tooltipText}>
        <Stack
          onClick={copyText}
          onMouseEnter={toggleCopyIcon}
          onMouseLeave={toggleCopyIcon}
          sx={{ fontSize: "12px", color: "#666666", cursor: "pointer"}}
        >
          <Stack direction="row" alignItems="center">
            {label}
            <Stack width="16px" direction="row" alignItems="center" paddingLeft={1} display="inline-block">
              {!copySuccess && showCopyIcon && <ContentCopyIcon sx={{ fontSize: "14px"}} />}
              {copySuccess && <CheckCircleIcon 
              sx={{ color: "#4CAF50", fontSize: "14px"}}
              />}
            </Stack>
          </Stack>
        </Stack>
      </Tooltip>
    </Stack>
  );
};
