import { Amplify } from "aws-amplify";
import {
  AUTH_CLIENT_ID_STORAGE,
  AUTH_COGNITO_DOMAIN_STORAGE,
  AUTH_USERPOOL_STORAGE,
  COGNITO_REDIRECT_URL,
} from "./Auth.constants";
import { sendMsgToBgScript } from "app/util/bgScriptMsgUtils";
import { KeyValueStorageInterface } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

class CustomLocalStorage implements KeyValueStorageInterface {
  public send(type: string, data?: string | Record<string, any>) {
    // this function is async, but we don't need to wait for it to finish
    void sendMsgToBgScript({ type: "CognitoMsg", data: { type, data } });
  }

  public async setItem(key: string, value: string): Promise<void> {
    this.send("cognito:setItem", { key, value });
    return localStorage.setItem(key, value);
  }

  public async getItem(key: string): Promise<string | null> {
    return localStorage.getItem(key);
  }

  public async removeItem(key: string): Promise<void> {
    this.send("cognito:removeItem", { key });
    return localStorage.removeItem(key);
  }

  public async clear(): Promise<void> {
    this.send("cognito:clear");
    return localStorage.clear();
  }
}

export const configureAmplify = (): void => {
  const userPoolId = localStorage.getItem(AUTH_USERPOOL_STORAGE);
  const clientId = localStorage.getItem(AUTH_CLIENT_ID_STORAGE);
  const domain = localStorage.getItem(AUTH_COGNITO_DOMAIN_STORAGE);

  if (domain && clientId && userPoolId) {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId,
          userPoolClientId: clientId,
          signUpVerificationMethod: "code",
          loginWith: {
            email: true,
            oauth: {
              domain,
              redirectSignIn: [`${COGNITO_REDIRECT_URL}`],
              redirectSignOut: [COGNITO_REDIRECT_URL],
              scopes: [
                "email",
                "profile",
                "openid",
                "aws.cognito.signin.user.admin",
              ],
              responseType: "code",
            },
          },
        },
      },
    });

    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CustomLocalStorage());
  }
};
