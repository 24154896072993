import { DialogModal } from "@@components/DialogModal";
import { Button, Typography, useTheme } from "@samacare/design";
import { useState } from "react";
import { segmentTypewriter } from "../../../analytics/analytics";
import { useAuthorization } from "../../hooks/useAuthorization";
import { useAccount } from "../../providers/AccountProvider";
import { FollowUpFlow, PaType } from "../../../analytics/generated/segment";

export interface ContactPayerButtonProps {
  children: React.ReactNode;
  onClose: () => void;
}

export const ContactPayerButton = ({
  children,
  onClose,
}: ContactPayerButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const { authorization } = useAuthorization();
  const account = useAccount();

  const toggleModal = () => {
    if (open) {
      onClose();
    } else {
      segmentTypewriter.paFollowUp({
        followUpFlow: FollowUpFlow.ContactPayer,
        internalAuthId: Number(authorization?.id),
        paType: (authorization?.type as PaType | undefined) ?? undefined,
        institutionId: Number(authorization?.InstitutionId),
        institutionName: authorization?.institution?.name,
        institutionSpecialty:
          authorization?.institution?.practicingSpecialty ?? undefined,
        accountId: String(account?.id),
      });

      setOpen(!open);
    }
  };

  const getContent = () => {
    return (
      <div style={{ width: "500px" }}>
        <Typography sx={{ fontSize: 16 }}>
          Contact the payer directly to follow up on this authorization. For
          additional assistance, we recommend calling the provider support phone
          number provided on the back of the Member ID card.
        </Typography>
        <br />
        <Typography sx={{ fontSize: 14, color: theme.palette.text.secondary }}>
          Tip: You can manually update the status of this prior authorization
          after contacting the payer.
        </Typography>
      </div>
    );
  };

  const getActions = () => {
    const bgColor = theme.palette.primary.main;
    return (
      <Button
        sx={{
          borderRadius: "20px",
          background: bgColor,
          color: "white",
          "&:hover": {
            background: bgColor,
            cursor: "pointer",
          },
        }}
        onClick={onClose}
        data-cy="actionContactPayer"
      >
        Close
      </Button>
    );
  };

  return (
    <>
      <DialogModal
        open={open}
        onClose={toggleModal}
        title="Contact payer"
        content={getContent()}
        actions={getActions()}
        divider
        preventDefault
      />
      <div onClick={toggleModal}>{children}</div>
    </>
  );
};
