import { Tooltip } from "@samacare/design";
import Chip from "@samacare/design/core/Chip";

export const EditAndResubmitIndicator: React.FC = () => (
  <Tooltip title="Edits to this PA will overwrite the current draft. When submitted, it will permanently update the existing PA in SamaCare and send the revised information to the payer.">
    <Chip
      size="small"
      variant="filled"
      color="warning"
      label="Updating PA"
      sx={{
        width: "fit-content",
        mb: 1,
      }}
    />
  </Tooltip>
);
