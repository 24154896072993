import _ from "lodash";
import { Fragment, PureComponent } from "react";
import styled from "styled-components";

import AutomatedRow from "./AutomatedRow";
import SendingFailedRow from "./SendingFailedRow";
import AssociateCorrespondenceModal from "../AssociateCorrespondenceModal";

const TableContainer = styled.table`
  width: 100%;
  min-width: ${(props) => props.minWidth || "900px"};
  max-width: 1450px;
  border-spacing: 0 10px;
`;

const TableHeader = styled.tr`
  font-weight: 700;
  color: ${(props) => props.theme.darkGray};
`;

const Row = styled.tr``;

const DataRow = styled(Row)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.lightestGray};
    cursor: pointer;
  }
`;

const Data = styled.td`
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
`;

const LineBreak = styled.div`
  border-top: 1px solid ${(props) => props.theme.lightGray};
  margin: 15px 0;
`;

class ListTable extends PureComponent {
  state = { autoAssociationReviewAuth: null };

  handleAutomatedRowClick = async (row) =>
    this.setState({ autoAssociationReviewAuth: row.model });

  render() {
    const {
      dataRows,
      headers,
      keyOff,
      onClick,
      minWidth,
      ActionRow,
      showActionBanner,
    } = this.props;
    const { autoAssociationReviewAuth } = this.state;

    return (
      <>
        <TableContainer minWidth={minWidth}>
          <tbody>
            <TableHeader>
              {_.map(headers, (header, i) => (
                <Data key={`ListTableHeader${i}-${keyOff}`}>
                  <div style={{ margin: "20px 0" }}>{header}</div>
                </Data>
              ))}
            </TableHeader>
            {_.map(dataRows, (row, i) => {
              let content;
              if (row.requiresAssociationReview && showActionBanner) {
                content = (
                  <AutomatedRow
                    i={i}
                    keyOff={keyOff}
                    row={row}
                    handleAutomatedRowClick={this.handleAutomatedRowClick}
                  />
                );
              } else if (
                _.get(row.model, "status") ===
                  CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENDING_FAILED &&
                showActionBanner
              ) {
                content = <SendingFailedRow i={i} keyOff={keyOff} row={row} />;
              } else {
                const indexOrId = _.get(row, "model.id", i);

                content = (
                  <>
                    <DataRow
                      key={`ListTableRow${indexOrId}-${keyOff}`}
                      onClick={({ metaKey }) => {
                        if (onClick) {
                          onClick(row.model, metaKey);
                        }
                      }}
                    >
                      {_.map(row.cells, (cell, ind) => (
                        <Data
                          key={`ListTableCell${indexOrId}-${ind}-${keyOff}`}
                          isFirst={ind === 0}
                        >
                          {cell}
                        </Data>
                      ))}
                    </DataRow>
                    {ActionRow && (
                      <tr
                        key={`ListActionRow${_.get(
                          row,
                          "model.id",
                          i
                        )}-${keyOff}`}
                      >
                        <td colSpan={`${row.cells.length}`}>
                          <ActionRow
                            key={`ActionRow${_.get(
                              row,
                              "model.id",
                              i
                            )}-${keyOff}`}
                            authorization={row.model}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                );
              }
              return (
                <Fragment key={row.model.id}>
                  {content}
                  {ActionRow && i !== dataRows.length - 1 && (
                    <tr>
                      <td colSpan={`${row.cells.length}`}>
                        <LineBreak />
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </TableContainer>
        {autoAssociationReviewAuth && (
          <AssociateCorrespondenceModal
            allowDisassociation
            allowChanges
            authorization={autoAssociationReviewAuth}
            closeModal={() =>
              this.setState({ autoAssociationReviewAuth: null })
            }
          />
        )}
      </>
    );
  }
}

/**
 * @deprecated Use a MUI component instead
 */
export default ListTable;
