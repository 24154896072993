import { Alert, Typography } from "@samacare/design";
import AlertTitle from "@samacare/design/core/AlertTitle";

export const CARD_PLACEMENT = {
  AUTH_LIST: "authList",
  CONTEXT_HEADER: "contextHeader",
  PATIENT_TREATMENT_DETAIL: "patientTreatmentDetail",
} as const;

export type CardPlacement =
  (typeof CARD_PLACEMENT)[keyof typeof CARD_PLACEMENT];

export const GenericActionBanner = ({
  backgroundColor,
  color,
  icon,
  button,
  title,
  subtitle,
  dataCy,
  cardPlacement,
  severityOverride = "warning",
  useDefaultIcon = false,
  subtitleSxOverride = {},
}: {
  backgroundColor?: string;
  color: string;
  icon?: React.ReactNode;
  button: React.ReactNode;
  title?: string;
  subtitle?: string | React.ReactNode;
  dataCy: string;
  cardPlacement?: CardPlacement;
  severityOverride?: "warning" | "error";
  useDefaultIcon?: boolean;
  subtitleSxOverride?: {
    subtitleVariant?: "body2";
    color?: string;
  };
}) => {
  return (
    <Alert
      sx={{
        background: backgroundColor ?? null,
        color,
        margin:
          cardPlacement === CARD_PLACEMENT.CONTEXT_HEADER ? "5px 0px" : "5px",
        "& .MuiAlert-icon": {
          alignItems: "center",
          fontSize: "24px",
        },
        "& .MuiAlert-action": {
          alignItems: "center",
        },
      }}
      severity={severityOverride}
      icon={icon || (useDefaultIcon ? null : false)}
      action={button}
      data-cy={dataCy}
    >
      {title && <AlertTitle sx={{ margin: 0 }}>{title}</AlertTitle>}
      {subtitle && (
        <Typography
          variant={subtitleSxOverride.subtitleVariant ?? "body1"}
          color={subtitleSxOverride.color ?? ""}
        >
          {subtitle}
        </Typography>
      )}
    </Alert>
  );
};
