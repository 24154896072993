import * as React from "react";
import { Button, Box, Typography, useTheme } from "@samacare/design/core";

import { DialogModal } from "@@components/DialogModal";
import { DialogCopilotButton } from "@@components/DialogCopilotButton";
import { INSTRUCTION_MODAL_SHOWED_TIMES } from "../../../util/constants";
import { PurpleContainerForModal } from "./PurpleContainerForModal";
import { LearnMoreText } from "./LearnMoreText";

export const ExtensionInstructionDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onShowNewPortalModal: () => void;
}> = ({ open, onClose, onShowNewPortalModal }) => {
  const theme = useTheme();

  const handleChoosePortalClick = () => {
    const instructionModalShowedTimes = localStorage.getItem(
      INSTRUCTION_MODAL_SHOWED_TIMES
    );

    instructionModalShowedTimes
      ? localStorage.setItem(
          INSTRUCTION_MODAL_SHOWED_TIMES,
          String(Number(instructionModalShowedTimes) + 1)
        )
      : localStorage.setItem(INSTRUCTION_MODAL_SHOWED_TIMES, "1");

    onShowNewPortalModal();
  };

  const content = (
    <>
      <Typography sx={{ fontSize: "17px" }}>
        After submission click on CoPilot to save PAs in SamaCare.
      </Typography>
      <Box>
        <PurpleContainerForModal />
        <LearnMoreText />
      </Box>
    </>
  );

  const actions = (
    <>
      <Button
        onClick={onClose}
        sx={{
          textDecoration: "underline",
          "&:hover": {
            background: "transparent",
          },
        }}
      >
        Close
      </Button>
      <DialogCopilotButton
        text="Choose Portal"
        onClick={handleChoosePortalClick}
        sx={{
          borderRadius: "20px",
          background: theme.palette.primary.main,
          color: "white",
          "&:hover": {
            background: theme.palette.primary.main,
            cursor: "pointer",
          },
        }}
      />
    </>
  );

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      title="Capture and Store PAs in Any Portal with CoPilot"
      content={content}
      actions={actions}
      divider
    />
  );
};
