import * as React from "react";
import AssociationModal from "../AssociationModal";

export type AssociationViewRouterProps = {
  onClose: () => void;
  correspondence: { id: number };
  setCorrespondenceOnAuthorization: () => Promise<void>;
  currentAuthorization: {
    id: number;
  };
};

const AssociationViewRouter: React.VoidFunctionComponent<
  AssociationViewRouterProps
> = ({
  onClose,
  correspondence,
  setCorrespondenceOnAuthorization,
  currentAuthorization,
}) => {
  const { id } = currentAuthorization;

  return (
    <AssociationModal
      onClose={onClose}
      authorizationId={id}
      correspondence={correspondence}
      setCorrespondenceOnAuthorization={setCorrespondenceOnAuthorization}
    />
  );
};

export default AssociationViewRouter;
