import { useQuery } from "@apollo/client";
import _ from "lodash";
import {
  createContext,
  useContext,
  useMemo,
  useState,
  SetStateAction,
  Dispatch,
} from "react";

import { GridPaginationModel, GridFilterModel } from "@samacare/design/core";
import getServicesByPatientQuery from "./GetServicesByPatient.gql";
import getPatientGridDataQuery from "./GetPatientGridData.gql";
import {
  GetPatientGridDataQuery,
  GetPatientGridDataQueryVariables,
  GetTreatmentsByPatientQuery,
  GetTreatmentsByPatientQueryVariables,
} from "@@generated/graphql";
import {
  Treatment,
  Patient as PatientType,
  PatientPresetFilter,
  PatientRow,
} from "@samacare/graphql";
import { useGridFilterUrlSync } from "../BenefitsVerifications/gridHooks";
import { useConfig, useFeatureFlag } from "../../hooks";

export type PatientTreatmentsGridRow = PatientType &
  PatientRow & {
    drugs: string[];
    treatments: Treatment[];
    payerName?: string;
    hasBvInDraft: boolean;
    showBvSupportNote: boolean;
    lastUpdated: Date;
  };

type PatientTreatmentsContextType = {
  patientTreatmentCount?: number | null;
  open: boolean;
  toggleDrawer: (newOpen: boolean) => void;
  activeRow?: PatientTreatmentsGridRow;
  setActiveRow: Dispatch<SetStateAction<PatientTreatmentsGridRow | undefined>>;
  setPaginationModel: Dispatch<SetStateAction<GridPaginationModel>>;
  paginationModel?: GridPaginationModel;
  rows?: PatientTreatmentsGridRow[];
  filterModel?: GridFilterModel;
  setFilterModel: Dispatch<SetStateAction<GridFilterModel>>;
  presetFilter?: PatientPresetFilter;
  setPresetFilter: Dispatch<SetStateAction<PatientPresetFilter | undefined>>;
  loading: boolean;
};

const PatientTreatmentsContext = createContext<PatientTreatmentsContextType>({
  open: false,
  loading: true,
  setFilterModel: () => {},
  setPaginationModel: () => {},
  toggleDrawer: () => {},
  setActiveRow: () => {},
  setPresetFilter: () => {},
});

export const usePatientTreatmentsContext = (): PatientTreatmentsContextType =>
  useContext<PatientTreatmentsContextType>(PatientTreatmentsContext);

export const PatientTreatmentsProvider: React.FC = ({ children }) => {
  const [presetFilter, setPresetFilter] = useState<
    PatientPresetFilter | undefined
  >();

  /**
   * @state paginationModel
   */
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const { filterModel, setFilterModel } = useGridFilterUrlSync();

  const config = useConfig();
  const patientTreatmentGridQueryEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnablePatientTreatmentGridQuery
  );

  const searchWords = filterModel?.quickFilterValues ?? [];

  let patientTreatmentsData: any;
  let loading: boolean;

  /* 
    This is a temporary feature flag to enable the new PatientTreatmentsGridQuery while 
    providing a way to disable it in case it causes any unexpected issues.

    The rules-of-hooks rule is disabled because it does not apply in this case - the feature flag will 
    not change value between renders. 
  */
  if (patientTreatmentGridQueryEnabled) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const patientTreatmentsResult = useQuery<
      GetPatientGridDataQuery,
      GetPatientGridDataQueryVariables
    >(getPatientGridDataQuery, {
      variables: {
        offset: paginationModel.page * paginationModel.pageSize,
        limit: paginationModel.pageSize,
        filter: searchWords?.length > 0 ? searchWords[0] : null,
        presetFilter,
      },
      fetchPolicy: "network-only",
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    patientTreatmentsData = useMemo(() => {
      if (patientTreatmentsResult.loading || patientTreatmentsResult.error)
        return;
      return {
        patientTreatmentCount:
          patientTreatmentsResult.data?.getPatientGridData?.count,
        rows: (
          patientTreatmentsResult.data?.getPatientGridData?.patients ?? []
        ).map((entry) => {
          return {
            id: entry?.patientId,
            patientId: entry?.patientId,
            mrn: entry?.mrn,
            institutionPatientId: entry?.institutionPatientId,
            firstName: entry?.firstName,
            lastName: entry?.lastName,
            dob: entry?.dob,
            drugs: entry?.drugs,
            payerName: entry?.insuranceCompanyName,
            lastUpdated: entry?.treatment_updated_at,
            actionTypes: entry?.actionTypes,
          };
        }),
      };
    }, [
      patientTreatmentsResult.data,
      patientTreatmentsResult.loading,
      patientTreatmentsResult.error,
    ]);

    loading = patientTreatmentsResult.loading;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const deprecatedPatientTreatmentsResult = useQuery<
      GetTreatmentsByPatientQuery,
      GetTreatmentsByPatientQueryVariables
    >(getServicesByPatientQuery, {
      variables: {
        offset: paginationModel.page * paginationModel.pageSize,
        limit: paginationModel.pageSize,
        filter: searchWords?.length > 0 ? searchWords[0] : null,
        presetFilter,
      },
      fetchPolicy: "network-only",
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    patientTreatmentsData = useMemo(() => {
      if (
        deprecatedPatientTreatmentsResult.loading ||
        deprecatedPatientTreatmentsResult.error
      )
        return;
      return {
        patientTreatmentCount:
          deprecatedPatientTreatmentsResult.data?.getTreatmentsByPatient?.count,
        rows: (
          deprecatedPatientTreatmentsResult.data?.getTreatmentsByPatient
            ?.rows ?? []
        ).map((entry) => {
          return {
            id: entry?.patient?.id,
            patientId: entry?.patient?.id,
            ...entry?.patient,
            drugs: entry?.drugNames,
            treatments: entry?.treatments,
            payerName: entry?.patient?.primaryInsurance?.insuranceCompany?.name,
            hasBvInDraft: entry?.hasBvInDraft,
            showBvSupportNote: entry?.showBvSupportNote,
            lastUpdated: entry?.lastUpdated,
          };
        }),
      };
    }, [
      deprecatedPatientTreatmentsResult.data,
      deprecatedPatientTreatmentsResult.loading,
      deprecatedPatientTreatmentsResult.error,
    ]);

    loading = deprecatedPatientTreatmentsResult.loading;
  }

  const [open, setOpen] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<
    PatientTreatmentsGridRow | undefined
  >();

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const value = useMemo(() => {
    return {
      patientTreatmentCount: patientTreatmentsData?.patientTreatmentCount,
      rows: patientTreatmentsData?.rows as PatientTreatmentsGridRow[],
      open,
      toggleDrawer,
      activeRow: _.find(
        patientTreatmentsData?.rows as PatientTreatmentsGridRow[],
        {
          patientId: activeRow?.patientId,
        }
      ),
      setActiveRow,
      setPaginationModel,
      paginationModel,
      filterModel,
      setFilterModel,
      presetFilter,
      setPresetFilter,
      loading,
    };
  }, [
    patientTreatmentsData,
    open,
    activeRow,
    paginationModel,
    loading,
    filterModel,
    setFilterModel,
    presetFilter,
  ]);

  return (
    <PatientTreatmentsContext.Provider value={value}>
      {children}
    </PatientTreatmentsContext.Provider>
  );
};
