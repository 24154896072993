import React, { FunctionComponent, useState } from "react";
import { UnderlinedButton } from "@@components/SubmitButtons";
import { Flex } from "@@ui-kit";
import styled from "styled-components";
import { Account, Authorization, AuthorizationLog } from "@samacare/graphql";
import FormList from "../SelectFormModal/FormList";
import MissingFormModal from "./MissingFormModal";
import { useAuthorizationLogs } from "../../../hooks/useAuthorizationLogs";
import { getLatestSubmissionFormId } from "../../../util/authUtils";

interface FaxFormProps {
  onSubmit: ({ formId }: { formId: string }) => void;
  account: Account;
  authorization: Authorization;
  authorizationId: string;
  benefitType?: string;
  NDC?: string;
  jCode?: string;
  insuranceType?: string;
  state?: string;
}

const Step1Container = styled(Flex)`
  flex-direction: column;
  padding-top: 32px;
  max-width: 1280px;
  justify-content: center;
  min-height: 400px;
`;

const StyledUnderlinedButton = styled(UnderlinedButton)`
  width: auto;
  align-self: center;
`;

const FaxForm: FunctionComponent<FaxFormProps> = ({
  authorization,
  insuranceType,
  jCode,
  onSubmit,
  state,
  NDC,
  benefitType,
}) => {
  const [formId, setFormId] = useState<number | undefined | null>();
  const [showMemo, setShowMemo] = useState<boolean>(false);

  const { authorizationLogs } = useAuthorizationLogs(
    parseInt(authorization.id)
  );

  const previouslySubmittedFormId =
    authorizationLogs &&
    getLatestSubmissionFormId(authorizationLogs as AuthorizationLog[]);

  return (
    <div>
      <div style={{ marginBottom: "24px" }}>
        <Step1Container
          style={{
            minHeight: "65vh",
            width: "100%",
            height: "100%",
          }}
        >
          <FormList
            insuranceType={insuranceType}
            authorizationId={authorization.id}
            insuranceName={authorization.insuranceCompany?.name}
            onSelect={(selectedFormId?: number) => {
              return setFormId(selectedFormId);
            }}
            isSimplifiedUx
            insuranceCompanyId={authorization.InsuranceCompanyId}
            selectedFormId={formId}
            jCode={jCode}
            state={state}
            missingAuthSelected={null}
            institutionId={authorization.InstitutionId}
            NDC={NDC}
            benefitType={benefitType}
            onSubmit={onSubmit}
            openReportMissingForm={() => setShowMemo(true)}
            previouslySubmittedFormId={previouslySubmittedFormId}
          />
        </Step1Container>
        <Flex justifyContent="center" padding="8px" marginTop="16px">
          <StyledUnderlinedButton
            onClick={() => {
              setShowMemo(true);
            }}
          >
            Report Missing Form
          </StyledUnderlinedButton>
        </Flex>
      </div>
      <MissingFormModal
        setFormId={(newFormId) => setFormId(newFormId)}
        setShowMemo={setShowMemo}
        showMemo={showMemo}
        authorization={authorization}
        state={state}
        insuranceCompanyId={authorization.InsuranceCompanyId as string}
      />
    </div>
  );
};

export default FaxForm;
