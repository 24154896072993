import React, { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { FormControlLabel, Radio, RadioGroup, FormControl, FormLabel } from "@samacare/design";

export type RadioButtonsOption = {
  label: string;
  value: string;
};

export type RadioButtonsProps = {
  name: string;
  label: string;
  options: RadioButtonsOption[];
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const RadioButtonsField: React.FC<RadioButtonsProps> = ({
  name,
  label,
  options,
  disabled = false,
}) => {
  const { watch, setValue } = useFormContext();
  const radioValue = watch(name);

  const handleChange = (_e: ChangeEvent<HTMLInputElement>, newValue: string) => {
    setValue(name, newValue, { shouldDirty: true });
  };

  return (
    <FormControl>
      <FormLabel focused={false}>{label}</FormLabel>
      <RadioGroup
        value={radioValue}
        onChange={handleChange}
      >
        {options.map((o: RadioButtonsOption) => (
          <FormControlLabel
            key={o.value}
            value={o.value}
            control={<Radio disabled={disabled} />}
            label={o.label}
            sx={{
              marginLeft: 0
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsField;
