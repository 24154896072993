import _ from "lodash";
import {
  Account,
  Authorization,
  AuthorizationType,
  PaOriginType,
} from "@samacare/graphql";
import { useConfig } from "../../hooks";
import {
  isTrackedPortalAuth,
  needCurrentUserAction,
} from "../../util/authUtils";
import { StatusCheckerErrorButton } from "../StatusCheckerErrorButton";
import { Button, Link } from "@samacare/design";
import VisibilityIcon from "@samacare/design/core/icons/Visibility";
import { interpolate } from "../../util/stringUtils";
import { UpdateAuthorizationStatusButton } from "@@components/UpdateAuthorizationStatusButton";
import { useTheme } from "@samacare/design/core/styles";
import {
  GenericActionBanner,
  CARD_PLACEMENT,
  CardPlacement,
} from "./GenericActionBanner";
import { StatusBanner } from "./StatusBanner";
export { CARD_PLACEMENT } from "./GenericActionBanner";

interface ActionBannersProps {
  authorization: Authorization;
  currentAccount: Account;
  cardPlacement?: CardPlacement;
}

export const ActionBanners: React.VFC<ActionBannersProps> = (props) => {
  const {
    authorization,
    currentAccount,
    cardPlacement = CARD_PLACEMENT.AUTH_LIST,
  } = props;

  const theme = useTheme();
  const config = useConfig();

  let portalUrl =
    authorization?.portal?.deepLink || authorization?.portal?.loginUrl;
  portalUrl = interpolate(portalUrl, authorization);

  return (
    <>
      {/**
       * If the auth API-tracked, do not display the action banner
       */}
      {isTrackedPortalAuth(authorization) &&
        config.CONSTANTS.PROGRESS_AUTHORIZATION_STATUSES.includes(
          authorization.status
        ) &&
        (authorization.portalAuthorizationId == null ||
          authorization.portalAuthorizationId === "") && (
          <GenericActionBanner
            backgroundColor={theme.palette.colors.P100}
            color={theme.palette.text.primary}
            icon={null}
            button={
              <UpdateAuthorizationStatusButton
                authorization={authorization}
                styleOverrides={{
                  width: "200px",
                  fontSize: "16px",
                  height: "35px",
                }}
              >
                Add ID
              </UpdateAuthorizationStatusButton>
            }
            subtitle="Please add authorization ID from the portal to enable tracking."
            dataCy="addAuthorizationIdBanner"
          />
        )}

      {!_.isNil(authorization.PortalId) &&
        (authorization?.paOrigin !== PaOriginType.Copilot ||
          authorization?.portal?.isLegacy) &&
        authorization.formDetails?.currentStep != null &&
        authorization.formDetails?.currentStep >= 3 &&
        authorization.status ===
          config.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION && (
          <GenericActionBanner
            backgroundColor={theme.palette.colors.P100}
            color={theme.palette.text.primary}
            icon={null}
            button={
              <UpdateAuthorizationStatusButton
                authorization={authorization}
                styleOverrides={{
                  width: "200px",
                  fontSize: "16px",
                  height: "35px",
                }}
              >
                Update
              </UpdateAuthorizationStatusButton>
            }
            subtitle="Please review the authorization in the portal and update the status accordingly."
            dataCy="didYouSubmitThisBanner"
            cardPlacement={cardPlacement}
          />
        )}

      {authorization?.type === AuthorizationType.Portal &&
        authorization?.portal &&
        !authorization?.portal?.isFallback && (
          <GenericActionBanner
            backgroundColor={theme.palette.colors.LB50}
            color={theme.palette.text.primary}
            icon={null}
            button={
              <Link
                href={portalUrl}
                target="_blank"
                sx={{ color: "unset", textDecorationColor: "unset" }}
              >
                <Button
                  variant="outlined"
                  sx={{ backgroundColor: "#ffffff", pl: 1, pr: 1 }}
                  onClick={() =>
                    window.open(portalUrl, "_blank", "noopener noreferrer")
                  }
                >
                  <VisibilityIcon sx={{ mr: 1 }} />
                  Check Status in Portal
                </Button>
              </Link>
            }
            subtitle={
              <>
                For the most recent status, visit{" "}
                <Link
                  href={portalUrl}
                  target="_blank"
                  sx={{ color: "unset", textDecorationColor: "unset" }}
                >
                  payer’s website
                </Link>
                .
              </>
            }
            dataCy="submittedViaPortalBanner"
            cardPlacement={cardPlacement}
          />
        )}

      {needCurrentUserAction(authorization, currentAccount) && (
        <GenericActionBanner
          backgroundColor={theme.palette.colors.P100}
          color={theme.palette.text.primary}
          icon={null}
          button={
            <StatusCheckerErrorButton
              key={`StatusCheckerErrorButton-id-${authorization.id}`}
              currentAccount={currentAccount}
              authorization={authorization}
              data-cy="resolveButton"
            >
              Resolve
            </StatusCheckerErrorButton>
          }
          subtitle="Unable to check status."
          dataCy="loginErrorsBanner"
          cardPlacement={cardPlacement}
        />
      )}

      <StatusBanner
        authorization={authorization}
        cardPlacement={cardPlacement}
      />
    </>
  );
};
