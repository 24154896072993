import { Stack, Box, Button, Typography } from "@samacare/design";
import DeleteOutlinedIcon from "@samacare/design/core/icons/DeleteOutlined";
import SearchIcon from "@samacare/design/core/icons/Search";
import { AutocompleteField } from "@samacare/form2";
import { createFilterOptions } from "@samacare/design/core/base";
import { FieldError, useFormContext, useFormState } from "react-hook-form";
import _ from "lodash";
import { EnhancedServiecesEnum, ServiceCode } from "@samacare/graphql";

import { QuantityAndType } from "./optionalFields";
import { renderServiceAutoSuggestOptions } from "./renderServiceAutoSuggestOptions";

// eslint-disable-next-line no-shadow
export enum ServiceOptionalFields {
  Quantity = "quantity",
}

interface ServiceInputProps {
  name: string;
  required?: boolean;
  disabled?: boolean;
  handleDelete?: () => void;
  optionalFields?: ServiceOptionalFields[];
  serviceCodes: ServiceCode[];
  isFirst: boolean;
  loading: boolean;
  highlightedEnhancedServices?: EnhancedServiecesEnum[];
  firstServiceCodeSelected?: boolean;
  displayNotes?: boolean;
}

export const ServiceInput: React.FC<ServiceInputProps> = ({
  required = true,
  disabled = false,
  handleDelete,
  optionalFields,
  name,
  serviceCodes,
  isFirst,
  loading,
  highlightedEnhancedServices,
  displayNotes,
}) => {
  const { control, watch } = useFormContext();

  const { errors } = useFormState({ control });
  const error = _.get(errors, name) as FieldError;

  const selected = watch(name);

  return (
    <Stack spacing={2} width="100%">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {
          isFirst
            ? <Typography color="#00000099">Primary service</Typography>
            : <>
              <Typography color="#00000099">Additional service</Typography>
              <Button
                onClick={handleDelete}
                startIcon={<DeleteOutlinedIcon />}
              >
                Remove
              </Button>
            </>
        }
      </Box>
      <Stack direction="row" spacing={1}>
        <AutocompleteField<ServiceCode, true, true, false>
          data-cy="fieldHcpcsCode"
          disablePortal
          loading={loading}
          required={required}
          disabled={disabled}
          label="HCPCS code"
          options={serviceCodes}
          name={name}
          value={selected?.code ? selected : null}
          filterSelectedOptions
          filterOptions={createFilterOptions({ limit: 50 })}
          getOptionLabel={(option: ServiceCode) =>
            `${option.code}: ${option.drugName}`
          }
          renderOption={renderServiceAutoSuggestOptions(highlightedEnhancedServices)}
          sx={{ flex: 4 }}
          isOptionEqualToValue={(option: ServiceCode, value: ServiceCode) =>
            option.code === value?.code
          }
          disableClearable
          TextFieldInputProps={{
            startAdornment: <SearchIcon />,
          }}
          helperText={
            isFirst && !error && displayNotes
              ? "This HCPCS code determines which form will be used for PA"
              : ""
          }
          rules={{
            validate: (value) => !!value?.code || "HCPCS code is required"
          }}
        />
      </Stack>
      {optionalFields?.includes(ServiceOptionalFields.Quantity) && (
        <QuantityAndType
          required={required}
          disabled={disabled}
          name={name}
        />
      )}
    </Stack>
  );
};
