import { colors } from "@samacare/design";
import { alpha } from "@samacare/design/core/styles";
import { EnhancedServicesCard, EnhancedServicesCardHeader, EnhancedServicesCardTitle } from "../EnhancedServices";

export const PaBvTriggerCard = ({
  children,
  drugName,
  displayHeader = true,
  styleOverrides,
}: {
  children?: React.ReactNode;
  drugName: string;
  displayHeader?: boolean;
  styleOverrides?: React.CSSProperties;
}) => (
  <EnhancedServicesCard
    sx={{
      maxWidth: "600px",
      my: "4px",
      ".MuiCardContent-root:last-child": {
        paddingBottom: "16px",
      },
      ...styleOverrides,
    }}>
    {displayHeader && (
      <EnhancedServicesCardHeader
        sx={{
          backgroundColor: alpha(colors.C500, 0.08),
        }}
        title={<EnhancedServicesCardTitle title="Drug Verification" />}
        subheader={`SamaCare investigated payer coverage, requirements and financials for ${drugName}`}
        subheaderTypographyProps={{ variant: "body2" }}
      />
    )}
    {children}
  </EnhancedServicesCard>
);
