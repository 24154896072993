import { ApolloClient, ApolloProvider } from "@apollo/client";
import { useAllServiceCodes } from "@samacare/hooks-data";
import {
  BaseServiceSelector,
  BaseServiceSelectorProps,
} from "./BaseServiceSelector";

type ServiceSelectorWithDataProps = Omit<
  BaseServiceSelectorProps,
  "drugOptions" | "hcpcsCodes" | "allServiceCodes" | "loading"
>;

const ServiceSelectorWithData: React.FC<ServiceSelectorWithDataProps> = (
  props,
) => {
  const [{ drugOptions, hcpcsCodes, allServiceCodes }, loading] =
    useAllServiceCodes();

  return (
    <BaseServiceSelector
      allServiceCodes={allServiceCodes}
      drugOptions={drugOptions}
      hcpcsCodes={hcpcsCodes}
      loading={loading}
      {...props}
    />
  );
};

export type ServiceSelectorProps = ServiceSelectorWithDataProps & {
  apolloClient: object; // Couldn't keep Typescript happy on the calling end when given the proper ApolloClient type
};

export const ServiceSelector: React.FC<ServiceSelectorProps> = (
  props,
) => {
  const { apolloClient, ...otherProps } = props;
  return (
    <ApolloProvider client={apolloClient as ApolloClient<object>}>
      <ServiceSelectorWithData {...otherProps} />
    </ApolloProvider>
  );
};
