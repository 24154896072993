import { Patient } from "@samacare/graphql";
import {
  GetPatientByIdQuery,
  GetPatientByIdQueryVariables,
} from "@@generated/graphql";
import { gql, useQuery } from "@apollo/client";

export const getPatientWithTreatmentsByIdQuery = gql`
  query getPatientWithTreatmentsById($id: Int!) {
    patientById(id: $id) {
      id
      dob
      firstName
      lastName
      address
      zip
      gender
      state
      city
      phone
      InsuranceCompanyId
      status
      primaryInsurance {
        id
        memberId
        planType
        insuranceState
        insuranceCompany {
          id
          name
        }
        isValid
        supportsCoverageCheck
        latestCoverageCheck {
          id
          isValid
          isActive
          checkedAt
        }
      }
      allTreatments {
        drugName
        drugCode
        DrugOptionId
        enrollments {
          id
          status
          submittedAt
          createdAt
          InsuranceCompany {
            name
          }
        }
        bvs {
          id
          createdAt
          status
          isDraft
          insuranceCompanyName
          updatedAt
        }
        authorizations {
          createdAt
          updatedAt
          formDetails
          id
          status
          submittedAt
          type
          latestCorrespondence {
            startDate
            endDate
          }
          authorizationLogs {
            createdAt
            type
            details
          }
          steps {
            key
            title
            number
            active
            section
          }
        }
        availableEnhancedServices
      }
    }
  }
`;
export const usePatientWithTreatments = (id: number | undefined) => {
  const { data, error, loading } = useQuery<
    GetPatientByIdQuery,
    GetPatientByIdQueryVariables
  >(getPatientWithTreatmentsByIdQuery, {
    variables: { id: Number(id) },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  return {
    patient: (data?.patientById ?? null) as Patient | null,
    loading,
    error,
  };
};
