import { useApolloClient, useQuery } from "@apollo/client";
import * as React from "react";
import { useState } from "react";
import { setLocale } from "yup";

import { SearchInsuranceCompanyFeaturesQuery } from "@@generated/graphql";
import { Form } from "@@ui-kit/forms";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Grid,
  LoadingButton,
  Stack,
  Typography,
} from "@samacare/design/core";
import {
  useForm,
  AddressBlock,
  InsuranceBlock,
  PatientInformationBlock,
  IcdField,
  Resolver,
} from "@samacare/form";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { useConfig } from "../../../hooks/config/index";
import { EnrollmentSection } from "../../Enrollment/EnrollmentSection";
import { buildBenefitVerificationRequestFormSchema } from "../formUtils";
import SearchInsuranceCompanyFeaturesQueryGql from "../graphql/SearchInsuranceCompanyFeatures.gql";
import { RequestBenefitVerificationFormDataType } from "../types";
import useInsuranceCompanyOptions from "@samacare/form/hooks/useInsuranceCompanyOptions";
import { useFocusFirstError } from "../../../hooks/useFocusFirstError";
import { BenefitsVerificationServiceAndPatientBlock } from "./BenefitsVerificationServiceAndPatientBlock";

type BenefitsVerificationRequestFormLiteProps = {
  canChangePatient?: boolean;
  editable?: boolean;
  submitErrors: string[];
  defaultValues: RequestBenefitVerificationFormDataType;
  onSubmit: (data: RequestBenefitVerificationFormDataType) => void;
  onBack: () => void;
  disableLiteOption?: boolean;
};

export const BenefitsVerificationRequestFormLite: React.VoidFunctionComponent<
  BenefitsVerificationRequestFormLiteProps
> = ({
  editable = true,
  defaultValues,
  onSubmit,
  onBack,
  submitErrors = [],
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const config = useConfig();
  const { insuranceCompanies, loading: insuranceCompaniesLoading } =
    useInsuranceCompanyOptions();
  const apolloClient = useApolloClient();

  // init bv request form
  const schema = buildBenefitVerificationRequestFormSchema(
    config,
    insuranceCompanies
  );
  const form = useForm<RequestBenefitVerificationFormDataType>({
    resolver: yupResolver(schema) as Resolver<
      RequestBenefitVerificationFormDataType,
      any
    >,
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  setLocale({
    mixed: {
      required: "This field is required",
    },
  });

  const {
    trigger,
    handleSubmit,
    watch,
    formState: { errors },
  } = form;

  useFocusFirstError(errors);

  const insuranceCompanyId = watch("primaryInsurance.InsuranceCompanyId");
  const insuranceState = watch("primaryInsurance.insuranceState");

  const { loading: featuresLoading, data: insuranceCompanyFeatures } =
    useQuery<SearchInsuranceCompanyFeaturesQuery>(
      SearchInsuranceCompanyFeaturesQueryGql,
      {
        fetchPolicy: "network-only",
        variables: {
          InsuranceCompanyId: insuranceCompanyId,
          state: insuranceState,
        },
        skip: insuranceCompanyId == null,
      }
    );

  const supportsCoverageCheck =
    insuranceCompanyFeatures?.insuranceCompanyFeatures.availityCoverageCheck;

  const showUnsupportedPayerMessage =
    !supportsCoverageCheck && !featuresLoading;

  return (
    <Form
      context={form}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <BenefitsVerificationServiceAndPatientBlock>
        <IcdField
          apolloClient={apolloClient}
          name="icds"
          disabled={!editable}
        />
      </BenefitsVerificationServiceAndPatientBlock>
      <EnrollmentSection title="Patient Information">
        <Stack spacing={2} direction="column">
          <AddressBlock disabled={!editable} />
          <PatientInformationBlock disabled={!editable} />
        </Stack>
      </EnrollmentSection>
      <EnrollmentSection title="Primary Insurance Information">
        <InsuranceBlock
          disabled={!editable}
          InsuranceTypeInputProps={{
            name: "primaryInsurance.planType",
          }}
          InsuranceMemberIdInputProps={{
            name: "primaryInsurance.memberId",
          }}
          InsuranceStateInputProps={{
            name: "primaryInsurance.insuranceState",
            required: !!showUnsupportedPayerMessage,
          }}
          InsuranceCompanyInputProps={{
            name: "primaryInsurance.InsuranceCompanyId",
          }}
          required
        />
        {showUnsupportedPayerMessage && (
          <Grid container justifyContent="flex-end" marginTop={1}>
            <Typography variant="body2" color="error">
              Benefits verification lite is currently not supported for this
              payer
            </Typography>
          </Grid>
        )}
      </EnrollmentSection>
      {submitErrors && submitErrors.length > 0 && (
        <Grid container justifyContent="flex-end" marginBottom={1} width={600}>
          <Typography variant="body2" color="error">
            {submitErrors.map((error, i) => (
              <Typography key={i} variant="body2" color="error">
                {error}
              </Typography>
            ))}
          </Typography>
        </Grid>
      )}
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between" }}
        marginTop={1}
      >
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <LoadingButton
          loading={featuresLoading || insuranceCompaniesLoading}
          variant="contained"
          disabled={!editable || !supportsCoverageCheck}
          data-cy="actionSubmitNewBenefitsVerification"
          onClick={async () => (await trigger()) && setShowConfirm(true)}
        >
          Submit
        </LoadingButton>
      </Stack>
      <ConfirmationDialog
        open={showConfirm}
        onClose={() => {
          setShowConfirm(false);
        }}
        onConfirm={async () => {
          setShowConfirm(false);
          await handleSubmit(onSubmit)();
        }}
      />
    </Form>
  );
};
