import { PureComponent } from "react";
import { withAlert } from "react-alert";
import { connect } from "react-redux";
import { compose } from "recompose";
import { push } from "connected-react-router";

import ROUTE_PATHS from "../routes/ROUTE_PATHS";
import {
  withUpdateAuthorizationStatus,
  withUpdateAuthorizationProgressWithoutPatient,
} from "../graphql/Authorization";

export class EditAndResubmitButton extends PureComponent {
  triggerEditAndResubmit = async () => {
    const {
      authorizationId,
      updateAuthorizationStatus,
      updateAuthorizationProgressWithoutPatient,
      alert,
      toAuthorization,
      triggerAppeal,
    } = this.props;

    try {
      const { data } = await updateAuthorizationStatus({
        variables: {
          id: authorizationId,
          status: CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT,
        },
      });

      if (triggerAppeal) {
        await updateAuthorizationProgressWithoutPatient({
          variables: {
            id: authorizationId,
            config: { USE_APPEAL: true },
          },
        });
      }

      toAuthorization(
        authorizationId,
        data.updateAuthorizationStatus.type,
        triggerAppeal
      );
    } catch (e) {
      alert.error("There was an error in edit and resubmit");
    }
  };

  render() {
    const { children } = this.props;

    return (
      <div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            this.triggerEditAndResubmit();
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  toAuthorization(id, authorizationType, triggerAppeal) {
    const { step } = CONFIG.CONSTANTS.AUTHORIZATION_FORM_STEPS.ALL.FORM_DETAILS;
    let route;
    if (
      authorizationType ===
      CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.value
    ) {
      route = `${ROUTE_PATHS.MUTLI_PARTY_AUTH.path}?step=${
        triggerAppeal ? step : 1
      }&id=${id}`;
    } else {
      route = `${ROUTE_PATHS.FORM_AUTHORIZATION.path}?step=${
        triggerAppeal ? step : 1
      }&id=${id}`;
    }
    dispatch(push(route));
  },
});

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default connect(
  () => ({}),
  mapDispatchToProps
)(
  compose(
    withUpdateAuthorizationStatus,
    withUpdateAuthorizationProgressWithoutPatient
  )(withAlert()(EditAndResubmitButton))
);
