import _ from "lodash";
import {
  Autocomplete,
  TextField,
  type AutocompleteProps,
  type TextFieldProps,
} from "@samacare/design/core";
import {
  useFormContext,
  useFormState,
  type FieldError,
  type UseControllerProps,
  RegisterOptions,
} from "react-hook-form";
import { InputProps } from "@samacare/design/core/Input";

export type AutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "renderInput"
> &
  Omit<TextFieldProps, "onChange"> &
  UseControllerProps & {
    endAdornment?: React.ReactNode;
  } & Omit<RegisterOptions, "onChange"> & {
  TextFieldInputProps?: InputProps,
  helperText?: string;
};

export const AutocompleteField = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultValue,
  multiple,
  options,
  label,
  placeholder,
  name,
  rules,
  endAdornment,
  required,
  setValueAs,
  onChange: onDefaultChange,
  TextFieldInputProps,
  helperText,
  ...materialProperties
}: AutocompleteFieldProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
>): JSX.Element => {
  const { control, register, setValue, trigger } = useFormContext();

  const { errors } = useFormState({ control });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ref, onChange, onBlur, ...registerProps } = register(name, {
    ...rules,
    required,
    setValueAs,
  });

  const error = _.get(errors, name) as FieldError;
  const errorHelperText = (() => {
    if (error?.message) return error.message;
    if (error?.type === "required") return `${label} required`;
    return "";
  })();

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    if (materialProperties.onBlur) {
      materialProperties.onBlur(event);
    }
    await trigger(name);
  };

  const renderEndAdornment = (defaultEndAdornment: React.ReactNode) => (
    <>
      {endAdornment}
      {defaultEndAdornment}
    </>
  );

  return (
    <Autocomplete
      openOnFocus
      blurOnSelect
      fullWidth
      id={`field-${name}`}
      onChange={async (e, change, reason, details) => {
        if (materialProperties.disabled) return; // fixing a MUI bug that causes this to still be editable while disabled (see: https://github.com/mui/material-ui/issues/36867)
        if (onDefaultChange) onDefaultChange(e, change, reason, details);
        setValue(name, change, { shouldDirty: true });
      }}
      renderInput={(params) => (
        <TextField
          {...{
            error: Boolean(error),
            helperText: errorHelperText || helperText,
            placeholder,
            label,
            name,
            rules,
            required,
            onBlur: handleBlur,
            ...params,
            InputProps: {
              "data-cy": _.camelCase(`field.${name}`),
              error: !!error,
              ...params.InputProps,
              endAdornment: renderEndAdornment(
                params?.InputProps?.endAdornment,
              ),
              ...TextFieldInputProps,
            } as InputProps,
            InputLabelProps: { shrink: true },
          }}
        />
      )}
      {...{
        multiple,
        options,
        ...registerProps,
        ...materialProperties,
      }}
    />
  );
};

export default AutocompleteField;
