export const getAuthorizationStatusDisplayName = (
    status: string,
    overrides: Record<string, string>
  ): string => {
    if (!status) {
      return '';
    }

    if (overrides && overrides[status]) {
      return overrides[status];
    }
  
    // eslint-disable-next-line unicorn/prefer-string-replace-all
    const phrase = status.toLocaleLowerCase().replace(/_/g, " ");
    return phrase.charAt(0).toUpperCase() + phrase.slice(1);
  };
  