import { ApolloClient, ApolloProvider } from "@apollo/client";
import { useFindIcd } from "@samacare/hooks-data";
import {
  BaseIcdField,
  BaseIcdBlockProps,
} from "./BaseIcdField";

type IcdFieldWithDataProps = Omit<
  BaseIcdBlockProps,
  "icdCodes" | "searchFor"
>;

const IcdFieldWithData: React.FC<
  IcdFieldWithDataProps
> = (props) => {
  const { data, loading, refetch } = useFindIcd({ searchTerm: "", options: props?.override?.options });

  return <BaseIcdField
    icdCodes={Array.isArray(data?.icd10) ? data.icd10 : []}
    loading={loading}
    searchFor={refetch}
    {...props}
  />;
};

export type IcdFieldProps = IcdFieldWithDataProps & {
  apolloClient: object; // Couldn't keep Typescript happy on the calling end when given the proper ApolloClient type
};

export const IcdField: React.FC<IcdFieldProps> = (
  props,
) => {
  const { apolloClient, ...otherProps } = props;

  return (
    <ApolloProvider client={apolloClient as ApolloClient<object>}>
      <IcdFieldWithData {...otherProps} />
    </ApolloProvider>
  );
};
