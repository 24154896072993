import _ from "lodash";
import React, { ChangeEvent, useEffect } from "react";
import { Button, ButtonGroup, TextField, colors } from "@samacare/design";
import AddIcon from "@samacare/design/core/icons/Add";
import RemoveIcon from "@samacare/design/core/icons/Remove";
import { useFormContext, useFormState, FieldError } from "react-hook-form";

export type CounterFieldProps = {
  name: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: number;
};

const buttonStyle = {
  color: colors.BG900,
  backgroundColor: colors.BG50,
  borderColor: colors.BG200,
  width: 25,
  "&:hover": {
    backgroundColor: colors.BG100,
    borderColor: colors.BG300,
  },
  "&.MuiButtonGroup-firstButton": {
    borderRightColor: colors.BG200,
  },
  "&.MuiButtonGroup-firstButton:hover": {
    borderRightColor: colors.BG200,
  },
  "&.MuiButtonGroup-lastButton": {
    marginLeft: 0,
  },
};

const inputStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 0,
      borderColor: colors.BG200,
    },
    "&:hover fieldset": {
      borderColor: colors.BG300,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.BG500,
    },
    "& input": {
      textAlign: "center",
      width: 30,
      color: colors.BG700,
    },
  },
};

const validateNumber = (value: number, min = 1) => Math.max(value, min);

export const CounterField: React.FC<CounterFieldProps> = ({
  name,
  required = false,
  disabled = false,
  defaultValue,
  ...props
}) => {
  const { register, setValue, watch, control } = useFormContext();

  const { errors } = useFormState({ control });

  const currentValue = Number(watch(name));

  useEffect(() => {
    if (!currentValue && defaultValue) {
      setValue(name, defaultValue);
    }
  }, [currentValue, defaultValue, name, setValue]);

  const error = _.get(errors, name) as FieldError;

  const { ref, ...inputProps } = register(name, {
    required,
    value: currentValue,
  })

  const applyValue = (value: number) => {
    setValue(name, validateNumber(value), { shouldDirty: true });
  }

  const handleIncrement = () => {
    applyValue(currentValue + 1);
  };

  const handleDecrement = () => {
    applyValue(currentValue - 1);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    applyValue(Number(e.target.value));
  };

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const handleLocalChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(e);
    }

    handleChange(e);
  };

  return (
    <ButtonGroup sx={{ height: "40px" }}>
      <Button onClick={handleDecrement} disabled={currentValue === 1} sx={buttonStyle}>
        <RemoveIcon fontSize="small" />
      </Button>
      <TextField
        {...inputProps}
        inputRef={ref}
        size="small"
        value={currentValue}
        sx={inputStyle}
        error={!!error}
        inputProps={{
          "data-cy": _.camelCase(`field.${name}`),
        }}
        disabled={disabled}
        {...props}
        onChange={handleLocalChange}
        onBlur={handleBlur}
      />
      <Button onClick={handleIncrement} sx={buttonStyle}>
        <AddIcon fontSize="small" />
      </Button>
    </ButtonGroup>
  );
};
