import React, { useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";

import colors from "Resources/colors";
import { Box, Button, Typography } from "@samacare/design/core";
import ContentCopyIcon from "@samacare/design/core/icons/ContentCopy";
import CheckCircleIcon from "@samacare/design/core/icons/CheckCircle";
import { useAuth } from "../../providers/AuthProvider";
import { AuthTitle } from "./AuthTitle";
import { TotpSetupDetails } from "../../providers/AuthProvider/AuthProvider";

export type AuthTotpSetupParams = {
  handleNextStep: () => void;
};

export const AuthTotpSetup = ({ handleNextStep }: AuthTotpSetupParams) => {
  const { loadTotpSecret } = useAuth();
  const [totpSetupDetails, setTotpSetupDetails] =
    useState<TotpSetupDetails | null>(null);
  const [copied, setCopied] = React.useState(false);

  useEffect(() => {
    void loadTotpSecret().then(setTotpSetupDetails);
  }, []);

  return (
    <Box
      sx={{
        justifySelf: "center",
        display: "flex",
        flexDirection: "column",
        gap: "25px",
      }}
    >
      <AuthTitle>Multi-factor authentication</AuthTitle>
      <Typography variant="body2" sx={{ marginTop: "-25px" }}>
        Scan the QR code below using your preferred authenticator app
      </Typography>
      <QRCodeSVG
        style={{ alignSelf: "center" }}
        value={totpSetupDetails?.qrCode?.toString() || ""}
      />

      <Typography sx={{ textAlign: "center" }}>
        Then click below to enter code
      </Typography>

      <Box
        sx={{
          backgroundColor: colors.lightestPurple,
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: "12px",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          Scan not working? Copy this code key and enter it manually in your
          authentication app.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
            color: colors.darkestPurple,
          }}
        >
          <Typography sx={{ fontSize: "13px" }}>
            {totpSetupDetails?.secretCode}
          </Typography>
          {copied ? (
            <CheckCircleIcon sx={{ fontSize: "20px" }} />
          ) : (
            <ContentCopyIcon
              sx={{ cursor: "pointer", fontSize: "20px" }}
              onClick={async (e) => {
                e.stopPropagation();
                await navigator.clipboard.writeText(
                  totpSetupDetails?.secretCode || ""
                );
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
              }}
            />
          )}
        </Box>
      </Box>
      <Button onClick={handleNextStep} variant="contained">
        Enter Code
      </Button>
    </Box>
  );
};
