import { Authorization } from "@samacare/graphql";
import { AlertManager } from "react-alert";
import { WebExtensionPubSub } from "../services/WebExtensionPubSub";

export const sendMsgToBgScript = async (
  msg:
    | {
        type: "CognitoMsg";
        data?: { type: string; data?: string | Record<string, any> };
      }
    | {
        type: "CognitoConfig";
        data?: { userPoolId?: string | null; clientId?: string | null };
      }
    | { type: "SCAuthSuccessful" }
    | { type: "CheckConnection" }
    | {
        type: "PortalAuthorizationData";
        data: { url: string; authId: string };
      }
) => {
  const { WEB_EXTENSION_IDS } = window.CONFIG.CONSTANTS;

  const webExtensionId = (() => {
    if (window.location.href.includes("provider.samacare.com")) {
      return WEB_EXTENSION_IDS.production;
    } else if (window.location.href.includes("app.samacare.com")) {
      return WEB_EXTENSION_IDS.staging;
    } else if (window.location.href.includes("app.demo.samacare.com")) {
      return WEB_EXTENSION_IDS.demo;
    } else if (
      window.location.href.includes("app.development.samacare.com") ||
      window.location.href.includes("sandbox.samacare.com")
    ) {
      return WEB_EXTENSION_IDS.sandbox;
    } else if (window.location.href.includes("qa.samacare.com")) {
      return WEB_EXTENSION_IDS.qa;
    }
    return WEB_EXTENSION_IDS.dev;
  })();

  // TODO:
  // - when publishing to the Edge or Firefox store, update the below condition to include the respective browser
  const MAX_RETRIES = 3;
  let retries = 0;
  while (retries < MAX_RETRIES) {
    retries++;
    try {
      // TODO:
      // - when publishing to the Edge or Firefox store, update the below condition to include the respective browser
      if ((window as any).chrome && (window as any).chrome.runtime) {
        const response = await (window as any).chrome.runtime.sendMessage(
          webExtensionId,
          msg
        );
        return response;
      }
    } catch (err) {
      await new Promise((resolve) => setTimeout(resolve, 50));
      if (retries === MAX_RETRIES) throw err;
    }
  }
};

// Custom function to substitute Promise.any,
// bc we are compiling our code to ES2015 and Promise.any is not supported
export async function customPromiseAny<T>(promises: Promise<T>[]): Promise<T> {
  return new Promise((resolve, reject) => {
    let rejectedCount = 0;
    const errors: Error[] = [];

    promises.forEach((promise, index) => {
      promise.then(
        (value) => {
          resolve(value);
        },
        (error: Error) => {
          errors[index] = error;
          rejectedCount++;
          if (rejectedCount === promises.length) {
            reject(new Error("All promises were rejected"));
          }
        }
      );
    });
  });
}

export async function launchPortalWithExternalMsg({
  backupUrl,
  authorization,
}: {
  backupUrl?: string;
  authorization: Authorization;
}) {
  const MAX_RETRIES = 2;

  for (let i = 0; i < MAX_RETRIES; i++) {
    try {
      const response = await customPromiseAny([
        new Promise((resolve) => setTimeout(() => resolve("NotConnected"), 50)),
        sendMsgToBgScript({ type: "CheckConnection" }),
      ]);

      if (response === "NotConnected") continue;

      if (response === "Connected") {
        await sendMsgToBgScript({
          type: "PortalAuthorizationData",
          data: {
            url: authorization.portal?.loginUrl ?? backupUrl ?? "about:blank",
            authId: authorization.id,
          },
        });
        return true;
      }

      return false;
    } catch (error) {
      if (i === MAX_RETRIES)
        throw error instanceof Error
          ? error
          : new Error("Failed to launch portal with external message");
    }
  }
  return false;
}

export const launchPortalAuth = async ({
  authorization,
  alert,
  backupUrl,
}: {
  authorization: Authorization;
  alert: AlertManager;
  backupUrl?: string;
}) => {
  let portalLaunched = false;
  let externalMsgErr = null;
  // we'll have 2 ways to launch portals and if 1 fails we try another
  try {
    // 1st try with external msg
    portalLaunched = await launchPortalWithExternalMsg({
      backupUrl,
      authorization,
    });
  } catch (err) {
    externalMsgErr =
      err instanceof Error ? err : new Error("Unknown error launching portal");
  }

  if (portalLaunched) return;

  const wePubSub = WebExtensionPubSub.getInstance();
  wePubSub.addListener((msg) => {
    switch (msg.data?.type) {
      case "PortalLaunchSuccess": {
        portalLaunched = true;
        break;
      }
      default:
        break;
    }
  });

  // 2nd try using window.postMessage to our content script
  wePubSub.send({
    type: "LaunchPortal",
    data: {
      authId: authorization.id,
      url: authorization.portal?.loginUrl ?? backupUrl ?? "about:blank",
    },
  });
  // we're waiting for the portal to launch and PortalLaunchSuccess to be sent
  await new Promise((resolve) => setTimeout(resolve, 2000));
  if (!portalLaunched)
    alert.error(
      `Portal did not launch. Please try again after reload.
    If the problem persists, please contact support.`
    );

  if (externalMsgErr) throw externalMsgErr;
};
