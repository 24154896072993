import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  GridCloseIcon,
  IconButton,
  Divider,
} from "@samacare/design/core";
import { useTheme } from "styled-components";

export const DialogModal: React.VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  actions: React.ReactNode;
  isBackgroundYellow?: boolean;
  showCloseIcon?: boolean;
  divider?: boolean;
  preventDefault?: boolean;
}> = ({
  open,
  onClose,
  title,
  content,
  actions,
  isBackgroundYellow,
  showCloseIcon = true,
  divider,
  preventDefault = false,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          borderTop: `4px solid ${
            isBackgroundYellow ? theme.lightYellow : theme.purple
          }`,
          borderRadius: "8px",
          width: "600px",
        },
        overflow: "auto",
      }}
      onClick={(e) => {
        if (preventDefault) {
          e.stopPropagation();
        }
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "left",
          p: 3,
          fontSize: "20px !important",
          fontWeight: "bold",
          position: "relative",
        }}
      >
        {title}
      </DialogTitle>
      {divider && <Divider sx={{ backgroundColor: `${theme.black}21` }} />}
      {showCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "24px",
            top: "24px",
            color: theme.gray,
            p: 0.5,
          }}
        >
          <GridCloseIcon />
        </IconButton>
      )}
      <DialogContent
        sx={{
          p: 3,
          m: 3,
          overflow: "auto",
        }}
      >
        {content}
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: isBackgroundYellow
            ? theme.lightYellow
            : theme.modalActionBackgroundPurple,
          py: 1,
          px: 1,
          justifyContent: "center",
        }}
      >
        {actions}
      </DialogActions>
    </Dialog>
  );
};
