import _ from "lodash";

import {
  EnhancedServicesCard,
  EnhancedServicesCardHeader,
  EnhancedServicesAvatar,
  EnhancedServicesChip,
  EnhancedServicesCardTitle,
} from "@samacare/component";
import { Link, Stack, Typography } from "@samacare/design";
import TipsAndUpdates from "@samacare/design/core/icons/TipsAndUpdates";
import { useTheme } from "@samacare/design/core/styles";

import { Authorization } from "@samacare/graphql";

import { cleanDrugName } from "../../../../../server/src/shared_code";
import { useConfig, useSamaCareBrandName } from "../../../hooks";
import { format } from "date-fns";
import { normalizeNonApprovalResponseDueBy } from "../../../util/authUtils";

export const ActionRequiredGuidanceCard = ({
  authorization,
}: {
  authorization: Authorization;
}) => {
  const theme = useTheme();
  const brandName = useSamaCareBrandName();
  const config = useConfig();

  const hasNonApproval =
    (authorization.authorizationNonApprovals ?? []).length > 0;
  const latestNonApproval = hasNonApproval
    ? _.last(_.sortBy(authorization.authorizationNonApprovals, "updatedAt"))
    : undefined;
  const nonApprovalReason = latestNonApproval?.type
    ? config.CONSTANTS.NONAPPROVAL_TYPES[latestNonApproval.type]
    : "-";
  const responseDueBy = latestNonApproval?.responseDueBy
    ? format(
        normalizeNonApprovalResponseDueBy(latestNonApproval.responseDueBy),
        "MM/dd/yyyy"
      )
    : "-";

  const drugName =
    cleanDrugName(authorization.HCPCSCodes?.[0]?.drugName) ??
    authorization.HCPCSCodes?.[0]?.drugName ??
    "";

  return (
    <EnhancedServicesCard>
      <EnhancedServicesCardHeader
        title={
          <EnhancedServicesCardTitle
            title={`Action Required Guidance for ${drugName}`}
          />
        }
        avatar={
          <EnhancedServicesAvatar>
            <TipsAndUpdates />
          </EnhancedServicesAvatar>
        }
        subheader={
          hasNonApproval
            ? `Reviewed by ${brandName}`
            : `Review by ${brandName} pending`
        }
        subheaderTypographyProps={{ variant: "body2" }}
      />

      <Stack direction="column" sx={{ padding: "16px" }} spacing={1.5}>
        <Stack direction="row" spacing={1}>
          <EnhancedServicesChip
            label={
              <>
                <Typography
                  variant="caption"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Respond by{" "}
                </Typography>
                <Typography variant="caption">{responseDueBy}</Typography>
              </>
            }
            highlighted
          />
          <EnhancedServicesChip
            label={
              <>
                <Typography
                  variant="caption"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  Reason{" "}
                </Typography>
                <Typography variant="caption">{nonApprovalReason}</Typography>
              </>
            }
          />
        </Stack>

        {latestNonApproval && latestNonApproval.details && (
          <Typography variant="body2">{latestNonApproval.details}</Typography>
        )}

        {authorization.drugConfiguration?.payerPolicyLink && (
          <Typography
            variant="caption"
            sx={{ color: theme.palette.text.secondary }}
          >
            You can view{" "}
            <Link
              href={authorization.drugConfiguration?.payerPolicyLink}
              target="_blank"
            >
              {`${authorization?.config?.INSURANCE_COMPANY}'s payer policy`}
            </Link>{" "}
            for more information
          </Typography>
        )}
      </Stack>
    </EnhancedServicesCard>
  );
};
