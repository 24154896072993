import gql from "graphql-tag";

import authorizationInfo from "./authorizationInfo";
import authorizationCorrespondenceInfo from "./authorizationCorrespondenceInfo";

export default gql`
  fragment detailedAuthorizationInfo on Authorization {
    ...authorizationInfo
    submittedFormURL
    submissionPhoneNumber
    type
    authorizationLogs {
      createdAt
      type
      details
    }
    drugOption {
      id
      drugName
    }

    enrollment {
      id
      status
    }

    newEnrollment {
      id
      status
    }

    integratedEMRFilesDownloaded {
      category
      title
      url
      key
      date
    }

    integratedEMRProcedures {
      name
      code
      icd
      quantity
    }

    attachments {
      id
      title
      awsKey
    }

    patient {
      id
      firstName
      lastName
      InsuranceCompanyId
      institutionPatientId
      dob
      primaryInsurance {
        id
        supportsCoverageCheck
        insuranceState
        planType
        memberId
        insuranceCompany {
          id
          name
        }
        latestCoverageCheck {
          id
          isValid
          isActive
          checkedAt
        }
      }
    }
  }

  ${authorizationInfo}
  ${authorizationCorrespondenceInfo}
`;
