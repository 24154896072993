import { VFC, useEffect } from "react";
import { FormProvider, PaLocationBlock, useForm } from "@samacare/form";
import { Authorization, Location } from "@samacare/graphql";

import { useSet } from "../../../../hooks/useSet";
import { SegmentTitle } from "../../../Segment/SegmentTitle";
import { Box, Typography } from "@samacare/design";
import { HeaderTitle } from "../../../../constants/HeaderTitle";
import { useSetRequired } from "../../../../hooks/useSetRequired";
import { useHighlightRequiredFields } from "../../../../hooks/useHighlightRequiredFields";
import { useUnsetRequired } from "../../../../hooks/useUnsetRequired";
import { useTheme } from "@samacare/design/core/styles";
import { LeftRightCenterV } from "@samacare/component";
import { useAlert } from "react-alert";
import { useSetLocationId } from "../../../../hooks/useSetLocationId";

const { DEFAULT_FIELDS } = window.CONFIG;

const LOCATION_ID = "LocationId";
const LOCATION_STR = "location";

export enum LocationType {
  Servicing = "Servicing",
  Referring = "Referring",
}

export interface LocationDetailsProps {
  authorization: Authorization;
  disabled: boolean;
  locationType: LocationType;
  validate?: () => boolean;
  results: Record<string, string>;
}

const LocationDetails: VFC<LocationDetailsProps> = ({
  locationType = LocationType.Servicing,
  disabled,
  authorization,
  validate,
  results,
}) => {
  const theme = useTheme();
  const alert = useAlert();
  const set = useSet();
  const setLocationId = useSetLocationId();
  const setRequired = useSetRequired();
  const unsetRequired = useUnsetRequired();
  const highlightIfMissing = useHighlightRequiredFields();

  const methods = useForm({
    shouldUnregister: false,
  });
  const { watch, setValue, setError, clearErrors, formState } = methods;

  const LocationId = watch(LOCATION_ID);
  const location = watch(LOCATION_STR);

  const isServicingLocation = locationType === LocationType.Servicing;
  const tinRequired = isServicingLocation;

  const sectionTitle = isServicingLocation
    ? HeaderTitle.ServicingLocation
    : HeaderTitle.ReferringLocation;

  useEffect(() => {
    if (isServicingLocation) {
      setRequired(DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key);
    }
    if (tinRequired) {
      setRequired(DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key);
    }
    return () => {
      unsetRequired(DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key);
      unsetRequired(DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key);
    };
  }, [setRequired, unsetRequired]);

  useEffect(() => {
    if (!isServicingLocation) return;
    if (highlightIfMissing && !LocationId) {
      setError(DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key, {
        type: "required",
        message: "Servicing Location is required",
      });
    } else if (!highlightIfMissing) {
      clearErrors(DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key);
    }
  }, [highlightIfMissing, LocationId, isServicingLocation]);

  useEffect(() => {
    if (!isServicingLocation) return;
    if (highlightIfMissing && tinRequired && !location?.taxId) {
      setError("location.taxId", {
        type: "required",
        message: "TIN must be added before proceeding.  Click Update above",
      });
    } else if (!highlightIfMissing) {
      clearErrors("location.taxId");
    }
  }, [highlightIfMissing, tinRequired, isServicingLocation, location?.taxId]);

  useEffect(() => {
    if (!authorization.config) return;
    if (isServicingLocation) {
      if (
        authorization.config.FACILITY_NAME ||
        authorization.config.PRESCRIBER_OFFICE_NPI
      ) {
        const loc = {
          id: authorization.LocationId,
          facilityName: authorization.config.FACILITY_NAME,
          nickname: authorization.config.LOCATION_NAME,
          NPI: authorization.config.PRESCRIBER_OFFICE_NPI,
          taxId: authorization.config.PRESCRIBER_OFFICE_TIN,
          fax: authorization.config.PRESCRIBER_OFFICE_FAX,
          address: authorization.config.PRESCRIBER_ADDRESS,
          city: authorization.config.PRESCRIBER_CITY,
          state: authorization.config.PRESCRIBER_STATE,
          zip: authorization.config.PRESCRIBER_ZIP,
        };
        setValue(LOCATION_ID, authorization.LocationId, {
          shouldDirty: false,
        });
        setValue(LOCATION_STR, loc, { shouldDirty: false });
        set({
          [DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key]:
            authorization.config.PRESCRIBER_OFFICE_NPI,
          [DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key]:
            authorization.config.PRESCRIBER_OFFICE_TIN,
        });
      } else {
        setValue(LOCATION_ID, null, { shouldDirty: false });
        setValue(LOCATION_STR, null, { shouldDirty: false });
        set({
          [DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key]: null,
          [DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key]: null,
        });
      }
    }
  }, [authorization.LocationId]);

  useEffect(() => {
    if (!authorization.config) return;
    if (!isServicingLocation) {
      if (
        authorization.config.REFERRING_PROVIDER_FACILITY_NAME ||
        authorization.config.REFERRING_PROVIDER_OFFICE_NPI
      ) {
        const pres = {
          id: authorization.ReferringLocationId,
          facilityName: authorization.config.REFERRING_PROVIDER_FACILITY_NAME,
          nickname: authorization.config.REFERRING_PROVIDER_LOCATION_NICKNAME,
          NPI: authorization.config.REFERRING_PROVIDER_OFFICE_NPI,
          taxId: authorization.config.REFERRING_PROVIDER_OFFICE_TIN,
          fax: authorization.config.REFERRING_PROVIDER_OFFICE_FAX,
          address: authorization.config.REFERRING_PROVIDER_ADDRESS,
          city: authorization.config.REFERRING_PROVIDER_CITY,
          state: authorization.config.REFERRING_PROVIDER_STATE,
          zip: authorization.config.REFERRING_PROVIDER_ZIP,
        };
        setValue(LOCATION_ID, authorization.ReferringLocationId, {
          shouldDirty: false,
        });
        setValue(LOCATION_STR, pres, { shouldDirty: false });
        set({
          [DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_NPI.key]:
            authorization.config.REFERRING_PROVIDER_OFFICE_NPI,
        });
      } else {
        setValue(LOCATION_ID, null, { shouldDirty: false });
        setValue(LOCATION_STR, null, { shouldDirty: false });
        set({ [DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_NPI.key]: null });
      }
    }
  }, [authorization.ReferringLocationId]);

  const handleAfterSelection = async (loc: Location | undefined) => {
    // The selector will update the prescriber set for the form, which we want to avoid for
    // setting other types of locations such as referring location
    if (loc) {
      switch (locationType) {
        case LocationType.Referring:
          set({
            [DEFAULT_FIELDS.REFERRING_PROVIDER_FACILITY_NAME.key]:
              loc?.facilityName,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_LOCATION_NICKNAME.key]:
              loc?.nickname,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_NPI.key]: loc?.NPI,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_TIN.key]: loc?.taxId,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_FAX.key]: loc?.fax,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_ADDRESS.key]: loc?.address,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_CITY.key]: loc?.city,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_STATE.key]: loc?.state,
            [DEFAULT_FIELDS.REFERRING_PROVIDER_ZIP.key]: loc?.zip,
          });
          break;
        case LocationType.Servicing:
        default:
          set({
            [DEFAULT_FIELDS.FACILITY_NAME.key]: loc?.facilityName,
            [DEFAULT_FIELDS.LOCATION_NAME.key]: loc?.nickname,
            [DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key]: loc?.NPI,
            [DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key]: loc?.taxId,
            [DEFAULT_FIELDS.PRESCRIBER_OFFICE_FAX.key]: loc?.fax,
            [DEFAULT_FIELDS.PRESCRIBER_ADDRESS.key]: loc?.address,
            [DEFAULT_FIELDS.PRESCRIBER_CITY.key]: loc?.city,
            [DEFAULT_FIELDS.PRESCRIBER_STATE.key]: loc?.state,
            [DEFAULT_FIELDS.PRESCRIBER_ZIP.key]: loc?.zip,
          });
          setLocationId(loc.id);
      }
    }
  };

  const taxId = results && results[DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key];
  useEffect(() => {
    if (taxId && validate && tinRequired) {
      validate();
    }
  }, [taxId, validate, tinRequired]);

  useEffect(() => {
    if (!formState.dirtyFields.location) return;
    if (location) {
      void handleAfterSelection(location);
    } else if (!location) {
      if (isServicingLocation) {
        set({
          [DEFAULT_FIELDS.FACILITY_NAME.key]: "",
          [DEFAULT_FIELDS.LOCATION_NAME.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_OFFICE_FAX.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_ADDRESS.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_CITY.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_STATE.key]: "",
          [DEFAULT_FIELDS.PRESCRIBER_ZIP.key]: "",
        });
      } else {
        set({
          [DEFAULT_FIELDS.REFERRING_PROVIDER_FACILITY_NAME.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_LOCATION_NICKNAME.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_TIN.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_NPI.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_OFFICE_FAX.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_ADDRESS.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_CITY.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_STATE.key]: "",
          [DEFAULT_FIELDS.REFERRING_PROVIDER_ZIP.key]: "",
        });
      }
    }
  }, [location, formState.dirtyFields.location]);

  return (
    <Box>
      <SegmentTitle>
        <LeftRightCenterV>
          {sectionTitle}
          {isServicingLocation && (
            <Typography color={theme.palette.error.main}>*</Typography>
          )}
        </LeftRightCenterV>
      </SegmentTitle>
      <FormProvider {...methods}>
        <form>
          <PaLocationBlock
            sx={{ mt: 2 }}
            disabled={disabled}
            name="PRESCRIBER_OFFICE_NPI"
            onAlert={(message) => alert.error(message)}
            tinRequired={tinRequired}
          />
        </form>
      </FormProvider>
    </Box>
  );
};

export default LocationDetails;
