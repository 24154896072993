const NON_APPROVALS = CONFIG.CONSTANTS.USER_NONAPPROVAL_TYPES;
const AUTH_STATUSES = CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;
const ACTION_REQUIRED_NONAPPROVALS =
  CONFIG.CONSTANTS.ACTION_REQUIRED_NONAPPROVAL_TYPES;
const DENIED_NONAPPROVALS = CONFIG.CONSTANTS.DENIED_NONAPPROVAL_TYPES;

export const formatNonApprovalTypeLabel = (type) => {
  if (type === NON_APPROVALS.OUT_OF_NETWORK) {
    return "Provider is out of network";
  }
  return type;
};

export const getNonApprovalTypesOptions = (status) => {
  let nonApprovalOptions = [];
  if (status === AUTH_STATUSES.ACTION_REQUIRED) {
    nonApprovalOptions = ACTION_REQUIRED_NONAPPROVALS;
  } else if (status === AUTH_STATUSES.DENIED) {
    nonApprovalOptions = DENIED_NONAPPROVALS;
  }

  return Object.values(nonApprovalOptions).map((t) => ({
    label: formatNonApprovalTypeLabel(t),
    value: t,
  }));
};
