import * as React from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { AccountInfoFragment } from "@@generated/graphql";
import { useConfig } from "../hooks";
import { Switch } from "@samacare/design/core";

const internalOnlyToggleUserTypeForTesting = gql`
  mutation internalOnlyToggleUserTypeForTesting {
    internalOnlyToggleUserTypeForTesting {
      success
    }
  }
`;

export const InternalOnlyToggleUserTypeForTesting: React.VoidFunctionComponent<{
  account: AccountInfoFragment;
}> = ({ account }) => {
  const [toggleUserType] = useMutation(internalOnlyToggleUserTypeForTesting);
  const config = useConfig();

  if (account == null) return null;
  const { email, isSamaUser } = account;

  if (
    config.NODE_ENV !== "production" &&
    (email ?? "").toLowerCase().includes("@samacare.com")
  ) {
    return (
      <Switch
        name="isSamaUser"
        checked={isSamaUser}
        onClick={async () => {
          await toggleUserType();
          location.reload();
        }}
      />
    );
  }

  return null;
};

export default InternalOnlyToggleUserTypeForTesting;
