import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import BaseButton from "@@components/BaseButton";
import { Authorization, Patient } from "@samacare/graphql";
import { ActionRequiredRowContainer } from "./ActionRequiredRowContainer";
import { parseAsNonNull } from "../../../util/parsers";
import ViewOnPortalLink from "../../../components/ViewOnPortalLink";
import { authorizationStatusDisplayName } from "../../../util/authUtils";

const AutoAssociatedData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  white-space: normal;
  padding: 15px;
  margin-left: 5px;

  b {
    margin: 0 5px;
  }
`;

export type AutomatedRowProps = React.ComponentProps<typeof AutomatedRow>;

export const AutomatedRow: React.VoidFunctionComponent<{
  authorization: Authorization;
  onClick: (authorization: Authorization) => void;
}> = ({ authorization, onClick }) => (
  <ActionRequiredRowContainer>
    <AutoAssociatedData>
      {"An automated "}
      <b>{authorizationStatusDisplayName(authorization.status)}</b>
      {"response has been associated with this authorization for "}
      <b>
        {`${_.upperFirst(
          parseAsNonNull(authorization.patient as Patient).firstName
        )} ${_.upperFirst(
          parseAsNonNull(authorization.patient as Patient).lastName
        )}`}
      </b>
      {" and requires your review."}
      <ViewOnPortalLink authorization={authorization} />
      <BaseButton
        style={{ marginLeft: "auto" }}
        onClick={async () => onClick(authorization)}
      >
        Review Response
      </BaseButton>
    </AutoAssociatedData>
  </ActionRequiredRowContainer>
);
