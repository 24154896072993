import { Authorization, AuthorizationType } from "@samacare/graphql";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
} from "@samacare/design";
import React, { useState } from "react";
import { useConfig } from "@@hooks/config";
import { MdArrowDropDown } from "@react-icons/all-files/md/MdArrowDropDown";
import { UpdateAuthorizationButton } from "AuthorizationSharedComponents/UpdateAuthorizationButton";
import { ContactPayerButton } from "./ContactPayerButton";
import { isTrackedPortalAuth } from "../../util/authUtils";
import RequestResendButton from "@@components/RequestResendButton";

export interface PAFollowUpButtonProps {
  authorization: Authorization;
}

export const PAFollowUpButton = ({ authorization }: PAFollowUpButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const config = useConfig();

  // Display Follow Up button only if all conditions are met:
  // 1) Authorization has a non-terminal status
  // 2) There was a submission attempt (submittedAt != null)
  if (
    config.CONSTANTS.TERMINAL_AUTHORIZATION_STATUSES.includes(
      authorization.status
    ) ||
    !authorization.submittedAt
  ) {
    return null;
  }

  const displayUpdatePaOption =
    !isTrackedPortalAuth(authorization) &&
    authorization.type !== AuthorizationType.External;

  const sharedButtonSxProp = {
    // The button's clickable area width is determined by the longest text.
    // Setting a min-width ensures the clickable area remains sufficiently wide.
    minWidth: "160px",
  };
  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        sx={{
          width: "fit-content",
          minWidth: "max-content",
          whiteSpace: "nowrap",
        }}
        data-cy="actionSelectFollowUp"
        data-tooltip-id="followUpButton"
        endIcon={<MdArrowDropDown />}
      >
        Follow Up
      </Button>
      <Menu
        elevation={8}
        sx={{
          "& .MuiMenu-paper": {
            py: 0,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <List dense sx={{ py: 0 }}>
          {displayUpdatePaOption && (
            <ListItem sx={{ py: "4px" }} disableGutters divider>
              <ListItemButton
                disableRipple
                sx={{
                  ":hover": {
                    boxShadow: "none",
                  },
                }}
              >
                <UpdateAuthorizationButton
                  authorization={authorization}
                  onClose={handleClose}
                >
                  <ListItemText
                    primary="Update PA"
                    secondary="Modify PA and resubmit it"
                    sx={sharedButtonSxProp}
                  />
                </UpdateAuthorizationButton>
              </ListItemButton>
            </ListItem>
          )}
          {authorization.type === AuthorizationType.Form && (
            <ListItem sx={{ py: "4px" }} disableGutters divider>
              <ListItemButton
                disableRipple
                sx={{
                  ":hover": {
                    boxShadow: "none",
                  },
                }}
              >
                <RequestResendButton
                  key={`authRequestResendButton-id-${authorization.id}`}
                  authorizationId={authorization.id}
                  insuranceCompanyId={authorization.insuranceCompany?.id}
                  authorizationFormId={authorization.formId}
                  onClose={handleClose}
                >
                  <ListItemText
                    primary="Resend"
                    secondary="Resend form"
                    sx={sharedButtonSxProp}
                  />
                </RequestResendButton>
              </ListItemButton>
            </ListItem>
          )}
          <ListItem sx={{ py: "4px" }} disableGutters>
            <ListItemButton
              disableRipple
              sx={{
                ":hover": {
                  boxShadow: "none",
                },
              }}
            >
              <ContactPayerButton onClose={handleClose}>
                <ListItemText
                  primary="Contact payer"
                  secondary="Contact payer directly"
                  sx={sharedButtonSxProp}
                />
              </ContactPayerButton>
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
    </>
  );
};
