import { DrugCodeType } from "@samacare/graphql";

export const BenefitTypeOptions = [
  { label: "Medical", value: DrugCodeType.Jcode },
  { label: "Pharmacy", value: DrugCodeType.Ndc },
];

export enum TreatmentPhases {
  "INITIATION_REQUEST" = "INITIATION_REQUEST",
  "CONTINUATION_REQUEST" = "CONTINUATION_REQUEST",
}

export const TreatmentPhaseOptions = [
  { label: "Initiation", value: TreatmentPhases.INITIATION_REQUEST },
  { label: "Continuation", value: TreatmentPhases.CONTINUATION_REQUEST }
];

export enum UrgencyTypes {
  "URGENT_REQUEST"= "URGENT_REQUEST",
  "NOT_URGENT_REQUEST" = "NOT_URGENT_REQUEST",
  "RETRO_REQUEST" = "RETRO_REQUEST",
}

export const UrgencyOptionsDescription: Record<string, string> = {
  [UrgencyTypes.URGENT_REQUEST]: "Expedited Request",
  [UrgencyTypes.NOT_URGENT_REQUEST]: "Non-Expedited Request",
  [UrgencyTypes.RETRO_REQUEST]: "Retro Request",
};
