import _ from "lodash";
import { AuthorizationStatusEnum } from "@samacare/graphql";

export const formatFollowUpTypeLabel = (type: string) => {
  return _.upperFirst(type.replace(/_/g, " ").toLocaleLowerCase());
};

const DENIED_FOLLOW_UPS = window.CONFIG.CONSTANTS.DENIED_FOLLOW_UP_TYPES;
const ACTION_REQUIRED_FOLLOW_UPS =
  window.CONFIG.CONSTANTS.ACTION_REQUIRED_FOLLOW_UP_TYPES;

export const getFollowUpTypesOptions = (status: AuthorizationStatusEnum) => {
  let followUpTypesForDisplay = [] as string[];
  if (status === AuthorizationStatusEnum.Denied) {
    followUpTypesForDisplay = DENIED_FOLLOW_UPS;
  } else if (status === AuthorizationStatusEnum.ActionRequired) {
    followUpTypesForDisplay = ACTION_REQUIRED_FOLLOW_UPS;
  }

  return Object.values(followUpTypesForDisplay).map((t) => ({
    label: formatFollowUpTypeLabel(t),
    value: t,
  }));
};
