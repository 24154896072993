import { setLightness, setSaturation } from "polished";
import colors from "@samacare/design/core/colors";

const danger = colors.R700;
const primary = "hsl(240, 73%, 70%)";

export default {
  danger,
  primary,
  primaryLight: setLightness(0.97, primary),
  primaryDark: setSaturation(0.84, primary),
  secondary: "#b0b0b0",
  success: "hsl(134, 61%, 25%)",
  warning: "hsl(40, 96%, 45%)",

  text: {
    primary: "hsl(0, 0%, 13%)",
    secondary: "hsl(0, 0%, 33%)",
  },

  darkGray: "#5d5d5d",
  lightestGray: "#f1f1f1",
  lightGray: "#b0b0b0", // Should match .secondary
  disabledGray: "#e8e8e8",
  gray: "#757575",
  tileBackground: "#fdfdfd",
  purple: primary,
  darkPurple: "#1918b0",
  green: "#2BBF96",
  pastelGreen: "#DDFFE4",
  lightGreen: "#3b8b92",
  red: danger,
  warningRed: "#ff4242",
  pastelRed: "#ff9995",
  orange: "orange",
  white: "white",
  blue: "#2F6FED",
  secondaryBlack: "#1D2433",
  presubmissionPink: "#ff9a9a",
  lightYellow: "#ffecb3",
  darkestPurple: "#484887",
  // Primarily used as a default color for auth statuses
  dullGray: "#949494",
  defaultFontColor: "#212121", // Should match text.primary
  lightestPurple: "#f8f5ff",
  lightPurple: "#e8e7fd",
  pastelYellow: "#FFEFD0",
  warningOrange: "#B35E09",
  black: "black",
  modalActionBackgroundPurple: "#f3f3fe",
};
