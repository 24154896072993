import * as React from "react";
import styled from "styled-components";
import { ReactTooltip } from "@@ui-kit/ReactTooltip";

const Container = styled.div`
  margin-top: 3px;
  font-size: 12px;
  color: ${(props) => props.theme.darkGray};
  max-width: 170px;
  white-space: normal;
`;

const PayerResponseRange: React.VoidFunctionComponent<{
  min: number | null;
  max: number | null;
}> = ({ min, max }) => {
  let text;
  let tooltipText =
    "Data based on the last 100 authorizations submitted to this payer";
  if (min == null || max == null) {
    text = "Not enough data to determine expected payer response time";
    tooltipText = "";
  } else if (min === 0 && max === 0) {
    text = "Payer typically responds in 24 hr";
  } else if (min === max) {
    text = `Payer typically responds in ${min.toString()} days`;
  } else {
    text = `Payer typically responds in ${min.toString()} - ${max.toString()} days`;
  }

  const toolTipId = `payerResponseRange_${
    min == null ? "null" : min.toString()
  }_${max == null ? "null" : max.toString()}`;
  return (
    <Container data-tooltip-id={toolTipId}>
      {text}
      {tooltipText && <ReactTooltip id={toolTipId}>{tooltipText}</ReactTooltip>}
    </Container>
  );
};

export default PayerResponseRange;
