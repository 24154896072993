import { Box, Typography } from "@samacare/design/core";
export const StepHeader = ({
  title,
  subTitle,
}: {
  title: string;
  subTitle: string;
}) => (
  <Box>
    <Typography variant="h5">{title}</Typography>
    <Typography variant="body1" color="text.secondary" mt={1}>
      {subTitle}
    </Typography>
  </Box>
);
