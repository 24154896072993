import _ from "lodash";
import styled from "styled-components";
import { PureComponent } from "react";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import { withFaxAssociatedAuthorizations } from "../../../../graphql/Authorization";
import { withDeleteFormNumber } from "../../../../graphql/FormNumber";
import ROUTE_PATHS from "../../../ROUTE_PATHS";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from "@samacare/design/core";

const Link = styled.a`
  display: block;
`;

class DeleteFax extends PureComponent {
  deleteNumber = async () => {
    const { id, deleteFormNumber, alert, clear } = this.props;
    try {
      await deleteFormNumber({
        variables: {
          id,
        },
      });
      clear();
      alert.info("Successfully deleted record");
    } catch (e) {
      alert.error(`Failed to update ${e}`);
    }
  };

  render() {
    const { authorizations, id, description, number, clear } = this.props;
    let statusMessage = `Are you sure want to delete \n "${description} - ${number}"`;
    if (!_.isEmpty(authorizations)) {
      statusMessage =
        "Draft / Updating PA Authorizations still exist using this number";
    }
    if (authorizations) {
      return (
        <Dialog
          onClose={() => {
            clear();
          }}
          open
        >
          <DialogTitle>Delete Fax Number</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {statusMessage}
              {authorizations.map((auth) => (
                <Link
                  key={`deleteFax-auth-${auth.id}`}
                  href={`${window.location.origin}/#${ROUTE_PATHS.FORM_AUTHORIZATION.path}?step=1&id=${auth.id}`}
                >
                  {`Authorization ID ${auth.id}`}
                </Link>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                this.deleteNumber(id);
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return <div />;
  }
}

/**
 * @deprecated Use a functional component instead (non HOC)
 */
export default compose(
  withFaxAssociatedAuthorizations,
  withDeleteFormNumber
)(withAlert()(DeleteFax));
