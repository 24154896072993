import { Account, Authorization } from "@samacare/graphql";
import { RequesterFields } from "./RequesterFields";
import { LeftRightCenterV } from "../LeftRight";
import { SegmentContainer } from "../Segment/SegmentContainer";
import { SegmentTitle } from "../Segment/SegmentTitle";
import { TopBottom } from "@samacare/component";

interface RequesterSectionProps {
  auth: Authorization;
  account: Account;
  disabled?: boolean;
  saveChanges: () => Promise<void>;
}
const RequesterSection: React.VFC<RequesterSectionProps> = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <SegmentContainer style={{ width: "100%" }}>
        <SegmentTitle>Requester Details</SegmentTitle>
        <LeftRightCenterV>
          <TopBottom sx={{ width: "600px" }}>
            <RequesterFields
              auth={props.auth}
              account={props.account}
              saveChanges={props.saveChanges}
            />
          </TopBottom>
        </LeftRightCenterV>
      </SegmentContainer>
    </div>
  );
};

export { RequesterSection };
