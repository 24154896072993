import React from "react";
import { useHistory, generatePath } from "react-router-dom";

import { Box, Button, GridFilterListIcon, Stack } from "@samacare/design/core";
import ViewIcon from "@samacare/design/core/icons/FilePresentOutlined";
import ModeEditIcon from "@samacare/design/core/icons/ModeEdit";
import { PermalinkButton } from "@samacare/form/PermalinkButton/PermalinkButton";
import {
  FullBenefitsCheckDataType,
  ModelType,
  TemplateType,
  UnifiedBenefitsCheckType,
  UnifiedBenefitsCheckStatus,
} from "@samacare/graphql";

import { DownloadAsPdf } from "../../../components/AuthorizationSharedComponents/DownloadAsPdf";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import { useUnifiedBenefitsCheckContext } from "../UnifiedBenefitsCheckProvider";
import { BenefitsVerificationAssigneeSelect } from "./AssigneeSelect";
import { CareMetxDownloadPdf } from "./CareMetxDownloadPdf";

type ActionBarProps = {
  isEditMode: boolean;
  onEditClick?: () => void;
};

export const ActionBar: React.FC<ActionBarProps> = (props) => {
  const { isEditMode, onEditClick } = props;

  const history = useHistory();
  const { unifiedBenefitsCheck, currentAccount } =
    useUnifiedBenefitsCheckContext();

  if (unifiedBenefitsCheck == null || currentAccount == null) {
    return <Box />;
  }

  const isSamaCareFullBv =
    unifiedBenefitsCheck.type === UnifiedBenefitsCheckType.Full &&
    unifiedBenefitsCheck.fullData?.type === FullBenefitsCheckDataType.Samacare;

  const isCareMetxFullBv =
    unifiedBenefitsCheck.type === UnifiedBenefitsCheckType.Full &&
    unifiedBenefitsCheck.fullData?.type === FullBenefitsCheckDataType.Caremetx;

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={1}>
        {isSamaCareFullBv && unifiedBenefitsCheck.authAssociated.length > 0 && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={isEditMode}
            startIcon={<ViewIcon />}
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `${window.location.origin}/#${generatePath(
                  `${ROUTE_PATHS.AUTHORIZATION_LIST_FILTERED.path}?firstName=${unifiedBenefitsCheck.patient?.firstName}&lastName=${unifiedBenefitsCheck.patient?.lastName}&jCode=${unifiedBenefitsCheck.drug?.code}`
                )}`,
                "_blank"
              );
            }}
          >
            View Auth
          </Button>
        )}
        {currentAccount?.isSamaUser && onEditClick != null && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={
              isEditMode ||
              unifiedBenefitsCheck.status === UnifiedBenefitsCheckStatus.Draft
            }
            startIcon={<ModeEditIcon />}
            onClick={() => onEditClick()}
          >
            Edit
          </Button>
        )}
        <Button
          color="primary"
          variant="outlined"
          startIcon={<GridFilterListIcon />}
          onClick={() => {
            history.push(
              `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}?filters=patientId%20equals%20${unifiedBenefitsCheck.patient.id}`
            );
          }}
        >
          {isSamaCareFullBv ? "See All BV" : "See all Verifications"}
        </Button>
        <PermalinkButton
          url={`#${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${unifiedBenefitsCheck.id}`}
          variant="outlined"
          text="Copy BV Link"
        />
        {isSamaCareFullBv && (
          <DownloadAsPdf
            template={TemplateType.BenefitsVerification}
            modelType={ModelType.BenefitsVerification}
            modelId={unifiedBenefitsCheck.id}
            variant="outlined"
          />
        )}
        {isCareMetxFullBv && <CareMetxDownloadPdf />}
        {isSamaCareFullBv && (
          <BenefitsVerificationAssigneeSelect
            benefitsVerificationId={unifiedBenefitsCheck.id}
            requestedById={unifiedBenefitsCheck.fullData?.requestedById}
          />
        )}
      </Stack>
    </Stack>
  );
};
