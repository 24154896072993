import { gql, useQuery } from "@apollo/client";

export const findIcdQuery = gql`
    query icd10Search($searchTerm: String!, $query: [String]) {
        icd10(searchTerm: $searchTerm, query: $query)
    }
`;

export type useFindIcdProps = { searchTerm: string; options?: string[] };

export const useFindIcd = ({ searchTerm, options }: useFindIcdProps) => {
  const { data, loading, refetch } = useQuery(findIcdQuery, {
    variables: {
      searchTerm,
      query: options,
    },
  });

  return {
    data,
    loading,
    refetch,
  };
};
