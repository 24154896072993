import _ from "lodash";
import { PureComponent } from "react";
import styled from "styled-components";
import colors from "Resources/colors";

import BaseButton from "../../../components/BaseButton";
import { UpdateAuthorizationStatusButton } from "@@components/UpdateAuthorizationStatusButton";

const UpdateStatusButton = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 16px;
`;

const SuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 225px;
  overflow-y: scroll;
`;

const SuggestionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  font-weight: bold;
`;

const Title = styled.div`
  color: ${(props) => props.theme.purple};
  font-weight: bold;
  margin-bottom: 5px;
`;

const SeparatorLine = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.purple};
  margin: 10px 0;
`;

class StatusRecommendation extends PureComponent {
  constructor(props) {
    super(props);
    const suggestions = props.suggestedStatus
      ? [{ status: props.suggestedStatus }]
      : [];

    this.state = {
      suggestions,
      recommendationAccepted:
        _.get(suggestions[0], "status") === props.authorization.status,
      showAll: false,
    };
  }

  render() {
    const { authorization, suggestedStatus } = this.props;
    const { suggestions, recommendationAccepted, showAll } = this.state;

    if (_.isEmpty(suggestions)) {
      return (
        <div>
          <UpdateAuthorizationStatusButton
            authorization={authorization}
            styleOverrides={{ width: "100%", margin: "10px 0" }}
          >
            <UpdateStatusButton>
              Current status
              <b>{` : ${authorization.status}`}</b>. Click to update (no
              recommendations)
            </UpdateStatusButton>
          </UpdateAuthorizationStatusButton>
        </div>
      );
    }

    if (recommendationAccepted) {
      return (
        <div>
          <Title>Status Suggestions</Title>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {`Auth status matches recommendation of: ${authorization.status}`}
            <BaseButton
              onClick={() => {
                this.setState({ recommendationAccepted: false });
              }}
              style={{ fontSize: 12, marginLeft: "auto" }}
            >
              See suggestions
            </BaseButton>
          </div>
          <SeparatorLine />
        </div>
      );
    }

    const suggestionsToDisplay = showAll ? suggestions : [suggestions[0]];
    return (
      <div>
        <Title>Status Suggestions</Title>
        {_.map(suggestionsToDisplay, (suggestion) => (
          <SuggestionContainer
            key={`AssociationSuggestionContainer_${suggestion.status}`}
          >
            <SuggestionHeader>
              <div style={{ marginRight: "auto" }}>
                {suggestedStatus
                  ? `Status ${_.startCase(
                      suggestion.status
                    )} Detected. Please confirm.`
                  : `Status Suggestions for: ${_.startCase(suggestion.status)}`}
              </div>
              <UpdateAuthorizationStatusButton
                authorization={authorization}
                defaultTo={suggestion.status}
                onUpdateSuccess={() => {
                  this.setState({ recommendationAccepted: true });
                }}
                styleOverrides={{
                  color: colors.green,
                  borderColor: colors.green,
                }}
              >
                Accept Recommendation
              </UpdateAuthorizationStatusButton>
            </SuggestionHeader>
          </SuggestionContainer>
        ))}
        {suggestions.length > 1 && (
          <BaseButton
            onClick={() => {
              this.setState({ showAll: !showAll });
            }}
            style={{ width: "100%", fontSize: 12, padding: 5 }}
          >
            {showAll ? "See Less" : "See More"}
          </BaseButton>
        )}
        <UpdateAuthorizationStatusButton
          authorization={authorization}
          styleOverrides={{ width: "100%", margin: "5px 0", padding: "5px" }}
          onUpdateSuccess={() => {
            this.setState({ recommendationAccepted: true });
          }}
        >
          Set Status manually
        </UpdateAuthorizationStatusButton>
        <SeparatorLine />
      </div>
    );
  }
}

export default StatusRecommendation;
