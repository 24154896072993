import { ApolloClient, ApolloProvider } from "@apollo/client";
import { useFindNdc } from "@samacare/hooks-data";
import { BaseNdcSelector, BaseNdcSelectorProps } from "./BaseNdcSelector";

type NdcSelectorWithDataProps = Omit<
  BaseNdcSelectorProps,
  "findNdc" | "ndcs" | "loading"
>;

export type NdcSelectorProps = NdcSelectorWithDataProps & {
  apolloClient: object; // Couldn't keep Typescript happy on the calling end when given the proper ApolloClient type
};

const NdcSelectorWithData: React.FC<NdcSelectorWithDataProps> = (
  props,
) => {
  const { findNdc, ndcs, loading } = useFindNdc();

  return (
    <BaseNdcSelector
      findNdc={findNdc}
      ndcs={ndcs}
      loading={loading}
      {...props}
    />
  );
};

export const NdcSelector: React.FC<NdcSelectorProps> = (
  props,
) => {
  const { apolloClient, ...otherProps } = props;
  return (
    <ApolloProvider client={apolloClient as ApolloClient<object>}>
      <NdcSelectorWithData {...otherProps}/>
    </ApolloProvider>
  );
};
