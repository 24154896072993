import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useHistory, useRouteMatch, generatePath } from "react-router-dom";

import { SearchUnifiedBenefitsChecksQuery } from "@@generated/graphql";
import {
  Box,
  Button,
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@samacare/design";

import {
  FullBenefitsCheckDataType,
  UnifiedBenefitsCheckStatus,
  UnifiedBenefitsCheckType,
} from "@samacare/graphql";
import { GridToolbar } from "../../../components/DataGrid/GridToolbar";
import GridWrapper from "../../../components/DataGrid/GridWrapper";
import NewPriorAuthButton from "../../../components/NewPriorAuthButton";
import { useAccount } from "../../../providers/AccountProvider";
import { getInsuranceType } from "../../../util/getInsuranceType";
import ROUTE_PATHS from "../../ROUTE_PATHS";
import {
  BenefitsVerificationAssigneeSelect,
  GridCellDrug,
  GridCellDate,
  StatusChip,
  StatusOption,
  TypeChip,
} from "../components";
import SearchUnifiedBenefitsChecks from "../graphql/SearchUnifiedBenefitsChecks.gql";
import { useGridFilterUrlSync } from "../gridHooks";
import { nestedValueGetter } from "../gridUtils";
import { humanizeMaybeBoolean } from "../humanizers";
import { SearchResultItem } from "../types";
import { segmentTypewriter } from "../../../../analytics/analytics";
import { ViewSource } from "../../../../analytics/generated/segment";
import { getSegmentEnumInsuranceTypeFromSamacareInsuranceType } from "../../../../analytics/util";

const gridColumns = [
  {
    headerName: "First name",
    field: "patient.firstName",
    valueGetter: nestedValueGetter,
  },
  {
    headerName: "Last name",
    field: "patient.lastName",
    valueGetter: nestedValueGetter,
  },
  {
    headerName: "DOB",
    field: "patient.dob",
    valueGetter: nestedValueGetter,
  },
  {
    headerName: "Status",
    field: "status",
    renderCell: (params: GridRenderCellParams<SearchResultItem>) => (
      <StatusChip status={_.lowerCase(params.row.status) as StatusOption} />
    ),
  },
  {
    headerName: "Type",
    field: "type",
    renderCell: (params: GridRenderCellParams<SearchResultItem>) => (
      <TypeChip type={params.row.type} />
    ),
  },
  {
    headerName: "Auth Required",
    field: "authRequired",
    width: 150,
    renderCell: (params: GridRenderCellParams<SearchResultItem>) => {
      const { id, patient, fullData } = params.row;
      const isAuthRequired = fullData?.outcome?.data?.isAuthRequired;
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Box mr={2}>{humanizeMaybeBoolean(isAuthRequired, "")}</Box>
          {isAuthRequired && (
            <NewPriorAuthButton
              patientId={patient.id}
              configOverrides={{
                benefitsVerificationId: id,
              }}
            >
              <Button variant="contained" size="small">
                Add
              </Button>
            </NewPriorAuthButton>
          )}
        </Box>
      );
    },
  },
  {
    headerName: "Auth",
    field: "authAssociated",
    renderCell: (params: GridRenderCellParams<SearchResultItem>) => {
      const { authAssociated, patient, drug } = params.row;
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {authAssociated.length > 0 && (
            <Button
              variant="outlined"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                window.open(
                  `${window.location.origin}/#${generatePath(
                    `${ROUTE_PATHS.AUTHORIZATION_LIST_FILTERED.path}?firstName=${patient?.firstName}&lastName=${patient?.lastName}&jCode=${drug?.code}`
                  )}`,
                  "_blank"
                );
              }}
            >
              View
            </Button>
          )}
        </Box>
      );
    },
  },
  {
    headerName: "Drug / Code",
    field: "drug",
    width: 160,
    renderCell: ({ value: drug }: { value: SearchResultItem["drug"] }) => {
      if (drug == null) {
        return null;
      }
      return <GridCellDrug drug={drug} />;
    },
  },
  {
    headerName: "Payer",
    field: "insuranceCompanyData.name",
    valueGetter: nestedValueGetter,
  },
  {
    headerName: "Plan Type",
    field: "insurancePolicyData.planType",
    valueGetter: nestedValueGetter,
    valueFormatter: ({ value }: { value: string }) => getInsuranceType(value),
  },
  {
    headerName: "Cov. Status",
    field: "fullData.outcome.data.insuranceCoverageStatus",
    valueGetter: nestedValueGetter,
    valueFormatter: ({ value }: { value: string }) => _.capitalize(value),
  },
  {
    headerName: "Member ID#",
    field: "insurancePolicyData.memberId",
    valueGetter: nestedValueGetter,
  },
  {
    headerName: "Created",
    field: "createdAt",
    width: 120,
    renderCell: GridCellDate,
  },
  {
    headerName: "Last updated",
    field: "updatedAt",
    width: 120,
    renderCell: GridCellDate,
  },
  {
    headerName: "Assignee",
    field: "fullData",
    width: 150,
    renderCell: ({ value: bv }: { value: SearchResultItem["fullData"] }) => {
      if (bv == null) {
        return null;
      }
      return (
        <BenefitsVerificationAssigneeSelect
          key={bv.id}
          benefitsVerificationId={bv.id}
          requestedById={bv.requestedById}
        />
      );
    },
  },
] as GridColDef<SearchResultItem>[];

export const UnifiedBenefitsCheckGrid: React.FC = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const account = useAccount();
  const { filterModel, setFilterModel } = useGridFilterUrlSync();
  const { loading, data } = useQuery<SearchUnifiedBenefitsChecksQuery>(
    SearchUnifiedBenefitsChecks,
    {
      fetchPolicy: "network-only",
      variables: { InstitutionId: account?.institution?.id },
    }
  );

  const onRowClick = (params: GridRowParams<SearchResultItem>) => {
    const { status, id, type, fullData, isBvCompleteForCaremetx } = params.row;

    if (type === UnifiedBenefitsCheckType.Full) {
      if (status === UnifiedBenefitsCheckStatus.Draft) {
        if (fullData?.type === FullBenefitsCheckDataType.Caremetx) {
          // we have some cases where the caremetx enrollment module errors out and the
          // caremetx bv info is not complete, we want to navigate to bv edit page in this case
          if (!isBvCompleteForCaremetx) {
            history.push(
              `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/edit/${id}`
            );
          } else {
            // we may run into this case if the caremetx enrollment module errors out or the user didn't submit the form, which leave the caremetx bv in draft state
            // we will redirect to the caremetx enrollment module page again so the user can resubmit
            history.push(
              `${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/caremetx-pavblu-request/${id}`
            );
          }
        } else {
          history.push(`${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/edit/${id}`);
        }
      } else {
        segmentTypewriter.viewFullBv({
          accountId: +account!.id,
          email: account!.email!,
          isAdmin: account!.isAdmin,
          institutionIsTop: account!.institution!.isTop,
          institutionName: account!.institution!.name,
          institutionSpecialty:
            account!.institution!.practicingSpecialty ?? undefined,
          viewSource: ViewSource.BvGrid,
          insuranceName: params.row.insuranceCompany?.name ?? "",
          insuranceState:
            params.row.patient.primaryInsurance?.insuranceState ?? "",
          insuranceType: getSegmentEnumInsuranceTypeFromSamacareInsuranceType(
            params.row.patient.primaryInsurance?.planType ?? undefined
          ),
        });
        history.push(`${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${id}`);
      }
    }

    if (type === UnifiedBenefitsCheckType.Lite) {
      segmentTypewriter.viewBvLite({
        accountId: +account!.id,
        email: account!.email!,
        isAdmin: account!.isAdmin,
        institutionIsTop: account!.institution!.isTop,
        institutionName: account!.institution!.name,
        institutionSpecialty:
          account!.institution!.practicingSpecialty ?? undefined,
        viewSource: ViewSource.BvGrid,
        insuranceName: params.row.insuranceCompany?.name ?? "",
        insuranceState:
          params.row.patient.primaryInsurance?.insuranceState ?? "",
        insuranceType: getSegmentEnumInsuranceTypeFromSamacareInsuranceType(
          params.row.patient.primaryInsurance?.planType ?? undefined
        ),
      });
      history.push(`${ROUTE_PATHS.BENEFITS_VERIFICATIONS.path}/view/${id}`);
    }
  };

  return (
    <GridWrapper headerText="Benefits Verifications">
      <DataGridPro
        loading={loading}
        columns={gridColumns}
        rows={data?.unifiedBenefitsChecks || []}
        onRowClick={onRowClick}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            onNewClick: () => history.push(`${path}/type`),
            newButtonDataCy: "actionNewBenefitsVerification",
            searchWord: filterModel?.quickFilterValues?.[0] ?? null,
          },
        }}
      />
    </GridWrapper>
  );
};
