import React, { useState } from "react";
import {
  Authorization,
  AuthorizationStatusEnum,
  AuthorizationType,
} from "@samacare/graphql";
import { DialogModal } from "@@components/DialogModal";
import { Button, useTheme } from "@samacare/design";
import {
  useUpdateAuthorizationStatus,
  useUpdateAuthorizationProgressWithoutPatient,
} from "../../graphql/Authorization";
import { useConfig } from "@@hooks/config";
import { useAlert } from "react-alert";
import getAuthorizationRoute from "../../util/getAuthorizationRoute";
import { useDispatch } from "../../configureStore";
import { push } from "connected-react-router";
import { isTrackedPortalAuth } from "../../util/authUtils";
import { segmentTypewriter } from "../../../analytics/analytics";
import { FollowUpFlow, PaType } from "../../../analytics/generated/segment";
import { useAccount } from "../../providers/AccountProvider";

export interface UpdateAuthorizationButtonProps {
  children: React.ReactNode;
  authorization: Authorization;
  triggerAppeal?: boolean;
  onClose: () => void;
}

export const UpdateAuthorizationButton = ({
  children,
  authorization,
  triggerAppeal,
  onClose,
}: UpdateAuthorizationButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const config = useConfig();
  const theme = useTheme();
  const alert = useAlert();
  const dispatch = useDispatch();
  const account = useAccount();

  const [updateAuthorizationStatus] = useUpdateAuthorizationStatus();
  const [updateAuthorizationProgressWithoutPatient] =
    useUpdateAuthorizationProgressWithoutPatient();

  if (
    isTrackedPortalAuth(authorization) ||
    authorization.type === AuthorizationType.External
  )
    return null;

  const toAuthorization = () => {
    const step = triggerAppeal
      ? config.CONSTANTS.AUTHORIZATION_FORM_STEPS.ALL.FORM_DETAILS.step
      : config.CONSTANTS.AUTHORIZATION_FORM_STEPS.ALL.INITIATE.step;
    const routePrefix = getAuthorizationRoute(
      authorization,
      authorization.portal?.isLegacy
    );

    const route = `${routePrefix}?step=${step}&id=${authorization.id}`;

    dispatch(push(route));
  };

  const triggerUpdateAndResubmit = async () => {
    try {
      await updateAuthorizationStatus({
        variables: {
          id: parseInt(authorization.id),
          status: AuthorizationStatusEnum.EditAndResubmit,
        },
      });

      if (triggerAppeal) {
        await updateAuthorizationProgressWithoutPatient({
          variables: {
            id: parseInt(authorization.id),
            config: { USE_APPEAL: true },
          },
        });
      }

      segmentTypewriter.paFollowUp({
        followUpFlow: FollowUpFlow.UpdatePa,
        internalAuthId: Number(authorization.id),
        paType: (authorization.type as PaType | undefined) ?? undefined,
        institutionId: Number(authorization.InstitutionId),
        institutionName: authorization?.institution?.name,
        institutionSpecialty:
          authorization?.institution?.practicingSpecialty ?? undefined,
        accountId: String(account?.id),
      });

      toAuthorization();
    } catch (e) {
      alert.error("There was an error in edit and resubmit");
    }
  };

  const toggleModal = () => {
    if (open) {
      onClose();
    }
    setOpen(!open);
  };

  const getContent = () => {
    return (
      <div>
        Edits to this prior authorization will overwrite the current draft. When
        submitted, it will permanently update the existing PA in SamaCare and
        send the revised information to the payer.
      </div>
    );
  };

  const getActions = () => {
    const bgColor = theme.palette.primary.main;
    return (
      <>
        <Button sx={{ textDecoration: "underline" }} onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          sx={{
            borderRadius: "20px",
            background: bgColor,
            color: "white",
            "&:hover": {
              background: bgColor,
              cursor: "pointer",
            },
          }}
          onClick={triggerUpdateAndResubmit}
          data-cy="actionConfirmUpdateAuthorization"
        >
          Continue
        </Button>
      </>
    );
  };

  return (
    <>
      <DialogModal
        open={open}
        onClose={toggleModal}
        title="Update and resubmit"
        content={getContent()}
        actions={getActions()}
        divider
        preventDefault
      />
      <div onClick={toggleModal}>{children}</div>
    </>
  );
};
