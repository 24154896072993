import { Stack } from "@samacare/design/core";
import { DatePickerField, IcdField, TextField } from "@samacare/form";
import { useApolloClient } from "@apollo/client";

export type DiagnosisInfoBlockProps = {
  isSubmitted?: boolean;
  isRequired?: boolean;
};

export const HorizonTepezzaDiagnosisInfoBlock: React.FC<
  DiagnosisInfoBlockProps
> = ({ isSubmitted = false, isRequired = false }) => {
  const apolloClient = useApolloClient();

  return (
    <Stack direction="column" spacing={2} maxWidth={600}>
      <IcdField
        apolloClient={apolloClient}
        name="questionnaire.secondaryDiagnoses"
        label="Additional disease manifestation codes"
        override={{
          limit: 1,
        }}
        disabled={isSubmitted}
      />
      <Stack direction="row" spacing={2} maxWidth={600}>
        <DatePickerField
          label="Date of initial Thyroid Eye Disease diagnosis"
          name="questionnaire.sama-prescriptionInfo-diagnosisDate"
          disabled={isSubmitted}
          textFieldProps={{ required: isRequired }}
        />
        <TextField
          type="number"
          label="Patient’s Clinical Activity Score [0-10 Range]:"
          name="questionnaire.sama-prescriptionInfo-activityScore"
          InputProps={{ inputProps: { min: 0, max: 10 } }}
          disabled={isSubmitted}
          required={isRequired}
          fullWidth
        />
      </Stack>
    </Stack>
  );
};
