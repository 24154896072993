import _ from "lodash";
import { forwardRef, Fragment, useCallback, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import styled from "styled-components";
import colors from "Resources/colors";

import PatientSearchList, { Container } from "./PatientSearchList";
import { LoadingSpinner } from "../..//LoadingSpinner";

const MIN_STRING_LENGTH = 1;
const FULL_DOB_STRING_LENGTH = 10; // format MM/DD/YYYY

const FloaterOuterContainer = styled.div`
  z-index: 1;
`;

const FloaterInnerContainer = styled.div`
  background: white;
  filter: drop-shadow(2px 2px 2px #ccc);
  margin-top: 5px;
  position: absolute;
`;

const Floater = forwardRef(({ children }, ref) => (
  <FloaterOuterContainer ref={ref}>
    <FloaterInnerContainer>{children}</FloaterInnerContainer>
  </FloaterOuterContainer>
));

const GET_PATIENTS = gql`
  query PatientSearchQuery(
    $firstName: String
    $lastName: String
    $dob: String
    $institutionPatientId: String
  ) {
    patientsByName(
      firstName: $firstName
      lastName: $lastName
      dob: $dob
      institutionPatientId: $institutionPatientId
    ) {
      id
      dob
      firstName
      lastName
      gender
      institutionPatientId
      mrn
      zip
      city
      state
      phone
      address
      primaryInsurance {
        memberId
        groupNumber
      }
    }
  }
`;

const PatientSearch = forwardRef(
  (
    {
      firstName = "",
      lastName = "",
      institutionPatientId = "",
      dob = "",
      // When set to true, render the search results in a container that floats
      // on top of other content.
      floating = false,
      onResult = () => {},
      setPatient,
    },
    ref
  ) => {
    const [searchPatients, { data, loading }] = useLazyQuery(GET_PATIENTS);
    const checkValueForRequiredLength = (value, minLength) => {
      if (value?.length && value?.length < minLength) {
        return false;
      }
      return true;
    };

    const hasValidDataForSearch = useCallback(() => {
      if (
        !checkValueForRequiredLength(firstName, MIN_STRING_LENGTH) ||
        !checkValueForRequiredLength(lastName, MIN_STRING_LENGTH) ||
        !checkValueForRequiredLength(institutionPatientId, MIN_STRING_LENGTH) ||
        !checkValueForRequiredLength(dob, FULL_DOB_STRING_LENGTH)
      ) {
        return false;
      }

      return Boolean(firstName || lastName || institutionPatientId || dob);
    }, [firstName, lastName, dob, institutionPatientId]);

    const throttledSearch = useCallback(
      _.debounce(searchPatients, 500, {
        leading: false,
        trailing: true,
      }),
      []
    );

    useEffect(() => {
      if (hasValidDataForSearch()) {
        throttledSearch({
          variables: { firstName, lastName, dob, institutionPatientId },
        });
      }
    }, [firstName, lastName, dob, institutionPatientId, hasValidDataForSearch]);

    const patients = data?.patientsByName ?? [];

    if (loading) {
      return (
        <Container display="flex">
          <LoadingSpinner colorTop={colors.darkPurple} color={colors.purple} />
          Searching for your patient...
        </Container>
      );
    }

    if (patients.length > 0) {
      onResult(data.patientsByName);
    }

    if (patients.length === 0) {
      return <div />;
    }

    const Wrapper = floating ? Floater : Fragment;

    return (
      <Wrapper ref={ref}>
        <PatientSearchList patients={patients} setPatient={setPatient} />
      </Wrapper>
    );
  }
);

export default PatientSearch;
