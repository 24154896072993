import { Box, Button, Stack, Typography } from "@samacare/design";
import { useEffect, useState } from "react";
import Add from "@samacare/design/core/icons/Add";
import { EnhancedServiecesEnum, ServiceCode } from "@samacare/graphql";
import { useFormContext } from "@samacare/form2";
import { useFieldArray } from "react-hook-form";
import {
  ServiceInput,
  ServiceOptionalFields,
} from "./ServiceInput";

// eslint-disable-next-line no-shadow
export enum ServiceSelectorDataSources {
  AllServiceCodes = "allServiceCodes",
  DrugOptionsOnly = "drugOptionsOnly",
  HcpcsCodesOnly = "hcpcsCodesOnly",
}

export enum NoteDisplayingTypes {
  Always = "Always",
  AfterReachingLimit = "AfterReachingLimit",
  Never = "Never",
}

export interface BaseServiceSelectorProps {
  allServiceCodes: ServiceCode[];
  drugOptions: ServiceCode[];
  hcpcsCodes: ServiceCode[];
  loading: boolean;
  required?: boolean;
  disabled?: boolean;
  limit?: number;
  dataSource?: ServiceSelectorDataSources;
  name: string;
  optionalFields?: ServiceOptionalFields[];
  showFirstServiceCode?: boolean;
  disableSelectedService?: boolean;
  highlightedEnhancedServices?: EnhancedServiecesEnum[];
  restrictAfterLimit?: boolean;
  noteDisplayingType?: NoteDisplayingTypes;
}

const DEFAULT_STATE = {
    code: '',
    drugName: '',
    quantity: '',
    quantityType: '',
    startDate: '',
    endDate: ''
  }

const canAddService = ({ restrictAfterLimit, amountServices, limit  }: {
  restrictAfterLimit?: boolean,
  amountServices: number,
  limit: number
}): boolean => !(restrictAfterLimit && amountServices >= limit);

const shouldDisplayNotes = ({ type, amountServices, limit }: {
  type?: NoteDisplayingTypes;
  amountServices: number,
  limit: number
}): boolean => {
  if (type === NoteDisplayingTypes.AfterReachingLimit && amountServices >= limit) return true;
  return type === NoteDisplayingTypes.Always;
};

export const BaseServiceSelector: React.FC<BaseServiceSelectorProps> = ({
  required = false,
  disabled = false,
  limit = 15,
  allServiceCodes,
  drugOptions,
  hcpcsCodes,
  loading,
  dataSource = ServiceSelectorDataSources.AllServiceCodes,
  name,
  optionalFields,
  showFirstServiceCode = true,
  disableSelectedService,
  highlightedEnhancedServices,
  restrictAfterLimit = true,
  noteDisplayingType = NoteDisplayingTypes.Never,
}) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({ control, name });
  const [firstServiceCodeSelected, setFirstServiceCodeSelected] =
    useState(false);

  useEffect(() => {
    if (showFirstServiceCode && fields.length > 0 && disableSelectedService) {
      setFirstServiceCodeSelected(true);
    }
    if (showFirstServiceCode && fields.length === 0) {
      append({});
    }
  }, [append, fields.length, showFirstServiceCode, disableSelectedService]);

  const serviceCodes = (): ServiceCode[] => {
    switch (dataSource) {
      case ServiceSelectorDataSources.DrugOptionsOnly: {
        return drugOptions;
      }
      case ServiceSelectorDataSources.HcpcsCodesOnly: {
        return hcpcsCodes;
      }
      default: {
        return allServiceCodes;
      }
    }
  };

  const displayNotes = shouldDisplayNotes({
    type: noteDisplayingType,
    limit,
    amountServices: fields.length
  });

  return (
    <Stack spacing={2} maxWidth={600}>
      {fields.map(({ id }, idx) => (
        <ServiceInput
          key={id}
          required={required}
          disabled={disabled || (idx === 0 && firstServiceCodeSelected)}
          optionalFields={optionalFields}
          serviceCodes={serviceCodes()}
          handleDelete={disabled ? undefined : () => remove(idx)}
          isFirst={idx === 0}
          loading={loading}
          name={`${name}.${idx}`}
          highlightedEnhancedServices={highlightedEnhancedServices}
          firstServiceCodeSelected={firstServiceCodeSelected}
          displayNotes={displayNotes}
        />
      ))}
      {
        !disabled && <Box sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          gap: 2
        }}>
          {
            canAddService({
              restrictAfterLimit,
              limit,
              amountServices: fields.length
            }) && <Button
              onClick={() => append(DEFAULT_STATE)}
              startIcon={<Add />}
              data-cy="actionAddServiceCode"
            >
              Add Service
            </Button>
          }
          {
            displayNotes && <Typography
              variant="caption"
              color="GrayText"
            >
              Note: The primary HCPCS code is included in the portal request. Additional codes are for reporting. You can also add them manually in the portal.
            </Typography>
          }
        </Box>
      }
    </Stack>
  );
};
