import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";

import {
  signIn,
  type SignInInput,
  type SignInOutput,
  signInWithRedirect,
  signOut,
  type SignOutInput,
  confirmSignIn,
  type ConfirmSignInInput,
  type ConfirmSignInOutput,
  resetPassword,
  type ResetPasswordInput,
  type ResetPasswordOutput,
  confirmResetPassword,
  type ConfirmResetPasswordInput,
  getCurrentUser,
  type GetCurrentUserOutput,
  fetchAuthSession,
  fetchUserAttributes,
  type FetchUserAttributesOutput,
  sendUserAttributeVerificationCode,
  type SendUserAttributeVerificationCodeInput,
  type SendUserAttributeVerificationCodeOutput,
  confirmUserAttribute,
  type ConfirmUserAttributeInput,
  autoSignIn,
  AuthError,
  setUpTOTP,
  verifyTOTPSetup,
  type VerifyTOTPSetupInput,
  updateMFAPreference,
  fetchMFAPreference,
} from "aws-amplify/auth";
import { datadogRum } from "@datadog/browser-rum";

import { sendMsgToBgScript } from "../../util/bgScriptMsgUtils";
import {
  AUTH_USERPOOL_STORAGE,
  AUTH_CLIENT_ID_STORAGE,
} from "app/layouts/Auth/Auth.constants";
import { configureAmplify } from "app/layouts/Auth/AmplifyConfiguration";

type AuthSignInState = Omit<SignInOutput, "nextStep">;
type AuthConfirmSignInState = Omit<ConfirmSignInOutput, "nextStep">;
type AuthResetPasswordState = Omit<ResetPasswordOutput, "nextStep">;

type AuthNextStepState =
  | SignInOutput["nextStep"]
  | ResetPasswordOutput["nextStep"];

type AuthState = Partial<
  AuthSignInState &
    AuthConfirmSignInState &
    AuthResetPasswordState &
    GetCurrentUserOutput &
    SendUserAttributeVerificationCodeOutput &
    AuthNextStepState &
    Awaited<ReturnType<typeof fetchAuthSession>>
> & {
  error?: string | Element;
  isChecked: boolean;
  isEmailVerified: boolean;
  isMfaSetup: boolean;
  attributes?: FetchUserAttributesOutput;
  signInStep?: SignInOutput["nextStep"];
  resetPasswordStep?: ResetPasswordOutput["nextStep"];
};

type AuthContextType = AuthState & {
  loading: boolean;
  onError: (message: string | Element | undefined) => void;
  onReset: () => void;
  onSignIn: (input?: SignInInput) => Promise<void>;
  onSignInWithRedirect: (name: string) => Promise<void>;
  onConfirmSignIn: (input: ConfirmSignInInput) => Promise<void>;
  onSignOut: (input?: SignOutInput) => Promise<void>;
  onResetPassword: (input: ResetPasswordInput) => Promise<void>;
  onConfirmResetPassword: (input: ConfirmResetPasswordInput) => Promise<void>;
  onConfirmUserAttribute: (input: ConfirmUserAttributeInput) => Promise<void>;
  onSendUserAttributeVerificationCode: (
    input: SendUserAttributeVerificationCodeInput
  ) => Promise<void>;
  loadTotpSecret: () => Promise<any>;
  onVerifyTOTPSetup: (input: VerifyTOTPSetupInput) => Promise<void>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const DEFAULT_AUTH_STATE: AuthState = {
  isChecked: false,
  isSignedIn: false,
  isPasswordReset: false,
  isEmailVerified: false,
  isMfaSetup: false,
  missingAttributes: [],
};

export type TotpSetupDetails = {
  qrCode: URL;
  secretCode: string;
};

export const useAuth = () =>
  useContext<AuthContextType>(AuthContext as React.Context<AuthContextType>);

export const withAuthContext =
  (ChildComponent: React.ComponentType<{ auth: AuthContextType }>): React.FC =>
  (props) => {
    const auth = useAuth();
    return <ChildComponent auth={auth} {...props} />;
  };

const clearCognitoLocalStorage = (): void => {
  const keys = Object.keys(localStorage);
  keys.forEach((key) => {
    // if the key is a cognito key remove it
    if (key.includes("CognitoIdentityServiceProvider")) {
      localStorage.removeItem(key);
    }
  });
};

export const isInvalidCodeError = (msg: string | Element): boolean => {
  if (typeof msg === "string") {
    return ["Code mismatch", "Invalid code received for user"].includes(msg);
  }
  return false;
};

export const AuthProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | Element | undefined>();
  const [state, setState] = useState<AuthState>(DEFAULT_AUTH_STATE);

  const onError = useCallback(function onReset(
    message: string | Element | undefined
  ) {
    setError(message);
  },
  []);

  const onReset = useCallback(function onReset() {
    setError(void 0);
    setLoading(false);
    setState(DEFAULT_AUTH_STATE);
  }, []);

  function onStateChange(nextState: Partial<AuthState>) {
    // @ts-ignore stupid types from AWS
    setState((prev) => ({
      ...prev,
      ...nextState,
    }));
  }

  const onAuthSuccessful = useCallback(async function onAuthSuccessfulCallback(
    data?: Partial<AuthState>
  ) {
    try {
      const [user, session, attributes, mfaPreferences] = await Promise.all([
        getCurrentUser(),
        fetchAuthSession(),
        fetchUserAttributes(),
        fetchMFAPreference(),
      ]);
      onStateChange({
        ...data,
        ...user,
        ...session,
        attributes,
        isEmailVerified: attributes?.email_verified === "true",
        isMfaSetup:
          attributes["custom:mfaRequired"] === "true" &&
          !mfaPreferences?.preferred?.length,
      });

      void sendMsgToBgScript({
        type: "SCAuthSuccessful",
      });
    } catch (err) {
      // PasswordResetRequiredException
      if ((err as AuthError).name === "PasswordResetRequiredException") {
        onStateChange({
          isChecked: true,
          resetPasswordStep: {
            resetPasswordStep: "CONFIRM_RESET_PASSWORD_WITH_CODE",
          } as ResetPasswordOutput["nextStep"],
        });
      } else {
        throw err;
      }
    }
  },
  []);

  const onSignInWithRedirect = useCallback(
    async function onSignInWithRedirectCallback(name: string) {
      try {
        setError(void 0);
        setLoading(true);

        //TODO: Should a non-custom provider be used (e.g. Google)
        //we will need to rework this to not use { custom name }, but instead
        //provider: name as the variable
        await signInWithRedirect({
          provider: {
            custom: name,
          },
        });
      } catch (err) {
        onStateChange(DEFAULT_AUTH_STATE);
        setError((err as Error).message ?? "An error occurred in sign in");
      } finally {
        setLoading(false);
      }
    },
    [setError, setLoading, onStateChange]
  );

  const onSignIn = useCallback(
    async function onSignInCallback(input?: SignInInput) {
      try {
        setError(void 0);
        setLoading(true);
        const { nextStep, ...data } = input
          ? await signIn({
              ...input,
              options: { authFlowType: "USER_PASSWORD_AUTH" },
            })
          : await autoSignIn();

        if (nextStep?.signInStep !== "DONE") {
          onStateChange({
            ...data,
            signInStep: nextStep,
          });
        } else {
          await onAuthSuccessful({
            ...data,
            signInStep: nextStep,
          });
        }
      } catch (err) {
        onStateChange(DEFAULT_AUTH_STATE);

        if ((err as AuthError)?.name === "UserNotFoundException") {
          setError("Incorrect username or password.");
        } else if ((err as AuthError)?.name !== "NotAuthorizedException") {
          switch ((err as Error).message) {
            case "PostAuthentication failed with error User is deactivated.":
              setError("User is deactivated.");
              break;
            case "PostAuthentication failed with error User has expired.":
              setError("User has expired.");
              break;
            default:
              datadogRum.addError(err, { username: input?.username });
              setError(
                (err as Error).message ?? "An error occurred in sign in."
              );
              break;
          }
        } else {
          setError((err as Error).message ?? "An error occurred in sign in.");
        }
      } finally {
        setLoading(false);
      }
    },
    [onAuthSuccessful]
  );

  const handleError = async (msg: string) => {
    setLoading(false);
    setError(msg);
    await new Promise((resolve) => {
      const timeoutId = setTimeout(resolve, 5000);
      return () => clearTimeout(timeoutId);
    });
    setError(undefined);
  };

  const value = useMemo(
    () => ({
      error,
      loading,
      ...state,
      onError,
      onReset,
      onSignIn,
      onSignInWithRedirect,
      onConfirmSignIn: async (input: ConfirmSignInInput) => {
        try {
          setError(void 0);
          setLoading(true);
          const { nextStep, ...data } = await confirmSignIn({
            ...input,
            options: { authFlowType: "USER_PASSWORD_AUTH" },
          });

          if (nextStep?.signInStep !== "DONE") {
            onStateChange({
              ...data,
              signInStep: nextStep,
            });
          } else {
            await onAuthSuccessful({
              ...data,
              signInStep: nextStep,
            });
          }
        } catch (err) {
          onStateChange(DEFAULT_AUTH_STATE);
          const errMsg = (err as Error).message;

          if (isInvalidCodeError(errMsg)) {
            await handleError(errMsg);
          } else {
            setError(errMsg ?? "An error occurred in confirm sign in");
          }
        } finally {
          setLoading(false);
        }
      },
      onSignOut: async (input?: SignOutInput) => {
        try {
          setError(void 0);
          setLoading(true);
          await signOut(input);
          clearCognitoLocalStorage();
          window.location.reload();
        } catch (err) {
          setError((err as Error).message ?? "An error occurred in sign out");
        }
      },
      onCheckUser: async () => {
        try {
          setLoading(true);
          configureAmplify();
          await onAuthSuccessful({
            isSignedIn: true,
            signInStep: {
              signInStep: "DONE",
            },
            isChecked: true,
          });
        } catch (err) {
          onStateChange({ isSignedIn: false, isChecked: true });
        } finally {
          setLoading(false);
        }
      },
      onResetPassword: async (input: ResetPasswordInput) => {
        try {
          setError(void 0);
          setLoading(true);
          const { nextStep, ...data } = await resetPassword(input);
          onStateChange({ ...data, resetPasswordStep: nextStep });
        } catch (err) {
          let errMsg: any =
            (err as Error).message ?? "An error occurred in reset password";
          if (
            errMsg === "User password cannot be reset in the current state."
          ) {
            errMsg = (
              <>
                Your account invitation has expired. Please reach out to{" "}
                <a href="mailto:help@samacare.com">help@samacare.com</a>
              </>
            );
          }
          setError(errMsg);
        } finally {
          setLoading(false);
        }
      },
      onConfirmResetPassword: async (input: ConfirmResetPasswordInput) => {
        try {
          setError(void 0);
          setLoading(true);
          await confirmResetPassword(input);
          onReset();
        } catch (err) {
          setState((prev) => ({ ...prev, isSignedIn: false, isChecked: true }));
          setError(
            (err as Error).message ??
              "An error occurred in confirm reset password"
          );
        } finally {
          setLoading(false);
        }
      },
      onConfirmUserAttribute: async (input: ConfirmUserAttributeInput) => {
        try {
          setError(void 0);
          setLoading(true);

          await confirmUserAttribute(input);
          await onSignIn();
        } catch (err) {
          setError(
            (err as Error).message ??
              "An error occurred in confirm reset password"
          );
        } finally {
          setLoading(false);
        }
      },
      onSendUserAttributeVerificationCode: async (
        input: SendUserAttributeVerificationCodeInput
      ) => {
        try {
          setError(void 0);
          setLoading(true);
          onStateChange(await sendUserAttributeVerificationCode(input));
        } catch (err) {
          setError(
            (err as Error).message ??
              "An error occurred in confirm reset password"
          );
        } finally {
          setLoading(false);
        }
      },
      loadTotpSecret: async (): Promise<TotpSetupDetails | undefined> => {
        try {
          setError(void 0);
          setLoading(true);
          const user = await getCurrentUser();
          const totpSetupDetails = await setUpTOTP();
          return {
            qrCode: totpSetupDetails.getSetupUri(
              "SamaCare",
              user?.signInDetails?.loginId
            ),
            secretCode: totpSetupDetails.sharedSecret,
          };
        } catch (err) {
          setError(
            (err as Error).message ??
              "An error occurred in confirm setup device"
          );
        } finally {
          setLoading(false);
        }
      },
      onVerifyTOTPSetup: async (input: VerifyTOTPSetupInput) => {
        try {
          setError(void 0);
          setLoading(true);
          await verifyTOTPSetup(input);
          await updateMFAPreference({ totp: "PREFERRED" });
          onStateChange({ isMfaSetup: false });
        } catch (err) {
          const errMsg = (err as Error).message;

          if (isInvalidCodeError(errMsg)) {
            await handleError(errMsg);
          } else {
            setError(errMsg ?? "An error occurred in confirm sign in");
          }
        } finally {
          setLoading(false);
        }
      },
    }),
    [
      state,
      error,
      loading,
      onError,
      onReset,
      onSignIn,
      onSignInWithRedirect,
      onAuthSuccessful,
    ]
  );

  useEffect(() => {
    if (window.CONFIG.COGNITO_ENABLED && !loading && !state.isChecked) {
      void value.onCheckUser();
    }
  }, [loading, state.isChecked, value]);

  useEffect(() => {
    // get all keys from local storage
    const keys = Object.keys(localStorage);
    // loop through each key
    keys.forEach((key) => {
      // if the key is a cognito key
      if (key.includes("CognitoIdentityServiceProvider")) {
        // remove the key
        void sendMsgToBgScript({
          type: "CognitoMsg",
          data: {
            type: "cognito:setItem",
            data: { key, value: localStorage[key] },
          },
        });
      }
    });
    void sendMsgToBgScript({
      type: "CognitoConfig",
      data: {
        clientId: localStorage.getItem(AUTH_CLIENT_ID_STORAGE),
        userPoolId: localStorage.getItem(AUTH_USERPOOL_STORAGE),
      },
    });
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
