import React, { useState } from "react";

import { useAuth } from "../../providers/AuthProvider";
import { AuthCodeVerification, OnSubmitParams } from "./AuthCodeVerification";
import { AuthTotpSetup } from "./AuthTotpSetup";

enum MfaStepSteps {
  Setup = "setup",
  Verification = "verification",
}

export const AuthMfaSetup: React.FC = () => {
  const { onVerifyTOTPSetup } = useAuth();
  const [step, setStep] = useState<MfaStepSteps>(MfaStepSteps.Setup);

  const handleNextStep = () => {
    setStep(MfaStepSteps.Verification);
  };

  if (step === MfaStepSteps.Setup) {
    return <AuthTotpSetup handleNextStep={handleNextStep} />;
  }

  return (
    <AuthCodeVerification
      onSubmit={async ({ code }: OnSubmitParams) => {
        await onVerifyTOTPSetup({ code });
      }}
    />
  );
};
