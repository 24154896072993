import React, { useState } from "react";
import ConfirmationModal from "../SelectFormModal/ConfirmationModal";
import FindingFormModal from "../SelectFormModal/FindingFormModal";
import ReportMissingFormModal from "../SelectFormModal/ReportMissingFormModal";
import { Authorization } from "@samacare/graphql";

interface MissingFormModalProps {
  showMemo: boolean;
  authorization: Authorization;
  insuranceCompanyId?: string | undefined;
  setShowMemo: (showMemo: boolean) => void;
  setFormId: (formId?: number | null) => void;
  state?: string | undefined;
}

const MissingFormModal = ({
  setShowMemo,
  setFormId,
  showMemo,
  authorization,
  insuranceCompanyId,
  state,
}: MissingFormModalProps) => {
  const [requestNewForm, setRequestNewForm] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  if (showConfirmation) {
    return (
      <ConfirmationModal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      />
    );
  }

  if (showMemo) {
    return (
      <FindingFormModal
        onClose={() => setShowMemo(false)}
        onFinish={() => {
          setShowMemo(false);
          setFormId(null);
          setRequestNewForm(true);
        }}
        open={showMemo}
      />
    );
  }
  if (requestNewForm) {
    return (
      <ReportMissingFormModal
        onClose={() => setRequestNewForm(false)}
        open={requestNewForm}
        authorization={authorization}
        insuranceCompanyId={insuranceCompanyId}
        state={state}
        onFinish={() => {
          setRequestNewForm(false);
          setShowConfirmation(true);
        }}
      />
    );
  }

  return null;
};

export default MissingFormModal;
