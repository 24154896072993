import _ from "lodash";

import {
  Card,
  CardHeader,
  colors,
  Chip,
  Stack,
  Typography,
  CardProps,
  CardHeaderProps
} from "@samacare/design";

import Avatar from "@samacare/design/core/Avatar";
import { alpha, useTheme } from "@samacare/design/core/styles";
import { EnahanceServiceIndicatorSingular, EnhancedServicesIcon } from "../EnhancedServicesIndicator";


export const EnhancedServicesCard = (props: CardProps) => {
  const styleOverrides = props.sx ?? {};
  const passableProps = _.omit(props, ["sx"]);

  return (
    <Card
      sx={{
        width: "100%",
        boxShadow: "none",
        border: `1px solid ${alpha(colors.C500, 0.3)}`,
        marginBottom: "16px",
        ...styleOverrides
      }}
      {...passableProps}
    >
      {props.children}
    </Card>
  )
}

export const EnhancedServicesCardHeader = (props: CardHeaderProps) => {
  const styleOverrides = props.sx ?? {};
  const passableProps = _.omit(props, ["sx"]);

  return (
    <CardHeader
      sx={{
        backgroundColor: alpha(colors.C500, 0.08),
        ...styleOverrides
      }}
    
      {...passableProps}
    />
)
}

export const EnhancedServicesCardTitle = ({ title }: { title: string }) => (          
  <Stack direction="row" alignItems="baseline">
    <Typography variant="body1" mr={1}>
      {title}
    </Typography>
    <Stack direction="row" alignItems="center">
      <EnahanceServiceIndicatorSingular
        styleOverride={{ textVariant: "caption" }}
      />
      <EnhancedServicesIcon sx={{ fontSize: 18 }} />
    </Stack>
  </Stack>
)
  
export const EnhancedServicesAvatar = ({ children }: { children: React.ReactNode }) => { 
  const theme = useTheme(); 
  return (
    <Avatar sx={{ bgcolor: theme.palette.secondary.dark }}>{children}</Avatar> 
  )
}

export const EnhancedServicesChip = ({ label, highlighted = false }: { label: string | React.ReactNode, highlighted?: boolean }) => { 
  const theme = useTheme(); 
  return (
    <Chip 
      label={label}           
      sx={{
        width: "fit-content",
        bgcolor: highlighted ? alpha(theme.palette.colors.C500, 0.3) :theme.palette.action.hover 
      }}
      size="small" 
    />
  )
}