import _ from "lodash";
import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import { ApolloCache } from "@apollo/client/cache";
import moment from "moment";

import { withDefaultProps } from "./utils";
import authorizationInfo from "./fragments/authorizationInfo";
import detailedAuthorizationInfo from "./fragments/detailedAuthorizationInfo";
import institutionInfo from "./fragments/institutionInfo";
import authorizationPage from "./fragments/authorizationPage";
import { useMutation } from "@apollo/client";
import {
  Mutation,
  MutationCreateAuthorizationArgs,
  MutationSetPrescriberOnAuthArgs,
  MutationUpdateAuthorizationByIdArgs,
  MutationUpdateAuthorizationStatusArgs,
} from "@samacare/graphql";
import { GET_AUTHORIZATION_LOGS_QUERY_NAME } from "@@hooks/useAuthorizationLogs";
import { UpdateAuthorizationProgressWithoutPatientMutationVariables } from "@@generated/graphql";
import { AUTHORIZATION_NOTES_QUERY_NAME } from "./Note";

export const GET_AUTHORIZATION_QUERY_NAME = "GetAuthorization";
export const ALL_AUTHORIZATIONS_QUERY_NAME = "allAuthorizations";

export const withAuthorizationQuery = gql`
  query GetAuthorization($id: Int!) {
    authorizationById(id: $id) {
      ...detailedAuthorizationInfo
      institution {
        ...institutionInfo
      }
    }
  }
  ${detailedAuthorizationInfo}
  ${institutionInfo}
`;

export const withFaxAssociatedAuthorizations = graphql(
  gql`
    query getAuthorizationsByFormNumber($id: Int!) {
      getAuthorizationsByFormNumber(id: $id) {
        ...authorizationInfo
      }
    }
    ${authorizationInfo}
  `,
  {
    options: { fetchPolicy: "network-only" },
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      authorizations: _.get(data, "getAuthorizationsByFormNumber"),
    })),
  }
);

export const withFormAssociatedAuthorizations = graphql(
  gql`
    query getAuthorizationsByForm($id: Int!) {
      getAuthorizationsByForm(id: $id) {
        ...authorizationInfo
      }
    }
    ${authorizationInfo}
  `,
  {
    options: { fetchPolicy: "network-only" },
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      authorizations: _.get(data, "getAuthorizationsByForm"),
    })),
  }
);

export const supportToolAuthorizationsSearchQuery = gql`
  query supportToolAuthorizationsSearch(
    $submittedDaysAgo: Int
    $InsuranceCompanyId: String
    $InstitutionId: String
    $patientFirstName: String
    $patientLastName: String
    $authorizationStatuses: [String]
    $limit: Int
    $AuthorizationId: Int
    $patientMemberId: String
    $PrescriberId: String
  ) {
    supportToolAuthorizationsSearch(
      submittedDaysAgo: $submittedDaysAgo
      InsuranceCompanyId: $InsuranceCompanyId
      InstitutionId: $InstitutionId
      patientFirstName: $patientFirstName
      patientLastName: $patientLastName
      authorizationStatuses: $authorizationStatuses
      limit: $limit
      AuthorizationId: $AuthorizationId
      patientMemberId: $patientMemberId
      PrescriberId: $PrescriberId
    ) {
      ...authorizationInfo
    }
  }
  ${authorizationInfo}
`;

export const withSupportToolAuthorizationSearch = graphql(
  supportToolAuthorizationsSearchQuery,
  {
    name: "supportToolAuthorizationsSearch",
    options: (props: {
      InstitutionId?: string;
      authorizationStatuses?: [string];
      patientFirstName?: string;
      patientLastName?: string;
      InsuranceCompanyId?: string;
      AuthorizationId?: number;
      patientMemberId?: string;
      submittedDaysAgo?: number;
    }) => ({
      variables: {
        InstitutionId: props.InstitutionId,
        authorizationStatuses: props.authorizationStatuses || [
          window.CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PENDING,
          window.CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENT,
        ],
        patientFirstName: props.patientFirstName,
        patientLastName: props.patientLastName,
        InsuranceCompanyId: props.InsuranceCompanyId,
        submittedDaysAgo: props.submittedDaysAgo,
        patientMemberId: props.patientMemberId,
        AuthorizationId: props.AuthorizationId,
      },
      fetchPolicy: "network-only",
    }),
  }
);

export const AUTHORIZATION_PAGINATION_QUERY_NAME = "authorizationsPaginated";
export const withAuthorizationsPaginatedQuery = gql`
  query authorizationsPaginated(
    $expirationDate: DateTime
    $filterByExpired: Boolean
    $filterByUpcomingDateOfServiceDays: String
    $firstName: String
    $insuranceCompanyId: ID
    $isArchived: AuthorizationsFiltersIsArchivedFilters
    $lastName: String
    $limit: Int
    $locationId: ID
    $patientId: ID
    $prescriberId: ID
    $accountId: ID
    $sortBy: AuthorizationConnectionSortByAttribute
    $statuses: [AuthorizationStatusEnum!]
  ) {
    authorizationsPaginated(
      filters: {
        expirationDate: $expirationDate
        filterByExpired: $filterByExpired
        filterByUpcomingDateOfServiceDays: $filterByUpcomingDateOfServiceDays
        firstName: $firstName
        insuranceCompanyId: $insuranceCompanyId
        isArchived: $isArchived
        lastName: $lastName
        locationId: $locationId
        patientId: $patientId
        prescriberId: $prescriberId
        accountId: $accountId
        statuses: $statuses
      }
      limit: $limit
      sortBy: $sortBy
    ) {
      ...authorizationPage
    }
  }
  ${authorizationPage}
`;

export const withAuthorizationsPaginated = graphql(
  withAuthorizationsPaginatedQuery,
  {
    // @ts-ignore
    options: (props) => {
      const base = { fetchPolicy: "network-only" };
      /* eslint-disable @typescript-eslint/no-unsafe-member-access, , @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */
      // @ts-ignore
      const list = props.authorizationList;

      if (list) {
        return {
          ...base,
          variables: {
            limit: list.limit,
            expirationDate:
              _.get(list.filters, "expirationDate") &&
              list.filters.expirationDate.offset !== 0
                ? moment()
                    .add(
                      list.filters.expirationDate.offset,
                      list.filters.expirationDate.unit
                    )
                    .toDate()
                : null,
            filterByExpired: list.selected.onlyExpired,
            isArchived: list.selected.isArchived,
            insuranceCompanyId: _.get(list.filters, "insuranceCompanyId"),
            locationId: _.get(list.filters, "locationId"),
            prescriberId: _.get(list.filters, "prescriberId"),
            accountId: _.get(list.filters, "accountId"),
            firstName: list.firstName,
            lastName: list.lastName,
            sortBy: list.sortBy,
            statuses: _.get(list.filters, "status")
              ? [list.filters.status]
              : list.selected.statuses,
            filterByUpcomingDateOfServiceDays: _.get(
              list.filters,
              "filterByUpcomingDateOfServiceDays"
            ),
          },
        };
      }

      return {
        ...base,
        variables: {
          limit: 10,
          // @ts-ignore
          patientId: props.otherAuthorizationsList.patientId,
        },
      };
      /* eslint-enable @typescript-eslint/no-unsafe-member-access, , @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument */
    },
    props: withDefaultProps(({ data }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      authorizationPage: _.get(data, "authorizationsPaginated"),
      authorizationsPaginatedRefetch: _.get(data, "refetch"),
    })),
  }
);

export const withCreateAuthorizationMutation = gql`
  mutation createAuthorization(
    $defaultFields: JSON
    $segmentDetails: SegmentDetailsInputCreatePa
  ) {
    createAuthorization(
      defaultFields: $defaultFields
      segmentDetails: $segmentDetails
    ) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;

export const useCreateAuthorization = () => {
  return useMutation<Mutation, MutationCreateAuthorizationArgs>(
    withCreateAuthorizationMutation,
    {
      refetchQueries: [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
      ],
    }
  );
};

export const withCreateAuthorizationFromBvMutation = gql`
  mutation createAuthorizationFromBv(
    $benefitsVerificationId: String!
    $patch: CreateAuthorizationFromBvPatch
  ) {
    createAuthorizationFromBv(
      benefitsVerificationId: $benefitsVerificationId
      patch: $patch
    ) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;

export const withCreateAuthorizationFromEnrollmentMutation = gql`
  mutation createAuthorizationFromExistingEnrollment(
    $enrollmentId: String!
    $patch: createPAFromEnrollmentPatch
  ) {
    createAuthorizationFromEnrollment(
      enrollmentId: $enrollmentId
      patch: $patch
    ) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;

export const withCreateAuthorization = graphql(
  withCreateAuthorizationMutation,
  {
    name: "createAuthorization",
    options: () => ({
      refetchQueries: () => [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
      ],
    }),
  }
);

export const updateAuthorizationMutation = gql`
  mutation updateAuthorization($id: Int!, $patch: JSON) {
    updateAuthorizationById(id: $id, patch: $patch) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;

export const useUpdateAuthorization = () => {
  return useMutation<Mutation, MutationUpdateAuthorizationByIdArgs>(
    updateAuthorizationMutation,
    {
      refetchQueries: [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
      ],
    }
  );
};

export const withUpdateAuthorization = graphql(updateAuthorizationMutation, {
  name: "updateAuthorization",
});

export const withUpdateAuthorizationAndRefetch = graphql(
  updateAuthorizationMutation,
  {
    name: "updateAuthorizationAndRefetch",
    options: () => ({
      refetchQueries: () => [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
      ],
    }),
  }
);

const withUpdateAuthorizationProgressWithoutPatientMutation = gql`
  mutation updateAuthorizationProgressWithoutPatient(
    $id: Int!
    $DrugOptionId: Int
    $config: JSON
    $details: JSON
  ) {
    setDrugOption: updateAuthorizationById(
      id: $id
      patch: { DrugOptionId: $DrugOptionId }
    ) {
      ...detailedAuthorizationInfo
    }
    setConfig: setOnAuthorizationJSONField(
      id: $id
      field: "config"
      patch: $config
    ) {
      ...detailedAuthorizationInfo
    }
    setConfigDetailsOnAuthorization(id: $id, authorizationConfig: $config) {
      ...detailedAuthorizationInfo
    }
    setDetails: setOnAuthorizationJSONField(
      id: $id
      field: "formDetails"
      patch: $details
    ) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withUpdateAuthorizationProgressWithoutPatient = graphql(
  withUpdateAuthorizationProgressWithoutPatientMutation,
  {
    name: "updateAuthorizationProgressWithoutPatient",
    options: {
      refetchQueries: () => [GET_AUTHORIZATION_QUERY_NAME],
    },
  }
);

export const useUpdateAuthorizationProgressWithoutPatient = () => {
  return useMutation<
    Mutation,
    UpdateAuthorizationProgressWithoutPatientMutationVariables
  >(withUpdateAuthorizationProgressWithoutPatientMutation, {
    refetchQueries: [GET_AUTHORIZATION_QUERY_NAME],
  });
};

const withUpdateAuthorizationProgressMutation = gql`
  mutation updateAuthorizationProgress(
    $id: Int!
    $config: JSON
    $attachmentIds: [Int]
    $patientId: Int
    $DrugOptionId: Int
    $details: JSON
  ) {
    setDrugOption: updateAuthorizationById(
      id: $id
      patch: { DrugOptionId: $DrugOptionId }
    ) {
      ...detailedAuthorizationInfo
    }
    setConfig: setOnAuthorizationJSONField(
      id: $id
      field: "config"
      patch: $config
    ) {
      ...detailedAuthorizationInfo
    }
    setDetails: setOnAuthorizationJSONField(
      id: $id
      field: "formDetails"
      patch: $details
    ) {
      ...detailedAuthorizationInfo
    }
    setAuthorizationAttachments(
      authorizationId: $id
      attachmentIds: $attachmentIds
    ) {
      ...detailedAuthorizationInfo
    }
    writeAuthorizationResultsToPatient(
      patientId: $patientId
      authorizationId: $id
    )
    setConfigDetailsOnAuthorization(id: $id, authorizationConfig: $config) {
      ...detailedAuthorizationInfo
    }
    writeIntegratedDetailsToAuthorization(id: $id) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withUpdateAuthorizationProgress = graphql(
  withUpdateAuthorizationProgressMutation,
  {
    name: "updateAuthorizationProgress",
    options: {
      refetchQueries: () => [GET_AUTHORIZATION_QUERY_NAME],
    },
  }
);

export const withUpdateFormDetailsMutation = gql`
  mutation updateFormDetails($id: Int!, $details: JSON) {
    setOnAuthorizationJSONField(
      id: $id
      field: "formDetails"
      patch: $details
    ) {
      ...authorizationInfo
    }
  }
  ${authorizationInfo}
`;

export const withUpdateFormDetails = graphql(withUpdateFormDetailsMutation, {
  name: "updateFormDetails",
});

const withUpdateAuthorizationStatusMutation = gql`
  mutation updateAuthorizationStatus(
    $id: Int!
    $status: AuthorizationStatusEnum!
    $followUpType: String
    $nonApprovalId: Int
    $nonApprovalInfo: String
    $nonApprovalType: String
    $responseDueBy: DateTime
    $portalAuthorizationId: String
    $identifierCaptureType: String
    $terminalStatusUpdateType: TerminalStatusUpdateType
    $segmentDetails: SegmentDetailsInputUpdatePa
  ) {
    updateAuthorizationStatus(
      id: $id
      status: $status
      followUpType: $followUpType
      nonApprovalId: $nonApprovalId
      nonApprovalInfo: $nonApprovalInfo
      nonApprovalType: $nonApprovalType
      responseDueBy: $responseDueBy
      portalAuthorizationId: $portalAuthorizationId
      identifierCaptureType: $identifierCaptureType
      terminalStatusUpdateType: $terminalStatusUpdateType
      segmentDetails: $segmentDetails
    ) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;

export const evictQueries = (
  cache: ApolloCache<any>,
  queryName: string
): void => {
  cache.evict({
    id: "ROOT_QUERY",
    fieldName: queryName,
  });
  cache.gc();
};

export const useUpdateAuthorizationStatus = () => {
  return useMutation<Mutation, MutationUpdateAuthorizationStatusArgs>(
    withUpdateAuthorizationStatusMutation,
    {
      refetchQueries: [
        GET_AUTHORIZATION_LOGS_QUERY_NAME,
        AUTHORIZATION_NOTES_QUERY_NAME,
      ],
      update: (cache) => {
        evictQueries(cache, "authorizationsPaginated");
      },
    }
  );
};

export const withUpdateAuthorizationStatus = graphql(
  withUpdateAuthorizationStatusMutation,
  {
    name: "updateAuthorizationStatus",
    // @ts-ignore
    options: () => ({
      refetchQueries: () => [
        GET_AUTHORIZATION_LOGS_QUERY_NAME,
        AUTHORIZATION_NOTES_QUERY_NAME,
      ],
      /**
       * Every time after we reassign an auth, we want to clear the prev query cache
       * so that front end can get updated data rather than rely on the old cache
       */
      update: (cache) => evictQueries(cache, "authorizationsPaginated"),
    }),
  }
);

const withSubmitAuthorizationMutation = gql`
  mutation submitAuthorization($id: Int!, $send: Boolean!, $signatureId: Int) {
    regenerateLOMNAttachment(authorizationId: $id, signatureId: $signatureId) {
      success
    }
    authorizationSubmit(id: $id, send: $send, signatureId: $signatureId) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withSubmitAuthorization = graphql(
  withSubmitAuthorizationMutation,
  {
    name: "submitAuthorization",
    options: () => ({
      refetchQueries: () => [GET_AUTHORIZATION_LOGS_QUERY_NAME],
    }),
  }
);

export const setPrescriberOnAuthMutation = gql`
  mutation setPrescriberOnAuth($authorizationId: Int!, $prescriberId: Int) {
    setPrescriberOnAuth(
      prescriberId: $prescriberId
      authorizationId: $authorizationId
    ) {
      ...authorizationInfo
    }
  }
  ${authorizationInfo}
`;
export const withSetPrescriberOnAuth = graphql(setPrescriberOnAuthMutation, {
  name: "setPrescriberOnAuth",
});

export const useSetPrescriberOnAuth = () => {
  return useMutation<Mutation, MutationSetPrescriberOnAuthArgs>(
    setPrescriberOnAuthMutation
  );
};

export const setPatientMutation = gql`
  mutation setPatientOnAuth($patientId: Int!, $authorizationId: Int!) {
    setPatientOnAuth(patientId: $patientId, authorizationId: $authorizationId) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withSetPatient = graphql(setPatientMutation, {
  name: "setPatient",
});

export const removePatientMutation = gql`
  mutation removePatientOnAuth($authorizationId: Int!) {
    removePatientOnAuth(authorizationId: $authorizationId) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withRemovePatient = graphql(removePatientMutation, {
  name: "removePatient",
});

const withDeleteAuthorizationMutation = gql`
  mutation deleteAuthorization($id: Int!) {
    authorizationDelete(id: $id)
  }
`;
export const withDeleteAuthorization = graphql(
  withDeleteAuthorizationMutation,
  {
    name: "deleteAuthorization",
    // @ts-ignore
    options: () => ({
      refetchQueries: () => [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
      ],
      update: (cache, res: { data: { authorizationDelete: number } }) => {
        const id = cache.identify({
          id: res.data.authorizationDelete,
          __typename: "Authorization",
        });
        cache.evict({ id });
        cache.gc();
      },
    }),
  }
);

const withSendAuthorizationMutation = gql`
  mutation sendAuthorization(
    $id: Int!
    $phone: String!
    $resendReasonKey: Int
    $resendReasonAdditionalInfo: String
  ) {
    sendAuthorization(
      id: $id
      phone: $phone
      resendReasonKey: $resendReasonKey
      resendReasonAdditionalInfo: $resendReasonAdditionalInfo
    ) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withSendAuthorization = graphql(withSendAuthorizationMutation, {
  name: "sendAuthorization",
});

export const duplicateAuthorizationMutation = gql`
  mutation duplicateAuthorization(
    $authorizationId: Int!
    $setNewForm: Boolean
    $portalId: Int
    $insuranceName: String
    $isExternal: Boolean
  ) {
    duplicateAuthorization(
      authorizationId: $authorizationId
      setNewForm: $setNewForm
      portalId: $portalId
      insuranceName: $insuranceName
      isExternal: $isExternal
    ) {
      id
      PortalId
      isReferral
      type
    }
  }
`;

const withCancelAuthorizationSendMutation = gql`
  mutation cancelAuthorizationSend($id: Int!) {
    cancelAuthorizationSend(id: $id) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withCancelAuthorizationSend = graphql(
  withCancelAuthorizationSendMutation,
  { name: "cancelAuthorizationSend" }
);

const withSetAuthorizationReminderMutation = gql`
  mutation setAuthorizationReminder(
    $id: Int!
    $emailReminderDescription: String
    $emailReminderAt: String!
  ) {
    setAuthorizationReminder(
      id: $id
      emailReminderAt: $emailReminderAt
      emailReminderDescription: $emailReminderDescription
    ) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withSetAuthorizationReminder = graphql(
  withSetAuthorizationReminderMutation,
  { name: "setAuthorizationReminder" }
);

export const disassociateCorrespondenceMutation = gql`
  mutation disassociateCorrespondence(
    $authorizationId: Int!
    $correspondenceId: Int!
  ) {
    disassociateCorrespondence(
      authorizationId: $authorizationId
      correspondenceId: $correspondenceId
    ) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;

export const withDisassociateCorrespondence = graphql(
  disassociateCorrespondenceMutation,
  {
    name: "disassociateCorrespondence",
    options: () => ({
      refetchQueries: () => [
        "allAuthorizations",
        AUTHORIZATION_PAGINATION_QUERY_NAME,
        GET_AUTHORIZATION_QUERY_NAME,
      ],
    }),
  }
);
