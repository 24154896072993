import { FormByIdQuery, FormByIdQueryVariables } from "@@generated/graphql";
import { gql, useQuery } from "@apollo/client";
import authorizationFormInfo from "../graphql/fragments/authorizationFormInfo";

export const getFormByIdQuery = gql`
  query formById($id: Int!) {
    formById(id: $id) {
      ...authorizationFormInfo
    }
  }
  ${authorizationFormInfo}
`;

export const useFormById = (id: number) => {
  const { data, error, loading } = useQuery<
    FormByIdQuery,
    FormByIdQueryVariables
  >(getFormByIdQuery, { variables: { id } });

  return {
    form: data?.formById ?? null,
    loading,
    error,
  };
};
