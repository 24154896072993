import _ from "lodash";
import styled from "styled-components";
import { connect } from "react-redux";
import Section from "AuthorizationSharedComponents/Section";
import FormSubmitButtons from "AuthorizationSharedComponents/FormSubmitButtons";

import { withFormById } from "../../../graphql/AuthorizationForm";
import IcdSelector from "../../IcdSelector";
import { setFormFields } from "../../../reducers/formReducer";
import RequiredTag from "../../RequiredTag";
import { countMaxSamaTypesByPrefix } from "../../../util/inputConfigurationManager";
import {
  icdChangeHandler,
  hcpcsChangeHandler,
} from "../../../util/requestDetailsChangeHandlers";
import {
  SegmentContainer,
  SegmentTitleContainer,
} from "@@components/Segment/SegmentContainer";
import { useApolloClient } from "@apollo/client";
import {
  Form,
  FormProvider,
  ServiceCodeDataWrapper,
  useForm,
} from "@samacare/form2";
import { ServiceCodeOptionalFields } from "@samacare/form2/core/ServiceCodeSelector/ServiceCodeInput";
import { Box } from "@samacare/design";
import { compose } from "redux";
import { useEffect } from "react";
import {
  Authorization,
  AuthorizationForm,
  EnhancedServiecesEnum,
} from "@samacare/graphql";
import { checkDisabledForResubmit } from "../../../util/authUtils";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const defaultFields = window.CONFIG.DEFAULT_FIELDS;
const constants = window.CONFIG.CONSTANTS;

type RequestDetailsProps = {
  back: () => void;
  authorization: Authorization;
  form: AuthorizationForm;
  triggerRequiredModalOnInvalid: () => void;
  nextStep: () => void;
  results: typeof defaultFields;
  disabled: boolean;
  highlightRequiredFields: boolean;
  set: () => void;
};

const RequestDetails = ({
  back,
  authorization,
  form,
  triggerRequiredModalOnInvalid,
  nextStep,
  results,
  disabled,
  highlightRequiredFields,
  set,
}: RequestDetailsProps) => {
  const defaultValues = {
    hcpcsCodes: authorization?.HCPCSCodes ?? [],
  };
  const apolloClient = useApolloClient();
  const hookForm = useForm({ defaultValues });
  const hcpcs = hookForm.watch("hcpcsCodes");
  const strValues = JSON.stringify(hcpcs);

  useEffect(() => {
    hcpcsChangeHandler(hcpcs, set);
  }, [hcpcs, strValues, set]);

  const submit = () => {
    if (!results[defaultFields.ALL_ICDS.key]) {
      triggerRequiredModalOnInvalid();
    } else {
      nextStep();
    }
  };

  const getICDAndHCPCSHelperText = (
    codeType: "HCPCS" | "ICD-10",
    max?: number | null
  ) => {
    if (max && max > 0) {
      return (
        `${codeType} Codes (Max ${max} codes. Limit set by selected payer form. If you want to add` +
        " additional codes, please add them in the additional clinical notes on the payer's form.)"
      );
    }

    return `${codeType} Codes.`;
  };

  const maxHCPCS = countMaxSamaTypesByPrefix(
    _.get(form, "specification", []),
    "HCPCS_"
  );
  const maxICDs = countMaxSamaTypesByPrefix(
    _.get(form, "specification", []),
    "ICD_"
  );

  return (
    <FormProvider {...hookForm}>
      <FormContainer>
        <Form {...hookForm}>
          <Section
            section={{
              items: [
                [
                  {
                    ...defaultFields.REQUEST_DESCRIPTION,
                    disabled: checkDisabledForResubmit(
                      authorization.status ?? ""
                    ),
                  },
                  {
                    ...defaultFields.DATE_OF_SERVICE,
                    warnIfPast: true,
                  },
                ],
              ],
              title:
                "Request Description (Internal Use Only, to help identify requests)",
            }}
          />
          <Section
            section={{
              items: [
                {
                  key: "requestUrgency",
                  title: "Is this an expedited request?",
                  type: constants.FORM_TYPES.OPTION,
                  options: [
                    defaultFields.URGENT_REQUEST,
                    defaultFields.NOT_URGENT_REQUEST,
                  ],
                  required: true,
                },
                {
                  key: "requestType",
                  title: "Is this a start or continuation request?",
                  type: constants.FORM_TYPES.OPTION,
                  options: [
                    defaultFields.INITIATION_REQUEST,
                    defaultFields.CONTINUATION_REQUEST,
                  ],
                  required: true,
                },
              ],
              title: "Request Type",
            }}
          />
          <Section section={{ title: "Patient Diagnosis" }}>
            <SegmentContainer width="600px">
              <SegmentTitleContainer paddingBottom="7px">
                {getICDAndHCPCSHelperText("ICD-10", maxICDs)}
                <RequiredTag />
              </SegmentTitleContainer>
              <IcdSelector
                disabled={disabled}
                max={maxICDs || 10}
                initializedICDs={authorization.ICDs}
                onChange={(newIcds) => {
                  icdChangeHandler(newIcds, set);
                }}
                highlightRequiredFields={highlightRequiredFields}
              />
            </SegmentContainer>
          </Section>
          <Section section={{ title: "Services" }}>
            <SegmentTitleContainer paddingBottom="12px">
              {getICDAndHCPCSHelperText("HCPCS", maxHCPCS)}
              <RequiredTag />
            </SegmentTitleContainer>
            <Box sx={{ width: "100%" }}>
              <ServiceCodeDataWrapper
                apolloClient={apolloClient}
                name="hcpcsCodes"
                required={true}
                disabled={disabled}
                optionalFields={[ServiceCodeOptionalFields.Quantity]}
                highlightedEnhancedServices={[
                  EnhancedServiecesEnum.PaBoost,
                  EnhancedServiecesEnum.AuthInform,
                  EnhancedServiecesEnum.PayerPolicy,
                  EnhancedServiecesEnum.PostSubmissionEnhancedMessaging,
                ]}
                disableSelectedService={checkDisabledForResubmit(
                  authorization.status ?? ""
                )}
              />
            </Box>
          </Section>
          <FormSubmitButtons
            back={back}
            submit={hookForm.handleSubmit(submit)}
          />
        </Form>
      </FormContainer>
    </FormProvider>
  );
};

function mapStateToProps(state: {
  form: {
    results: object;
    disabled: boolean;
    highlightRequiredFields: boolean;
  };
}) {
  return {
    results: state.form.results,
    disabled: state.form.disabled,
    highlightRequiredFields: state.form.highlightRequiredFields,
  };
}

const mapDispatchToProps = (
  dispatch: (action: {
    type: string;
    toSet: Record<string, unknown> | null;
  }) => void
) => ({
  set(fields: Record<string, unknown>) {
    dispatch(setFormFields(fields));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withFormById)(RequestDetails));
