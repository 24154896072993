import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import CreateIcon from "@samacare/design/core/icons/Create";
import { EmailField } from "@samacare/form/EmailField/EmailField";
import { AuthLoginQuery, AuthLoginQueryVariables } from "@@generated/graphql";

import {
  Alert,
  Stack,
  Button,
  LoadingButton,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@samacare/design/core";

import {
  useForm,
  PasswordField,
  SubmitHandler,
  FormProvider,
} from "@samacare/form";

import { AuthTitle } from "./AuthTitle";
import AuthLoginGql from "./AuthLogin.gql";
import { useAuth } from "../../providers/AuthProvider";
import {
  AUTH_CLIENT_ID_STORAGE,
  AUTH_COGNITO_DOMAIN_STORAGE,
  AUTH_USERNAME_STORAGE,
  AUTH_USERPOOL_STORAGE,
} from "./Auth.constants";
import { CognitoClient } from "@samacare/graphql";
import { sendMsgToBgScript } from "app/util/bgScriptMsgUtils";
import { configureAmplify } from "./AmplifyConfiguration";

type AuthLoginForm = {
  username: string;
  password?: string;
};

export const AuthLogin: React.FC = () => {
  const history = useHistory();
  const { onSignIn, onSignInWithRedirect, error, loading, onReset, onError } =
    useAuth();
  const methods = useForm<AuthLoginForm>();

  const [isSso, setIsSSO] = useState(false);
  const [identityProviderName, setIdentityProviderName] = useState<
    string | undefined
  >(void 0);
  const [getIdentityProvider, { loading: requestLoading, called }] =
    useLazyQuery<AuthLoginQuery, AuthLoginQueryVariables>(AuthLoginGql, {
      onCompleted: (requestData) => {
        if (requestData.getIdentityProvider) {
          const { userPool, clientId, domain } = requestData.getIdentityProvider
            .cognitoClient as CognitoClient;
          setIdentityProviderName(
            requestData.getIdentityProvider.name ?? void 0
          );
          setIsSSO(requestData.getIdentityProvider.isSso ?? false);
          localStorage.setItem(
            AUTH_USERPOOL_STORAGE,
            userPool?.userPoolId as string
          );
          localStorage.setItem(AUTH_CLIENT_ID_STORAGE, clientId);
          localStorage.setItem(AUTH_COGNITO_DOMAIN_STORAGE, domain);
          void sendMsgToBgScript({
            type: "CognitoConfig",
            data: {
              userPoolId: userPool?.userPoolId,
              clientId,
            },
          });
        }
      },
      onError: (requestError) => {
        onError(requestError.message);
      },
    });
  const [username, setUsername] = useState<string | undefined>(
    () => localStorage.getItem(AUTH_USERNAME_STORAGE) ?? void 0
  );

  useEffect(() => {
    if (!called && !requestLoading) {
      void (async () => {
        await getIdentityProvider({ variables: { email: username ?? "" } });
      })();
    }
  }, [called, username, requestLoading, getIdentityProvider]);

  const onSubmit: SubmitHandler<AuthLoginForm> = async (data) => {
    if (!username) {
      localStorage.setItem(AUTH_USERNAME_STORAGE, data.username);
      setUsername(data.username);
      await getIdentityProvider({
        variables: {
          email: data.username,
        },
      });
      return;
    }

    configureAmplify();
    if (!isSso) {
      await onSignIn(data);
    }
    if (isSso && identityProviderName) {
      await onSignInWithRedirect(identityProviderName);
    }
  };

  const isLoading = loading || requestLoading;
  const callToAction = isSso ? "Login with SSO" : "Login";

  if (requestLoading) {
    return (
      <Stack spacing={2} alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <FormProvider {...methods}>
      <form name="login" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <AuthTitle>Login to SamaCare</AuthTitle>
        <Stack spacing={2}>
          <EmailField
            name="username"
            label="Email address"
            disabled={!!username}
            value={username}
            InputProps={{
              endAdornment: username && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Change email address"
                    onClick={() => {
                      localStorage.removeItem(AUTH_USERNAME_STORAGE);
                      localStorage.removeItem(AUTH_USERPOOL_STORAGE);
                      localStorage.removeItem(AUTH_CLIENT_ID_STORAGE);
                      localStorage.removeItem(AUTH_COGNITO_DOMAIN_STORAGE);
                      methods.setValue("username", "");
                      void sendMsgToBgScript({
                        type: "CognitoConfig",
                        data: {
                          userPoolId: undefined,
                          clientId: undefined,
                        },
                      });
                      setUsername(void 0);
                      setIdentityProviderName(void 0);
                      setIsSSO(false);
                      onError(void 0);
                    }}
                    edge="end"
                  >
                    <CreateIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {username && !isSso && (
            <PasswordField
              name="password"
              label="Password"
              InputProps={{
                autoComplete: "current-password",
              }}
            />
          )}
          {error && <Alert color="error">{error}</Alert>}

          <LoadingButton
            loading={isLoading}
            type="submit"
            size="large"
            variant="contained"
            data-cy="actionLogin"
          >
            {username ? callToAction : "Continue"}
          </LoadingButton>
          {!isSso && (
            <Button
              onClick={() => {
                onReset();
                history.push("/auth/reset-password");
              }}
              size="large"
            >
              Forgot Password?
            </Button>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
