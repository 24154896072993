import React from "react";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { ListItemText, MenuItem, Typography } from "@samacare/design";
import { EnhancedServiecesEnum, ServiceCode } from "@samacare/graphql";
import { EnhancedServicesIcon } from "@samacare/component";
import _ from "lodash";

export const renderServiceAutoSuggestOptions = (highlightedEnhancedServices: EnhancedServiecesEnum[] | undefined) => (
  props: React.HTMLAttributes<HTMLLIElement>,
  { drugName, code, enhancedServices, drugOptionId }: ServiceCode,
  params: { selected: boolean; inputValue: string },
): React.ReactNode => {
  const label = `${code}: ${drugName!} `;

  const { selected, inputValue } = params;
  const matches = match(label, inputValue, { insideWords: true });
  const parts = parse(label, matches);

  const shouldHighlight = !_.isEmpty(highlightedEnhancedServices) &&
    !_.isEmpty(enhancedServices) &&
    !_.isEmpty(_.intersection(highlightedEnhancedServices, enhancedServices))

  return (
    <MenuItem {...props} key={drugOptionId ?? (code + drugName)} selected={selected}>
      <ListItemText
        primary={parts.map((part, index) => (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            component="span"
            sx={{
              fontWeight: part.highlight ? 700 : 400,
            }}
          >
            {part.text}
          </Typography>
        ))}
        primaryTypographyProps={{
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }
        }}
        sx={{
          flexGrow: 0,
        }}
      />
      {shouldHighlight && <EnhancedServicesIcon
        sx={{ fontSize: 20 }}
        hover={false}
        key={`icon-${code}`}
      />}
    </MenuItem>
  );
};
