import { Stack } from "@samacare/design/core";
import { IcdField } from "@samacare/form";
import { useApolloClient } from "@apollo/client";

export type DiagnosisInfoBlockProps = {
  isSubmitted?: boolean;
  isRequired?: boolean;
};

export const HorizonKrystexxaDiagnosisBlock: React.FC<
  DiagnosisInfoBlockProps
> = ({ isSubmitted = false }) => {
  const apolloClient = useApolloClient();

  return (
    <Stack direction="column" spacing={2} maxWidth={600}>
      <IcdField
        apolloClient={apolloClient}
        name="questionnaire.secondaryDiagnoses"
        label="Additional disease manifestation codes"
        override={{
          limit: 1,
        }}
        disabled={isSubmitted}
      />
    </Stack>
  );
};
