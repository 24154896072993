import { useEffect } from "react";
import { Stack } from "@samacare/design";
import { DrugCodeType } from "@samacare/graphql";
import {
  AutocompleteField,
  NdcSelector,
  RadioButtonsField,
  ServiceOptionalFields,
  ServiceSelector,
  useFormContext,
  DatePicker,
  ServiceNoteDisplayingTypes
} from "@samacare/form2";
import {
  BenefitTypeOptions,
  TreatmentPhaseOptions,
  UrgencyOptionsDescription,
  UrgencyTypes,
} from "./constants";

export enum HcpcsBlocks {
  BenefitTypes = "benefitTypes",
  TreatmentPhases = "treatmentPhases",
  Urgency = "urgency",
  StartDate = "startDate",
  EndDate = "endDate",
}

export interface HcpcsProps {
  apolloClient: object; // Couldn't keep Typescript happy on the calling end when given the proper ApolloClient type
  blocks: HcpcsBlocks[];
  disabled?: boolean;
  serviceProps?: {
    noteDisplayingType?: ServiceNoteDisplayingTypes,
    restrictAfterLimit?: boolean,
    optionalFields?: ServiceOptionalFields[],
  };
}

export const Hcpcs = ({
  apolloClient, blocks, serviceProps, disabled,
}: HcpcsProps) => {
  const { watch, unregister } = useFormContext();
  const drugCodeType = watch("drugCodeType");

  const useNdc = drugCodeType === DrugCodeType.Ndc;

  useEffect(() => {
    if (useNdc) unregister("services");
    else unregister("ndc");
  }, [useNdc, unregister]);

  return (
    <Stack
      spacing={3}
      maxWidth={500}
      sx={{
        margin: "20px 0",
      }}
    >
      {blocks?.includes(HcpcsBlocks.BenefitTypes) && (
        <RadioButtonsField
          label="Benefit type"
          name="drugCodeType"
          options={BenefitTypeOptions}
          disabled={disabled}
        />
      )}
      {blocks?.includes(HcpcsBlocks.TreatmentPhases) && (
        <RadioButtonsField
          label="Treatment phase"
          name="requestType"
          options={TreatmentPhaseOptions}
          disabled={disabled}
        />
      )}
      {
        (blocks?.includes(HcpcsBlocks.StartDate) || blocks?.includes(HcpcsBlocks.EndDate)) &&
          (<Stack direction="row" spacing={1}>
            {blocks?.includes(HcpcsBlocks.StartDate) && (
              <DatePicker
                label="Start date"
                name="dateOfService"
                required
                disabled={disabled}
                warnIfPast
              />
            )}
            {blocks?.includes(HcpcsBlocks.EndDate) && (
              <DatePicker
                label="End date"
                name="endDateOfService"
                disabled={disabled}
                warnIfPast
              />
            )}
          </Stack>)
      }
      {blocks?.includes(HcpcsBlocks.Urgency) && (
        <AutocompleteField<string, false, true, false>
          required={false}
          label="Urgency"
          options={Object.keys(UrgencyTypes)}
          name="urgencyRequest"
          getOptionLabel={(o: string) => UrgencyOptionsDescription[o]}
          disabled={disabled}
        />
      )}
      {
        useNdc ? <NdcSelector
              name="ndc"
              apolloClient={apolloClient}
              disabled={disabled}
              required
            />
          : <ServiceSelector
              name="services"
              apolloClient={apolloClient}
              required
              disabled={disabled}
              noteDisplayingType={serviceProps?.noteDisplayingType}
              restrictAfterLimit={serviceProps?.restrictAfterLimit}
              optionalFields={serviceProps?.optionalFields}
            />
      }
    </Stack>
  );
};
