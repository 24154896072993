import { PureComponent } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { SegmentTitle } from "Segment/SegmentTitle";
import { SegmentContainer } from "Segment/SegmentContainer";
import { SegmentFieldContainer } from "../Segment/SegmentFieldContainer";
import { HelpTag } from "@samacare/layout/HelpTag";

class Section extends PureComponent {
  // SegmentContainer uses a temporary hack (isModal) to fix alignments in modals/mobile views.
  render() {
    const {
      section,
      childrenAfterSection,
      display,
      disabled,
      children,
      highlightAsMissing,
      isModal,
      showRequiredTag = false,
      showTitle = true,
    } = this.props;

    if (display) {
      return (
        <SegmentContainer style={{ padding: 0 }}>
          {showTitle && (
            <SegmentTitle showRequiredTag={showRequiredTag}>
              {section.title}
              {section.help && <HelpTag text={section.help} />}
            </SegmentTitle>
          )}
          {!childrenAfterSection && children}
          <SegmentFieldContainer
            items={section.items}
            highlightAsMissing={highlightAsMissing}
            disabled={disabled}
            isModal={isModal}
          />
          {childrenAfterSection && children}
        </SegmentContainer>
      );
    }
    return <div />;
  }
}

function mapStateToProps(state, props) {
  return {
    display:
      !props.section.displayIf ||
      _.get(state, `form.results.${props.section.displayIf}`),
    disabled: state.form.disabled || props.disabled,
  };
}

export default connect(mapStateToProps)(Section);
