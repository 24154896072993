import { createContext, useContext } from "react";
import type * as CONSTANTS from "../../../../server/src/sharedConstants";
import type DEFAULT_FIELDS from "../../../../server/src/forms/defaultFields";
import window from "./window";

export interface Config {
  CONSTANTS: typeof CONSTANTS;
  DEFAULT_FIELDS: typeof DEFAULT_FIELDS;
  FULL_STORY_ID: string;
  NODE_ENV: string;
  SITE_DOMAIN: string;
  SITE_PROTOCOL: string;
  ENABLE_FRESHDESK: boolean;
  INGEST_PIPELINE_BUCKET: string;
  LAUNCH_DARKLY_CLIENT_ID: string;
  SATISMETER_KEY: string;
  ENABLE_SATISMETER: boolean;
  COGNITO_ENABLED: boolean;
  SEGMENT_WRITE_KEY: string;
}

export interface FcWidget {
  show: () => void;
  open: () => void;
  user: {
    setProperties: (options: any) => void;
  };
}

export const ConfigContext = createContext(window.CONFIG);

export const ConfigProvider = ConfigContext.Provider;

export const ConfigConsumer = ConfigContext.Consumer;

export const useConfig = (): Config => useContext(ConfigContext);

export const useFeatureFlagNames =
  (): typeof CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS => {
    const { CONSTANTS } = useConfig();
    return CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS;
  };

export const useSamaCareBrandName =
  (): typeof CONSTANTS.SAMACARE_BRAND_NAME => {
    const { CONSTANTS } = useConfig();
    return CONSTANTS.SAMACARE_BRAND_NAME;
  };
