import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@samacare/design/core";
import * as React from "react";

export const MfaConfirmationModal: React.VoidFunctionComponent<{
  onClose: () => void;
  onCancel: () => void;
  open: boolean;
  isEnablingMfa: boolean;
}> = ({ onClose, onCancel, open, isEnablingMfa }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm MFA Status Change</DialogTitle>
      <DialogContent>
        <Typography>
          {isEnablingMfa
            ? "You are about to enable Multi-Factor Authentication (MFA) for all users in this institution. This change will take effect immediately after saving."
            : "You are about to disable Multi-Factor Authentication (MFA) for all users in this institution. They will be no longer asked to use MFA next time they log in. This change will take effect immediately after saving."}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onClose} variant="contained">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
