import MuiAutocomplete, {
  AutocompleteProps,
  AutocompleteValue,
} from "@mui/material/Autocomplete";
import { UseAutocompleteProps } from "@mui/base/useAutocomplete";

const Autocomplete = MuiAutocomplete;

type AutocompleteOnChangeType<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = UseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>["onChange"];

export default Autocomplete;
export type { AutocompleteProps, AutocompleteValue, AutocompleteOnChangeType };
