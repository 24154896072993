import {
  DrugCodeType,
  FilterFormBenefitType,
  HcpcsCode,
  Ndc,
} from "@samacare/graphql";
import { TreatmentPhases } from "@samacare/form2";

const defaultFields = window.CONFIG.DEFAULT_FIELDS;
const MAX_ICDS_AND_HCPCS =
  window.CONFIG.CONSTANTS.POSSIBLE_ICD_AND_HCPCS_ENTRIES;

interface HCPCSCode {
  code?: string;
  modifier?: string;
  quantity?: string;
  unit?: string;
  quantityType?: string;
  drugName?: string;
}

const formatHCPCSCodeDescription = (hcpcsCode: HCPCSCode): string => {
  const modifierText = hcpcsCode.modifier ? ` (${hcpcsCode.modifier})` : "";
  const quantityText = hcpcsCode.quantity ? ` x${hcpcsCode.quantity}` : "";
  return `${hcpcsCode.code}${modifierText}${quantityText}`;
};

const createHCPCSFieldEntries = (
  hcpcsCode: HCPCSCode | null,
  index: number
) => {
  const fields: Record<string, any> = {};

  if (index === 0 && hcpcsCode?.drugName) {
    fields[defaultFields.PRIMARY_DRUG_NAME.key] = hcpcsCode?.drugName;
    fields[defaultFields.REQUEST_DESCRIPTION.key] = hcpcsCode?.drugName;
  }

  fields[defaultFields[`HCPCS_${index}`].key] = hcpcsCode?.code || null;
  fields[defaultFields[`HCPCS_QUANTITY_${index}`].key] =
    hcpcsCode?.quantity ?? null;
  fields[defaultFields[`HCPCS_DRUG_NAME_${index}`].key] =
    hcpcsCode?.drugName || null;

  if (hcpcsCode?.unit != null) {
    fields[defaultFields[`HCPCS_UNIT_${index}`].key] = hcpcsCode.unit;
  }

  if (hcpcsCode?.quantityType != null) {
    fields[defaultFields[`HCPCS_QUANTITY_TYPE_${index}`].key] =
      hcpcsCode.quantityType;
  }

  return fields;
};

export const generateHCPCSData = (
  newHCPCSCodes: HCPCSCode[]
): Record<string, any> => {
  const formattedCodes = newHCPCSCodes.map(formatHCPCSCodeDescription);

  const hcpcsFieldEntries = [];

  for (let i = 0; i < MAX_ICDS_AND_HCPCS; i++) {
    hcpcsFieldEntries.push(
      createHCPCSFieldEntries(newHCPCSCodes[i] || null, i)
    );
  }

  const mergedFieldEntries = Object.assign({}, ...hcpcsFieldEntries);

  return {
    [defaultFields.ALL_HCPCS.key]: formattedCodes.join(", "),
    HCPCS_CODES: newHCPCSCodes.map((c) => c.code).join(","),
    ...mergedFieldEntries,
  };
};

export const generateDrugTypes = (type: DrugCodeType): Record<string, any> => {
  if (type === DrugCodeType.Jcode) {
    return {
      [defaultFields.DRUG_CODE_TYPE.key]: DrugCodeType.Jcode,
      [defaultFields.BENEFIT_TYPES.key]: FilterFormBenefitType.MedicalOnly,
    };
  } else if (type === DrugCodeType.Ndc) {
    return {
      [defaultFields.DRUG_CODE_TYPE.key]: DrugCodeType.Ndc,
      [defaultFields.BENEFIT_TYPES.key]: FilterFormBenefitType.PharmacyOnly,
    };
  }

  return {};
};

export const detectRequestType = (params: {
  [x: string]: string | boolean | undefined;
}): TreatmentPhases | null => {
  if (params[defaultFields.INITIATION_REQUEST.key] === "On")
    return TreatmentPhases.INITIATION_REQUEST;
  if (params[defaultFields.CONTINUATION_REQUEST.key] === "On")
    return TreatmentPhases.CONTINUATION_REQUEST;
  return null;
};

export const generateRequestTypes = ({
  requestType,
}: {
  requestType: string | null;
}) => {
  return {
    [defaultFields.INITIATION_REQUEST.key]:
      requestType === TreatmentPhases.INITIATION_REQUEST ? "On" : null,
    [defaultFields.CONTINUATION_REQUEST.key]:
      requestType === TreatmentPhases.CONTINUATION_REQUEST ? "On" : null,
  };
};

export const detectNdc = (params: { [x: string]: string }) => {
  if (!params[defaultFields.PRIMARY_NDC.key]) return null;

  return {
    code: params[defaultFields.PRIMARY_NDC.key] ?? "",
    brandName: params[defaultFields.PRIMARY_DRUG_NAME.key] ?? "",
  };
};

export const generateNdcData = (data: Ndc | null) => {
  if (!data) {
    return {
      [defaultFields.PRIMARY_NDC.key]: null,
    };
  }

  const { code, brandName } = data;

  return {
    [defaultFields.PRIMARY_DRUG_NAME.key]: brandName ?? "",
    [defaultFields.PRIMARY_NDC.key]: code ?? "",
    [defaultFields.REQUEST_DESCRIPTION.key]: brandName?.toUpperCase() ?? "",
  };
};

export const generateServiceDetails = ({
  type,
  hcpcsCodes,
  ndc,
}: {
  type: DrugCodeType;
  hcpcsCodes: HcpcsCode[];
  ndc: Ndc | null;
}) => {
  const isNdc = type === DrugCodeType.Ndc;

  const basicConfiguration = generateDrugTypes(type);
  const hcpcs = generateHCPCSData(isNdc ? [] : (hcpcsCodes as HCPCSCode[]));
  const ndcCode = generateNdcData(isNdc ? ndc : null);

  return {
    ...basicConfiguration,
    ...hcpcs,
    ...ndcCode,
  };
};
