import React, { ReactElement, useContext, useState } from "react";
import { useHistory } from "../hooks/routing";
import { useAlert } from "react-alert";
import _ from "lodash";

import {
  EnhancedServiecesEnum,
  Mutation,
  MutationCreateAuthorizationFromBvArgs,
  MutationCreateAuthorizationFromEnrollmentArgs,
  PaOriginType,
} from "@samacare/graphql";
import PortalSelectionModal from "./PortalSelectionModal";
import ROUTE_PATHS from "../routes/ROUTE_PATHS";
import { useCurrentAccount } from "../graphql/Account";
import {
  AUTHORIZATION_PAGINATION_QUERY_NAME,
  useCreateAuthorization,
  withCreateAuthorizationFromBvMutation,
  ALL_AUTHORIZATIONS_QUERY_NAME,
  withCreateAuthorizationFromEnrollmentMutation,
} from "../graphql/Authorization";
import { useMutation } from "@apollo/client";
import { useConfig, useSamaCareBrandName } from "@@hooks/config";
import {
  Box,
  Paper,
  Stack,
  Tooltip,
  MenuItem,
  Typography,
  useTheme,
} from "@samacare/design/core";
import { alpha } from "@samacare/design/core/styles";
import { useFeatureFlag } from "../hooks";
import { WebExtensionContext } from "../contexts/webExtension";
import { PopperWithTarget } from "./PopperWithTarget";
import { doCreate } from "../util/authUtils";
import { PortalContext } from "../../app/contexts/portalContext";
import { CYPRESS_MOCK_EXTENSION } from "../constants/AuthorizationMock";
import Add from "@samacare/design/core/icons/Add";
import { EnhancedServicesIcon } from "@samacare/component";
import { openBvEditPage } from "../BenefitsVerifications/SponsoredBvCreationSection";
import {
  CreateBenefitsVerificationMutation,
  CreateBenefitsVerificationMutationVariables,
} from "../../generated/graphql";
import CreateBenefitsVerificationMutationGql from "../routes/BenefitsVerifications/graphql/CreateBenefitsVerification.gql";

export type NewPriorAuthButtonProps = React.ComponentProps<
  typeof NewPriorAuthButton
>;

const Item = ({
  icon,
  text,
  onClick,
  divider = false,
  isSectionHeader = false,
}: {
  icon?: ReactElement;
  text: ReactElement;
  onClick?: () => void;
  divider?: boolean;
  isSectionHeader?: boolean;
}) => {
  let styles = { padding: "7px" };
  const sectionHeaderStyles = {
    "&:hover": { backgroundColor: "white", cursor: "auto" },
  };
  if (isSectionHeader) styles = { ...styles, ...sectionHeaderStyles };

  return (
    <MenuItem sx={styles} onClick={onClick} divider={divider}>
      <Stack direction="row" alignItems="center">
        <Box sx={{ width: "24px", marginRight: "6px" }}>{icon}</Box>
        <Box>{text}</Box>
      </Stack>
    </MenuItem>
  );
};

const NewPriorAuthButton: React.VoidFunctionComponent<{
  children: React.ReactNode;
  configOverrides?: Record<string, unknown>;
  patientId?: string;
  drugOptionId?: string;
  enhancedServices?: EnhancedServiecesEnum[];
}> = ({
  children,
  configOverrides,
  patientId,
  drugOptionId,
  enhancedServices,
}) => {
  const config = useConfig();
  const [portalSelectionOpen, setPortalSelectionOpen] = useState(false);
  const [createAuthorization] = useCreateAuthorization();
  const [account] = useCurrentAccount();
  const history = useHistory();
  const alert = useAlert();
  const brandName = useSamaCareBrandName();
  const { isWebExtensionConnected } = useContext(WebExtensionContext);
  const { setPortalData } = useContext(PortalContext);

  const [cypressMockExtension] = useState<string | null>(() =>
    localStorage.getItem(CYPRESS_MOCK_EXTENSION)
  );
  const theme = useTheme();

  const enableBlendedPortalWorkflow = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableBlendedPortalWorkflow
  );
  const isEdiAuthEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.AvailityEdiSubmission
  );
  const emailUserOnChange = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EmailOnAuthStatusChange
  );

  const showNewEnrollmentOption = _.includes(
    enhancedServices,
    EnhancedServiecesEnum.EnrollSmart
  );
  const showNewBvOption = _.includes(
    enhancedServices,
    EnhancedServiecesEnum.BvEase
  );

  const [createAuthorizationFromBv] = useMutation<
    Mutation,
    MutationCreateAuthorizationFromBvArgs
  >(withCreateAuthorizationFromBvMutation, {
    refetchQueries: [
      ALL_AUTHORIZATIONS_QUERY_NAME,
      AUTHORIZATION_PAGINATION_QUERY_NAME,
    ],
  });

  const [createAuthorizationFromExistingEnrollment] = useMutation<
    Mutation,
    MutationCreateAuthorizationFromEnrollmentArgs
  >(withCreateAuthorizationFromEnrollmentMutation, {
    refetchQueries: [
      ALL_AUTHORIZATIONS_QUERY_NAME,
      AUTHORIZATION_PAGINATION_QUERY_NAME,
    ],
  });

  const [createBenefitsVerification] = useMutation<
    CreateBenefitsVerificationMutation,
    CreateBenefitsVerificationMutationVariables
  >(CreateBenefitsVerificationMutationGql);

  const handleCreate = async ({
    type,
    portal,
    onClickConfigOverride = {},
  }: {
    type: string;
    portal?: { id?: string; key?: string; isLegacy: boolean; title: string };
    onClickConfigOverride?: Record<string, unknown>;
  }) => {
    if (portal && !portal.isLegacy && enableBlendedPortalWorkflow) {
      setPortalData({ portal, onClickConfigOverride });
      history.push(ROUTE_PATHS.PORTAL_AUTH_NEW.path);
    } else {
      await doCreate({
        type,
        paOrigin: PaOriginType.WebApp,
        portal,
        onClickConfigOverride,
        account,
        configOverrides,
        config,
        patientId,
        createAuthorizationFromBv,
        createAuthorizationFromExistingEnrollment,
        createAuthorization,
        history,
        alert,
        emailUserOnChange: !!emailUserOnChange,
      });
    }
  };

  const handleEdiAuthCreate = () => {
    history.push(ROUTE_PATHS.EDI_AUTH_CREATE.path);
  };

  if (account == null) return null;

  const authorizationTypes = _.compact([
    {
      key: "newFormAuth",
      text: "Form",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.FORM.key,
      description:
        "An insurance authorization form is electronically filled and faxed to the payer",
    },
    !_.isEmpty(account?.institution?.ReferralForms) && {
      key: "newReferralAuth",
      text: "Referral",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.REFERRAL.key,
      description: undefined,
    },
    {
      key: "newWebAuth",
      text: "Web Portal",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key,
      description: "The payer uses an online portal to submit authorizations",
      onClick: () => setPortalSelectionOpen(true),
    },
    {
      key: "newExternalAuth",
      text: "External",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key,
      description: `Track an authorization you submitted outside of ${brandName}`,
    },
    isEdiAuthEnabled && {
      key: "newEdiAuth",
      text: "ePA",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.EDI.key,
      description: "Submit authorizations directly to the payer electronically",
      onClick: handleEdiAuthCreate,
    },
  ]) as {
    key: string;
    text: string;
    type: string;
    description?: string;
    onClick?: () => void;
  }[];

  return (
    <div data-cy="controlNewAuthorization">
      {drugOptionId != null ? (
        <PopperWithTarget target={children} placement="bottom-end">
          <Paper sx={{ width: "200px" }}>
            <Item
              icon={<Add sx={{ color: theme.palette.text.secondary }} />}
              text={
                <Typography
                  variant="subtitle2"
                  color={theme.palette.text.secondary}
                >
                  New Auth
                </Typography>
              }
              isSectionHeader={true}
            />
            {authorizationTypes.map(
              ({ key, text, type, description, onClick }, index) => (
                <Tooltip placement="left" key={key} title={description}>
                  <Item
                    data-cy={_.camelCase(`action_${key}`)}
                    onClick={onClick || (async () => handleCreate({ type }))}
                    text={<Typography variant="body2">{text}</Typography>}
                    divider={index === authorizationTypes.length - 1}
                  />
                </Tooltip>
              )
            )}

            {showNewBvOption && (
              <Item
                icon={
                  <EnhancedServicesIcon
                    sx={{ color: alpha(theme.palette.text.primary, 0.54) }}
                  />
                }
                text={
                  <Typography variant="body2">Check Drug Coverage</Typography>
                }
                onClick={async () => {
                  const res = await createBenefitsVerification({
                    variables: {
                      patientId: Number(patientId),
                      drugOptionId: Number(drugOptionId),
                    },
                  });
                  openBvEditPage(
                    res?.data?.createBenefitsVerification?.id ?? undefined
                  );
                }}
              />
            )}

            {showNewEnrollmentOption && (
              <Item
                icon={
                  <EnhancedServicesIcon
                    sx={{ color: alpha(theme.palette.text.primary, 0.54) }}
                  />
                }
                text={<Typography variant="body2">New Enrollment</Typography>}
                onClick={async () => {
                  window.open(
                    `${window.location.origin}/#${ROUTE_PATHS.ENROLLMENTS_CREATE.path}/program`,
                    "_blank"
                  );
                }}
              />
            )}
          </Paper>
        </PopperWithTarget>
      ) : (
        <PopperWithTarget target={children} placement="bottom-start">
          <Paper>
            {authorizationTypes.map(
              ({ key, text, type, description, onClick }) => (
                <Tooltip placement="left" key={key} title={description}>
                  <MenuItem
                    sx={{ padding: "10px" }}
                    data-cy={_.camelCase(`action_${key}`)}
                    onClick={onClick || (async () => handleCreate({ type }))}
                  >
                    {text}
                  </MenuItem>
                </Tooltip>
              )
            )}
          </Paper>
        </PopperWithTarget>
      )}
      {portalSelectionOpen && (
        <PortalSelectionModal
          open={portalSelectionOpen}
          institution={account.institution}
          isWebExtensionConnected={
            isWebExtensionConnected || !!cypressMockExtension
          }
          isCypressMockExtension={!!cypressMockExtension}
          closeModal={() => {
            setPortalSelectionOpen(false);
          }}
          onSelect={async (portal: {
            key?: string;
            insuranceCompanyName: string;
            isLegacy: boolean;
            title: string;
          }) =>
            handleCreate({
              type: config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key,
              portal,
            })
          }
        />
      )}
    </div>
  );
};

/**
 * @deprecated Reorganize me?
 */
export default NewPriorAuthButton;
