import { gql, useLazyQuery } from "@apollo/client";
import { Ndc } from "@samacare/graphql";
import { useCallback, useState } from "react";

export const findNdcQuery = gql`
    query NDCs($searchTerm: String!) {
        NDCs(searchTerm: $searchTerm) {
            code
            brandName
            genericName
        }
    }
`;

export const useFindNdc = () => {
  const [ndcs, setNdcs] = useState<Ndc[]>([]);

  const [find, { loading, error }] = useLazyQuery(findNdcQuery, {
    onCompleted: (d) => {
      setNdcs(d?.NDCs);
    },
    fetchPolicy: "network-only",
  });

  const findNdc = useCallback(
    (searchTerm: string) => {
      find({ variables: { searchTerm } });
    },
    [find],
  );

  return {
    findNdc,
    ndcs,
    loading,
    error,
  };
};
