import _ from "lodash";
import { useHistory } from "react-router-dom";
import {
  Authorization,
  AuthorizationLog,
  AuthorizationStepKey,
} from "@samacare/graphql";

import { useConfig, useFeatureFlag } from "../../hooks";
import { previousAuthorizationStatus } from "../../util/authUtils";
import { Button } from "@samacare/design";
import { alpha, useTheme } from "@samacare/design/core/styles";
import { EnhancedServicesIcon } from "@samacare/component";
import WarningAmberIcon from "@samacare/design/core/icons/WarningAmberIcon";
import getAuthorizationRoute from "../../util/getAuthorizationRoute";
import * as stepUtils from "../../util/stepUtils";
import { cleanDrugName } from "../../../../server/src/shared_code";
import {
  GenericActionBanner,
  CARD_PLACEMENT,
  CardPlacement,
} from "./GenericActionBanner";

type BannerProps = {
  backgroundColor?: string;
  color?: string;
  icon: React.ReactNode | null;
  buttonBackground?: string;
  subtitle: string | React.ReactNode;
  title: string;
  dataCy: string;
  severityOverride?: "warning" | "error";
  useDefaultIcon?: boolean;
  subtitleSxOverride?: {
    subtitleVariant: "body2";
    color?: string;
  };
};

const getBannerProps = ({
  authorization,
  enhancedMessagingEnabled,
  isDenied,
  displayActionRequiredBanner,
  theme,
}: {
  authorization: Authorization;
  enhancedMessagingEnabled: boolean;
  isDenied: boolean;
  displayActionRequiredBanner: boolean;
  theme: any;
}): BannerProps | undefined => {
  if (!isDenied && !displayActionRequiredBanner) return undefined;

  const getTitle = () => {
    if (displayActionRequiredBanner) {
      return `Updated status from payer: Action required${
        enhancedMessagingEnabled
          ? ` for ${cleanDrugName(authorization?.drugOption?.drugName)}`
          : ""
      }`;
    }
    if (isDenied) {
      if (enhancedMessagingEnabled) {
        return `Updated status from payer: Denial for ${cleanDrugName(
          authorization?.drugOption?.drugName
        )}`;
      }
      return `Updated status from payer: Denied`;
    }
    return "";
  };

  return {
    backgroundColor: enhancedMessagingEnabled
      ? alpha(theme.palette.colors.C500, 0.12)
      : undefined,
    color: enhancedMessagingEnabled ? theme.palette.text.primary : undefined,
    icon: enhancedMessagingEnabled ? (
      <EnhancedServicesIcon />
    ) : isDenied ? null : (
      <WarningAmberIcon />
    ),
    title: getTitle(),
    subtitle: "Response requires review",
    dataCy: isDenied ? "deniedBanner" : "actionRequiredBanner",
    severityOverride: isDenied ? "error" : "warning",
    useDefaultIcon: isDenied,
    subtitleSxOverride: {
      subtitleVariant: "body2",
      color: enhancedMessagingEnabled
        ? theme.palette.text.secondary
        : undefined,
    },
    buttonBackground: enhancedMessagingEnabled
      ? theme.palette.secondary.dark
      : isDenied
      ? theme.palette.error.main
      : theme.palette.warning.main,
  };
};

export const StatusBanner = ({
  authorization,
  cardPlacement,
}: {
  authorization: Authorization;
  cardPlacement: CardPlacement;
}) => {
  const theme = useTheme();
  const config = useConfig();
  const history = useHistory();

  const { LAUNCH_DARKLY_FEATURE_FLAGS, AUTHORIZATION_STATUSES } =
    config.CONSTANTS;
  const enhancedDrugs = useFeatureFlag<
    { drugName: string; drugOptionId: number }[]
  >(LAUNCH_DARKLY_FEATURE_FLAGS.PostSubmissionEnhancedMessaging);

  const enhancedMessagingEnabled = _.some(
    enhancedDrugs ?? [],
    (drug: { drugName: string; drugOptionId: number }) =>
      drug.drugOptionId === authorization.DrugOptionId
  );

  const previousStatus = previousAuthorizationStatus(
    authorization.authorizationLogs as AuthorizationLog[]
  );

  const displayActionRequiredBanner =
    authorization.status === AUTHORIZATION_STATUSES.ACTION_REQUIRED ||
    (authorization.status === AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT &&
      previousStatus === AUTHORIZATION_STATUSES.ACTION_REQUIRED);

  const bannerProps = getBannerProps({
    authorization,
    enhancedMessagingEnabled,
    isDenied: authorization.status === AUTHORIZATION_STATUSES.DENIED,
    displayActionRequiredBanner,
    theme,
  });

  if (!bannerProps) return null;

  const {
    backgroundColor,
    color,
    subtitle,
    title,
    icon,
    severityOverride,
    useDefaultIcon,
    subtitleSxOverride,
    dataCy,
    buttonBackground,
  } = bannerProps;

  const button = () => {
    if (cardPlacement === CARD_PLACEMENT.CONTEXT_HEADER) {
      return;
    }

    const summaryStep = stepUtils.stepNumberForKey(
      authorization.steps,
      AuthorizationStepKey.Summary
    );

    const authRoute = `${getAuthorizationRoute(
      authorization
    )}?step=${summaryStep}&id=${authorization.id}`;

    return (
      <Button
        onClick={
          cardPlacement === CARD_PLACEMENT.PATIENT_TREATMENT_DETAIL
            ? () =>
                window.open(`${window.location.origin}/#${authRoute}`, "_blank")
            : () => history.push(`${authRoute}`)
        }
        variant="contained"
        sx={{
          borderRadius: "20px",
          backgroundColor: buttonBackground,
          "&:hover": { backgroundColor: theme.palette.error.main },
          whiteSpace: "nowrap",
        }}
      >
        View Response
      </Button>
    );
  };

  return (
    <GenericActionBanner
      backgroundColor={backgroundColor}
      color={color ?? ""}
      button={button()}
      subtitle={subtitle}
      dataCy={dataCy}
      title={title}
      icon={icon}
      cardPlacement={cardPlacement}
      severityOverride={severityOverride}
      useDefaultIcon={useDefaultIcon}
      subtitleSxOverride={subtitleSxOverride}
    />
  );
};
