import _ from "lodash";
import { useCallback, useEffect } from "react";
import {
  useFormContext,
  AutocompleteField,
  type AutocompleteFieldProps,
} from "@samacare/form2";
import { type useFindIcdProps } from "@samacare/hooks-data";

type formattedIcdData = {
  code: string;
  description: string;
};

export type BaseIcdFieldProps = {
  required?: boolean;
  disabled?: boolean;
  displayLimitInLabel?: boolean;
  override?: {
    options?: string[];
    limit?: number;
  };
  icdCodes: [[string]];
  searchFor: (props: useFindIcdProps) => void;
};

export type BaseIcdBlockProps = Omit<
  AutocompleteFieldProps<formattedIcdData, true, true, false>,
  "options" | "TextFieldInputProps"
> &
  BaseIcdFieldProps;

export const BaseIcdField: React.FC<BaseIcdBlockProps> = ({
  name,
  override,
  label,
  displayLimitInLabel,
  icdCodes,
  searchFor,
  ...props
}) => {
  const { watch, setValue } = useFormContext();
  const selectedCodes = watch(name);

  useEffect(() => {
    if (
      selectedCodes &&
      selectedCodes.length > 0 &&
      typeof selectedCodes[0] === "string"
    ) {
      const fetchData = async () => {
        const result = await Promise.all(selectedCodes.map((c: string) => searchFor({ searchTerm: c })));
        const reformatedCodes = result.map((icd) => ({
          code: icd.data.icd10[0][0],
          description: `${icd.data.icd10[0][0]}: ${icd.data.icd10[0][1]}`,
        }));
        setValue(name, reformatedCodes);
      };

      fetchData();
    }
  }, [selectedCodes, searchFor, setValue, name]);

  const search = useCallback(
    (searchTerm: string) => {
      if (searchTerm) {
        searchFor({ searchTerm, options: override?.options });
      }
    },
    [searchFor, override?.options]
  );
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSearch = useCallback(
    _.debounce(search, 500, {
      leading: false,
      trailing: true,
    }),
    [search]
  );

  return (
    <AutocompleteField<formattedIcdData, true, true, false>
      multiple
      sx={{ width: 600 }}
      placeholder="Type to search ICD codes..."
      label={
        label ??
        `ICD-10 codes${
          displayLimitInLabel && override?.limit
            ? ` (Max ${override.limit})`
            : ""
        }`
      }
      name={name}
      value={selectedCodes}
      options={
        icdCodes.map((icd: [string]) => ({
          code: icd[0],
          description: icd.join(":"),
        }))
      }
      filterSelectedOptions
      getOptionLabel={(option) => option.description}
      onInputChange={(e, value) => {
        throttledSearch(value);
      }}
      getOptionDisabled={() => {
        if (override?.limit) {
          return (selectedCodes ?? []).length >= override.limit;
        }
        return false;
      }}
      TextFieldInputProps={{ sx: { minHeight: 50 } }}
      {...props}
    />
  );
};
