import _ from "lodash";
import Checkbox from "@samacare/design/core/Checkbox";
import { FormControlLabel, FormControlLabelProps } from "@samacare/design/core";
import { useFormContext, UseControllerProps, useWatch } from "@samacare/form";
import { useCombinedReferences } from "@samacare/hooks-ui";

export type CheckboxFieldProps = Partial<
  Omit<FormControlLabelProps, "control">
> &
  Omit<UseControllerProps, "control"> & {
    label?: string;
    defaultChecked?: boolean;
    onChangeOverride?: (
      event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean
    ) => void;
  };

const CheckboxField: React.VFC<CheckboxFieldProps> = ({
  name,
  label = "",
  value,
  inputRef = null,
  required = false,
  disabled = false,
  shouldUnregister = false,
  rules = {},
  defaultChecked,
  onChangeOverride,
  ...materialProperties
}) => {
  const { control, register } = useFormContext();
  const { ref, onBlur, onChange, ...fields } = register(name, {
    ...rules,
    disabled,
    required,
    shouldUnregister,
    value,
  });

  // controlled
  const isChecked = useWatch({
    name,
    control,
    defaultValue: defaultChecked ?? false,
  });

  const combinedRef = useCombinedReferences<HTMLInputElement>(ref, inputRef);

  return (
    <FormControlLabel
      id={`CheckboxField-${name}`}
      inputRef={combinedRef}
      sx={{ alignItems: "flex-start" }}
      control={
        <Checkbox
          name={name}
          required={required}
          checked={isChecked}
          onBlur={
            onBlur as React.FocusEventHandler<HTMLButtonElement> | undefined
          }
          onChange={
            onChangeOverride ??
            (onChange as
              | ((
                  event: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => void)
              | undefined)
          }
          sx={{
            marginTop: -1,
          }}
          inputProps={
            {
              "data-cy": _.camelCase(`field.${name}`),
            } as React.InputHTMLAttributes<HTMLInputElement>
          }
        />
      }
      label={label}
      {...fields}
      {...materialProperties}
    />
  );
};

export default CheckboxField;
